import React, { useState } from "react";
import "./Newsletter.css";
import { BsFillFilePdfFill } from "react-icons/bs";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Notiflix from "notiflix";
import PostApiCall from "../../PostApi";

export default function AddNewsletter() {
  const ImageApiUrl = "https://cms.nabdelhi.in/nabdapi/UploadGalleryImage";
  const [OriginalImage, SetOriginalImage] = useState([]);
  const [outputFileName, SetOutputFileName] = useState("");
  const [id, setId] = useState(null);
  const [pdf, setPdf] = useState("");
  const [name, setName] = useState("");
  const [status, setStatus] = useState("Active");

  async function onUploadDocument(documentFileName, randomnumber) {
    var options = {
      maxSizeMB: 0.05,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const form = new FormData();
    form.append("file", OriginalImage);
    form.append("foldername", "Activities");
    form.append("filename", randomnumber + "-" + documentFileName);
    let response = await fetch(ImageApiUrl, {
      method: "POST",
      body: form,
    }).then((response) => {
      if (response.status == 200 || response.status == 201) {
        Notiflix.Loading.remove();
        Notiflix.Notify.success("Newsletter Added Successfully.");
      } else {
        Notiflix.Loading.remove();
        Notiflix.Notify.failure("Document Uploading Failed");
      }
    });

    OriginalImage([]);
  }

  function onSaveNewsletterData() {
    var randomnumber = Math.floor(100000 + Math.random() * 900000);
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        id: id,
        pdf:
          "https://cms.nabdelhi.in/images/Activities/" +
          randomnumber +
          "-" +
          outputFileName,
        name: name,
        status: status,
        updatedon: new Date(),
        createdon: new Date(),
      },
      "AddNewsletter"
    ).then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          onUploadDocument(outputFileName, randomnumber);
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
  }
  return (
    <>
      <Navbar />

      <div className="container mt-5">
        <div className="row">
          <div className="col-12 text-center">
            <div className="main-heading pb-1">
              <h2 className="main-title wow animate__animated animate__fadeInUp">
                Newsletter Subscribers
              </h2>
              <h3 className="main-text wow animate__animated animate__fadeInUp">
                Manage the list of News Letter Subscribers
              </h3>
            </div>
          </div>

          <div className="col-12 px-0">
            <div className="form-container">
              <form className="w-100">
                <div className="row w-100">
                  <div className="col-md-4">
                    <div className="row">
                      <div className="form-field col-12 me-0">
                        <label>Upload PDF</label>
                        <input
                          type="file"
                          onChange={(e) => {
                            e.preventDefault();
                            const pdfFile = e.target.files[0];
                            SetOriginalImage(pdfFile);
                            SetOutputFileName(pdfFile.name);
                          }}
                        />
                      </div>
                      <div className="form-field col-12 me-0 form-radio">
                        <label>Status</label>
                        <div className="d-flex">
                          <span className="d-flex">
                            <input
                              type="radio"
                              name="status"
                              className="ms-2"
                              checked={status == "Active" ? true : false}
                              onChange={() => {
                                setStatus("Active");
                              }}
                            />
                            Active
                          </span>
                          <span className="d-flex">
                            <input
                              type="radio"
                              name="status"
                              checked={status == "Inactive" ? true : false}
                              onChange={() => {
                                setStatus("Inactive");
                              }}
                            />
                            Inactive
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="form-field col-6 me-0 w-100">
                      <label>Name</label>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12 border-top text-end pt-3">
                    <button
                      type="button"
                      className="default-btn"
                      onClick={onSaveNewsletterData}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
