import { useState } from "react";
import Image from "./Image";

const ShowImage = ({ images }) => {
  const show = (image) => {
    return <Image image={image} />;
  };
  return <div className="dropbox-container">{images.map(show)} </div>;
};

export default ShowImage;
