import "./App.css";
import LoginPage from "./pages/LoginPage/LoginPage";
import Dashboard from "./pages/Dashboard/DashboardPage";
import { BrowserRouter, json, Route, Routes } from "react-router-dom";
import Organisation from "./pages/Organisation/Organisation";
import EditContactPersonInfo from "./pages/Organisation/EditContactPersonInfo";
import Members from "./pages/Members/Members";
import Downloads from "./pages/Downloads/Downloads";
import Services from "./pages/Services/Services";
import Stories from "./pages/Stories/Stories";
import Partners from "./pages/Partners/Partners";
import Projects from "./pages/Projects/Projects";
import Events from "./pages/Events/Events";
import NewsBlogs from "./pages/NewsBlogs/NewsBlogs";
import Newsletter from "./pages/Newsletter/Newsletter";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import ImageGallery from "./pages/ImageGallery/ImageGallery";
import VideoGallery from "./pages/VideoGallery/VideoGallery";
import AddMembers from "./pages/Members/AddMembers";
import AddDownload from "./pages/Downloads/AddDownload";
import AddStory from "./pages/Stories/AddStory";
import AddPartner from "./pages/Partners/AddPartner";
import AddNewsBlogs from "./pages/NewsBlogs/AddNewsBlogs";
import AddNewsletter from "./pages/Newsletter/AddNewsletter";
import AddVideoGallery from "./pages/VideoGallery/AddVideoGallery";
import AddImageGallery from "./pages/ImageGallery/AddImageGallery";
import AddServices from "./pages/Services/AddServices";
import AddEvents from "./pages/Events/AddEvents";
import AddProjects from "./pages/Projects/AddProjects";
import Admissions from "./pages/Admissions/Admissions";
import ContactReport from "./pages/ContactReport/ContactReport";
import Volunteer from "./pages/Volunteer/Volunteer";
// Donar of the weeks
import DonarWeeks from "./pages/DonarOfTheWeek/DonarWeek";

// Academy Section
import Academy from "./pages/Academy/AddAcademy";
import AcademyInnerList from "./pages/Academy/AcademyInnerList";
import AcademyInner from "./pages/Academy/AddAcademyInner";

import { useNavigate } from "react-router-dom";
import HomeBannerList from "./pages/HomeBanner/HomeBannerList";
import AddHomeBanner from "./pages/HomeBanner/AddHomeBanner";

function App() {
  let navigate = useNavigate();
  if (
    JSON.parse(localStorage.getItem("access")) == null ||
    JSON.parse(localStorage.getItem("access")) == undefined
  ) {
    navigate("/");
    return (
      <Routes>
        <Route exact path="/" element={<LoginPage />} />
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route exact path="/" element={<LoginPage />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/organisation" element={<Organisation />} />
        <Route exact path="/members" element={<Members />} />
        <Route exact path="/downloads" element={<Downloads />} />
        <Route exact path="/services" element={<Services />} />
        <Route exact path="/stories" element={<Stories />} />
        <Route exact path="/partners" element={<Partners />} />
        <Route exact path="/projects" element={<Projects />} />
        <Route exact path="/events" element={<Events />} />
        <Route exact path="/newsblogs" element={<NewsBlogs />} />
        <Route exact path="/newsletter" element={<Newsletter />} />
        <Route exact path="/videogallery" element={<VideoGallery />} />
        <Route exact path="/changepassword" element={<ChangePassword />} />
        <Route exact path="/photogallery" element={<ImageGallery />} />
        <Route exact path="/addnewmember" element={<AddMembers />} />
        <Route exact path="/addnewdownload" element={<AddDownload />} />
        <Route exact path="/addstory" element={<AddStory />} />
        <Route exact path="/addpartner" element={<AddPartner />} />
        <Route exact path="/addnewsblogs" element={<AddNewsBlogs />} />
        <Route exact path="/addnewsletter" element={<AddNewsletter />} />
        <Route exact path="/addvideogallry" element={<AddVideoGallery />} />
        <Route exact path="/addimagegallery" element={<AddImageGallery />} />
        <Route exact path="/addservices" element={<AddServices />} />
        <Route exact path="/addevents" element={<AddEvents />} />
        <Route exact path="/addprojects" element={<AddProjects />} />
        <Route exact path="/admissions" element={<Admissions />} />
        <Route exact path="/contactreport" element={<ContactReport />} />
        <Route exact path="/volunteer" element={<Volunteer />} />
        <Route exact path="/homebanner" element={<HomeBannerList />} />
        <Route exact path="/addhomebanner" element={<AddHomeBanner />} />

        {/* Donar of the weeks */}
        <Route exact path="/donors" element={<DonarWeeks />} />

        {/* Academy Section */}
        <Route exact path="/addacademy" element={<Academy />} />
        <Route exact path="/addacademyinner" element={<AcademyInner />} />
        <Route exact path="/academyinnerlist" element={<AcademyInnerList />} />
      </Routes>
    );
  }
}

export default App;
