import React, { useState } from "react";
import "./Downloads.css";
import { BsFillFilePdfFill } from "react-icons/bs";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Notiflix from "notiflix";
import PostApiCall from "../../PostApi";

export default function AddDownload() {
  const ImageApiUrl = "https://cms.nabdelhi.in/nabdapi/UploadGalleryImage";
  const [fillActive, setFillActive] = useState("tab1");
  const [showdocumentcategory, SetShowDocumentCategory] = useState(true);
  const [downloadid, SetDocumentId] = useState(null);
  const [document, SetDocument] = useState("");
  const [documentfile, setDocumentFile] = useState([]);
  const [documentname, SetDocumentName] = useState("");
  const [documentcategory, SetDocumentCategory] = useState("");
  const [documenttype, SetDocumentType] = useState("Statutory Documents");
  const [status, SetStatus] = useState("Active");

  const handleFillClick = (value) => {
    if (value === fillActive) {
      return;
    }

    setFillActive(value);
  };

  async function onUploadDocument(documentFileName, randomnumber) {
    var options = {
      maxSizeMB: 0.05,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const form = new FormData();
    form.append("file", documentfile);
    form.append("foldername", "Activities");
    form.append("filename", randomnumber + "-" + documentFileName);
    let response = await fetch(ImageApiUrl, {
      method: "POST",
      body: form,
    }).then((response) => {
      if (response.status == 200 || response.status == 201) {
        Notiflix.Loading.remove();
        Notiflix.Notify.success("Download Added Successfully.");
      } else {
        Notiflix.Loading.remove();
        Notiflix.Notify.failure("Document Uploading Failed");
      }
    });

    setDocumentFile([]);
  }

  function onSaveDownloadData() {
    var randomnumber = Math.floor(100000 + Math.random() * 900000);
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        downloadid: downloadid,
        document:
          "https://cms.nabdelhi.in/images/Activities/" +
          randomnumber +
          "-" +
          document,
        documentname: documentname,
        documentcategory: documentcategory,
        documenttype: documenttype,
        status: status,
      },
      "AddDownload"
    ).then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          onUploadDocument(document, randomnumber);
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
  }
  return (
    <>
      <Navbar />

      <div className="container mt-5">
        <div className="row">
          <div className="col-12 text-center">
            <div className="main-heading pb-1">
              <h2 className="main-title wow animate__animated animate__fadeInUp">
                Manage Downloads
              </h2>
              <h3 className="main-text wow animate__animated animate__fadeInUp">
                Use this module to add Documents that can be downloadable from
                the website
              </h3>
            </div>
          </div>

          <div className="col-12">
            <div className="form-container">
              <h3 class="main-text fw-bold">Add New Document</h3>
              <form className="">
                <div className="row">
                  <div className="form-field col-md-3 me-0 form-file">
                    <p>Upload PDF</p>
                    <input
                      type="file"
                      onChange={(e) => {
                        e.preventDefault();
                        const documentFile = e.target.files[0];
                        SetDocument(documentFile.name);
                        setDocumentFile(documentFile);
                      }}
                    />
                  </div>
                  <div className="col-md-9">
                    <div className="row">
                      <div className="form-field col-md-6 me-0 form-radio">
                        <label>Choose Document Type</label>
                        <div className="d-flex">
                          <span className="d-flex">
                            <input
                              type="radio"
                              name="member"
                              className="ms-2"
                              checked={
                                documenttype == "Statutory Documents"
                                  ? true
                                  : false
                              }
                              onChange={() => {
                                SetShowDocumentCategory(true);
                                SetDocumentType("Statutory Documents");
                              }}
                            />
                            Statutory Documents
                          </span>
                          <span className="d-flex">
                            <input
                              type="radio"
                              name="member"
                              checked={
                                documenttype == "Annual Reports" ? true : false
                              }
                              onChange={() => {
                                SetShowDocumentCategory(false);
                                SetDocumentType("Annual Reports");
                                SetDocumentCategory("ANNUAL REPORTS");
                              }}
                            />
                            Annual Reports
                          </span>
                        </div>
                      </div>
                      <div
                        className="form-field col-md-6 me-0"
                        style={{
                          display:
                            showdocumentcategory == true ? "block" : "none",
                        }}
                      >
                        <label>Document Category</label>
                        <select
                          class="w-100"
                          onChange={(e) => {
                            SetDocumentCategory(e.target.value);
                          }}
                        >
                          <option value="Select Document Category">
                            Select Document Category
                          </option>
                          <option value="IMPORTANT PUBLICATIONS">
                            IMPORTANT PUBLICATIONS
                          </option>
                          <option value="REC HOLISTIC EDUCATION & REHABILITATION FOR CHILDREN WITH BLINDNESS">
                            REC HOLISTIC EDUCATION & REHABILITATION FOR CHILDREN
                            WITH BLINDNESS
                          </option>
                          <option value="AUDITED ACCOUNTS">
                            AUDITED ACCOUNTS
                          </option>
                          <option value="LEGAL DOCUMENTS">
                            LEGAL DOCUMENTS
                          </option>
                          <option value="BENEFICIARY DATA">
                            BENEFICIARY DATA
                          </option>
                        </select>
                      </div>
                      <div className="form-field col-md-6 me-0">
                        <label>Name</label>
                        <input
                          type="text"
                          value={documentname}
                          onChange={(e) => {
                            SetDocumentName(e.target.value);
                          }}
                        />
                      </div>

                      <div className="form-field col-md-6 me-0 form-radio">
                        <label>Status</label>
                        <div className="d-flex">
                          <span className="d-flex">
                            <input
                              type="radio"
                              name="status"
                              className="ms-2"
                              checked={status == "Active" ? true : false}
                              onChange={() => {
                                SetStatus("Active");
                              }}
                            />
                            Active
                          </span>
                          <span className="d-flex">
                            <input
                              type="radio"
                              name="status"
                              checked={status == "Inactive" ? true : false}
                              onChange={() => {
                                SetStatus("Inactive");
                              }}
                            />
                            Inactive
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 border-top text-end pt-3">
                    <button
                      type="button"
                      className="default-btn"
                      onClick={onSaveDownloadData}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
