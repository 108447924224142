import React, { useState, useEffect } from "react";
import "./Newsletter.css";
import { useNavigate } from "react-router-dom";
import { BsFillFilePdfFill } from "react-icons/bs";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Notiflix from "notiflix";
import GetApiCall from "../../GETAPI";
import PostApiCall from "../../PostApi";
import Modal from "react-bootstrap/Modal";
import { confirmAlert } from "react-confirm-alert";
import Sequentialize from "../../assets/images/Sequentialize.png";
import { Offcanvas } from "react-bootstrap";
import DragDropNewsLetter from "./DragDropNewsLetter"
export default function Newsletter() {
  let navigate = useNavigate();
  const ImageApiUrl = "https://cms.nabdelhi.in/nabdapi/UploadGalleryImage";
  const [modalShow, setModalShow] = useState(false);
  const [allNewsLetterData, setAllNewsLetterData] = useState([]);
  const [allNewsLetterSubscriberData, setAllNewsLetterSubscriberData] =
    useState([]);
  const [OriginalImage, SetOriginalImage] = useState([]);
  const [outputFileName, SetOutputFileName] = useState("");
  const [id, setId] = useState(null);
  const [pdf, setPdf] = useState("");
  const [pdfurl, setPdfUrl] = useState("");
  const [name, setName] = useState("");
  const [status, setStatus] = useState("Active");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetNewsLetterData").then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          setAllNewsLetterData(obj.data);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("No Records Exits");
        }
      })
    );
    GetApiCall.getRequest("GetNewsLetterSubscriberData").then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          setAllNewsLetterSubscriberData(obj.data);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("No Records Exits");
        }
      })
    );
  }, []);

  async function onUploadPdf(documentFileName, randomnumber) {
    var options = {
      maxSizeMB: 0.05,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const form = new FormData();
    form.append("file", OriginalImage);
    form.append("foldername", "Activities");
    form.append("filename", randomnumber + "-" + documentFileName);
    let response = await fetch(ImageApiUrl, {
      method: "POST",
      body: form,
    }).then((response) => {
      if (response.status == 200 || response.status == 201) {
        GetApiCall.getRequest("GetNewsLetterData").then((resultdes) =>
          resultdes.json().then((obj) => {
            if (obj.data.length != 0) {
              setAllNewsLetterData(obj.data);
              setModalShow(false);
              SetOutputFileName("");
              setStatus("Active");
              setName("");
              setId(null);
              Notiflix.Loading.remove();
              Notiflix.Notify.success("Newsletter Updated Successfully.");
            } else {
              Notiflix.Loading.remove();
              Notiflix.Notify.failure("No Records Exits");
            }
          })
        );
      } else {
        Notiflix.Loading.remove();
        Notiflix.Notify.failure("Document Uploading Failed");
      }
    });

    SetOriginalImage([]);
  }

  function onUpdateNewsletterData() {
    var randomnumber = Math.floor(100000 + Math.random() * 900000);
    Notiflix.Loading.dots();
    var newsletterobj = {};
    if (OriginalImage.length != 0) {
      newsletterobj = {
        id: id,
        pdf:
          "https://cms.nabdelhi.in/images/Activities/" +
          randomnumber +
          "-" +
          outputFileName,
        name: name,
        status: status,
        updatedon: new Date(),
        createdon: new Date(),
      };
    } else {
      newsletterobj = {
        id: id,
        pdf: pdfurl,
        name: name,
        status: status,
        updatedon: new Date(),
        createdon: new Date(),
      };
    }
    PostApiCall.postRequest(newsletterobj, "AddNewsletter").then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          if (OriginalImage.length != 0) {
            onUploadPdf(outputFileName, randomnumber);
          } else {
            GetApiCall.getRequest("GetNewsLetterData").then((resultdes) =>
              resultdes.json().then((obj) => {
                if (obj.data.length != 0) {
                  setAllNewsLetterData(obj.data);
                  setModalShow(false);
                  SetOutputFileName("");
                  setStatus("Active");
                  setName("");
                  setId(null);
                  Notiflix.Loading.remove();
                  Notiflix.Notify.success("Newsletter Updated Successfully.");
                } else {
                  Notiflix.Loading.remove();
                  Notiflix.Notify.failure("No Records Exits");
                }
              })
            );
          }
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
  }

  function onCloseNewsletterModal() {
    setModalShow(false);
    SetOutputFileName("");
    setStatus("Active");
    setName("");
    setId(null);
  }
  function onAddNewsletter() {
    navigate("/addnewsletter");
  }
  return (
    <>
      <Navbar />

      <div className="container mt-5">
        <div className="row">
          <div className="col-12 text-center">
            <div className="main-heading pb-1">
              <h2 className="main-title wow animate__animated animate__fadeInUp">
                Newsletter Subscribers
              </h2>
              <h3 className="main-text wow animate__animated animate__fadeInUp">
                Manage the list of News Letter Subscribers
              </h3>
            </div>
          </div>

          <div className="col-12 mt-5">
            <div className="form-container download-main">
              <div className="mb-3 d-flex justify-content-between border-bottom pb-3">
                <h3 class="main-text fw-bold">Newsletter List</h3>
                <button
                  type="button"
                  className="default-btn ms-auto"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                  onClick={() => {
                    handleShow();
                  }}
                >
                  <img
                    alt="sequential-img"
                    src={Sequentialize}
                    className="sequentialize-btn"
                  />
                </button>
                <button
                  type="button"
                  className="default-btn"
                  onClick={() => {
                    onAddNewsletter();
                  }}
                >
                  Add Newsletter
                </button>
              </div>
              <form className="mt-3 flex-column">
                <div className="row">
                  {allNewsLetterData?.sort((a, b) => a.fld_sequence - b.fld_sequence).map((newsletterdata, i) => {
                    return (
                      <div className="col-md-4 px-3">
                        <div className="members-card">
                          <a
                            className="form-field d-block download-section col-12 me-0"
                            href={newsletterdata.fld_pdf}
                            target="_blank"
                          >
                            <BsFillFilePdfFill />
                          </a>
                          <div className="col-md-10">
                            <div className="row">
                              <div className="form-field col-12 flex-row me-0">
                                <span>{newsletterdata.fld_name}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 border-top pt-3 text-end d-flex">
                            <div className="form-field form-radio d-flex">
                              <span className={newsletterdata.fld_status}>
                                {newsletterdata.fld_status}
                              </span>
                            </div>
                            <div className="ms-auto">
                              <button
                                type="button"
                                className="default-btn ms-auto"
                                onClick={() => {
                                  setModalShow(true);
                                  setPdfUrl(newsletterdata.fld_pdf);
                                  setStatus(newsletterdata.fld_status);
                                  setName(newsletterdata.fld_name);
                                  setId(newsletterdata.fld_id);
                                }}
                              >
                                Edit
                              </button>
                              <button
                                type="button"
                                className="default-btn"
                                onClick={() => {
                                  confirmAlert({
                                    title: "Confirm to Delete",
                                    message:
                                      "Are you sure you want to delete the Newsletter?",
                                    buttons: [
                                      {
                                        label: "Yes",
                                        onClick: () => {
                                          PostApiCall.postRequest(
                                            {
                                              id: newsletterdata.fld_id,
                                            },
                                            "DeleteNewsLetter"
                                          ).then((results) =>
                                            results.json().then((obj) => {
                                              if (
                                                results.status == 200 ||
                                                results.status == 201
                                              ) {
                                                Notiflix.Loading.remove();
                                                Notiflix.Notify.success(
                                                  "Newsletter successfully deleted."
                                                );
                                                window.location.reload();
                                              } else {
                                                Notiflix.Loading.remove();
                                                Notiflix.Notify.failure(
                                                  "Something went wrong, try again later."
                                                );
                                              }
                                            })
                                          );
                                        },
                                      },
                                      {
                                        label: "No",
                                      },
                                    ],
                                  });
                                }}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </form>
            </div>
          </div>
          <div className="col-12 mt-5">
            <div className="form-container download-main">
              <div className="mb-3 d-flex justify-content-between border-bottom pb-3">
                <h3 class="main-text fw-bold">Newsletter Subscribers</h3>
              </div>
              <form className="mt-3 flex-column">
                <div className="row">
                  {allNewsLetterSubscriberData.map((newslettersubsdata, i) => {
                    return (
                      <div className="col-md-3 px-3 mb-3">
                        <div className="members-card">
                          <div className="col-md-10">
                            <div className="row">
                              <div className="form-field col-12 me-0">
                                <span>{newslettersubsdata.fld_name}</span>
                                <span>{newslettersubsdata.fld_email}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 border-top pt-3 d-flex">
                            <div className="">
                              <button
                                type="button"
                                className="default-btn"
                                onClick={() => {
                                  confirmAlert({
                                    title: "Confirm to Delete",
                                    message:
                                      "Are you sure you want to delete the Newsletter Subscriber?",
                                    buttons: [
                                      {
                                        label: "Yes",
                                        onClick: () => {
                                          PostApiCall.postRequest(
                                            {
                                              id: newslettersubsdata.fld_id,
                                            },
                                            "DeleteNewsLetterSubscriber"
                                          ).then((results) =>
                                            results.json().then((obj) => {
                                              if (
                                                results.status == 200 ||
                                                results.status == 201
                                              ) {
                                                Notiflix.Loading.remove();
                                                Notiflix.Notify.success(
                                                  "Newsletter Subscriber successfully deleted."
                                                );
                                                window.location.reload();
                                              } else {
                                                Notiflix.Loading.remove();
                                                Notiflix.Notify.failure(
                                                  "Something went wrong, try again later."
                                                );
                                              }
                                            })
                                          );
                                        },
                                      },
                                      {
                                        label: "No",
                                      },
                                    ],
                                  });
                                }}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-90w"
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 px-0">
            <div className="form-container">
              <form className="w-100">
                <div className="row w-100">
                  <div className="col-md-4">
                    <div className="row">
                      <div className="form-field col-12 me-0">
                        <label>Upload PDF</label>
                        <input
                          type="file"
                          onChange={(e) => {
                            e.preventDefault();
                            const pdfFile = e.target.files[0];
                            SetOriginalImage(pdfFile);
                            SetOutputFileName(pdfFile.name);
                          }}
                        />
                      </div>
                      <div className="form-field col-12 me-0 form-radio">
                        <label>Status</label>
                        <div className="d-flex">
                          <span className="d-flex">
                            <input
                              type="radio"
                              name="status"
                              className="ms-2"
                              checked={status == "Active" ? true : false}
                              onChange={() => {
                                setStatus("Active");
                              }}
                            />
                            Active
                          </span>
                          <span className="d-flex">
                            <input
                              type="radio"
                              name="status"
                              checked={status == "Inactive" ? true : false}
                              onChange={() => {
                                setStatus("Inactive");
                              }}
                            />
                            Inactive
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="form-field col-6 me-0 w-100">
                      <label>Name</label>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12 border-top text-end pt-3">
                    <button
                      type="button"
                      className="default-btn"
                      onClick={onCloseNewsletterModal}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="default-btn"
                      onClick={onUpdateNewsletterData}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Footer />
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header className="border-bottom" closeButton>
          <Offcanvas.Title>Sequentialize</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <DragDropNewsLetter
            verticalData={allNewsLetterData}
            // verticalData={
            //   fillActive == "tab1"
            //     ? allActiveOfficeBearers
            //     : allActiveGoverningCouncil
            // }
            for="Vertical"
            htmlFor="verticle"
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
