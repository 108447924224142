import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import GetApiCall from "../../GETAPI";
import PostApiCall from "../../PostApi";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import "../Stories/Stories.css";
import { confirmAlert } from "react-confirm-alert";

// ***** Add CkEditor *******
import Nodata from "../../assets/images/nodata/no-data.png";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import createDOMPurify from "dompurify";
const DOMPurify = createDOMPurify(window);

export default function DonorWeek() {
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [dowid, setDowid] = useState(null);
  const [status, setStatus] = useState("Active");
  const [editData, setEditData] = useState([]);

  // for donor modal
  const [description, setDescription] = useState("");
  const [donorDetails, setDonorDetails] = useState([]);
  const [showDonor, setShowDonor] = useState(false);
  const [showonHomePage, setShowonHomePage] = useState("No");
  const [donarDataLength, setDonarDataLength] = useState(0);
  const handleCloseDonor = () => setShowDonor(false);
  const handleShowDonor = () => {
    setEditData([]);
    setDowid(null);
    setDescription("");
    setShowDonor(true);
  };

  // For Add Donor
  function onSaveEventsData() {
    Notiflix.Loading.dots();

    PostApiCall.postRequest(
      {
        dowid: dowid,
        shortcontent: description,
        status: status,
        showOnHomePage: showonHomePage,
        updatedon: new Date(),
        createdon: new Date(),
      },
      "AddDOW"
    ).then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          // setEditData([]);
          GetDOW();
          handleCloseDonor();
          Notiflix.Loading.remove();
          // window.location.reload();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
  }

  useEffect(() => {
    GetDOW();
  }, []);
  const GetDOW = () => {
    GetApiCall.getRequest("GetDOW").then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          setDonorDetails(obj.data);
          setDonarDataLength(
            obj.data.filter((dat) => dat.fld_status !== "Delete")
          );
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("No Records Exits");
        }
      })
    );
  };

  return (
    <>
      <Navbar />
      <div className="container mt-5">
        <div className="row">
          <div className="col-12 text-center">
            <div className="main-heading pb-1">
              <h2 className="main-title wow animate__animated animate__fadeInUp">
                Donor of the Day
              </h2>
              <h3 className="main-text wow animate__animated animate__fadeInUp">
                Daily Donor Highlights
              </h3>
            </div>
          </div>

          <div className="col-12">
            <div className="row service-section form-container">
              <div className="mb-3 d-flex justify-content-between border-bottom pb-3">
                <h3 class="main-text fw-bold">Donors List</h3>
                <button
                  type="button"
                  className="default-btn"
                  onClick={handleShowDonor}
                >
                  Add New Donor
                </button>
              </div>
              {/* {console.log(donarDataLength)} */}
              {donarDataLength.length > 0 ? (
                <>
                  {/* ******* Get all details ******** */}
                  {donorDetails
                    .filter((donordeletedata) => {
                      if (donordeletedata.fld_status !== "Delete") {
                        return donordeletedata;
                      }
                    })
                    .map((data, i) => {
                      return (
                        <div className="col-md-6 px-3 mb-3">
                          <div className="donor-card">
                            <div className="story-content">
                              <p className="donor-description">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                      data.fld_shortcontent
                                    ),
                                  }}
                                />
                              </p>
                            </div>

                            {/* Btn Position */}
                            <div className="bg-white py-4 px-2 col-12 border-top pt-3 text-end d-flex position-absolute bottom-0">
                              <div className="form-field form-radio py-0 my-0 text-start">
                                <p className="d-flex mb-1 pb-0">
                                  <b>Status:</b>{" "}
                                  <span className={data.fld_status}>
                                    {data.fld_status}
                                  </span>
                                </p>
                                {/* **** Show on website ****** */}
                                <p className="mb-0 pb-0">
                                  <b>Show on Home Page:</b>{" "}
                                  <span className={data.fld_showOnHomePage}>
                                    {data.fld_showOnHomePage}{" "}
                                  </span>{" "}
                                </p>
                              </div>

                              <div className="ms-auto d-flex align-items-end">
                                <button
                                  type="button"
                                  className="default-btn ms-auto"
                                  onClick={() => {
                                    setShowDonor(true);
                                    setDowid(data.fld_id);
                                    setEditData([data]);
                                    setStatus(data.fld_status);
                                    setShowonHomePage(data.fld_showOnHomePage);
                                    setDescription(data.fld_shortcontent);
                                  }}
                                >
                                  Edit
                                </button>
                                <button
                                  type="button"
                                  className="default-btn"
                                  onClick={() => {
                                    confirmAlert({
                                      title: "Confirm to Delete",
                                      message:
                                        "Are you sure you want to delete the Donor Details?",
                                      buttons: [
                                        {
                                          label: "Yes",
                                          onClick: () => {
                                            PostApiCall.postRequest(
                                              {
                                                // id: data.fld_id,
                                                dowid: data.fld_id,
                                                status: "Delete",
                                              },
                                              // "DeleteDonor"
                                              "AddDOW"
                                            ).then((results) =>
                                              results.json().then((obj) => {
                                                if (
                                                  results.status == 200 ||
                                                  results.status == 201
                                                ) {
                                                  Notiflix.Loading.remove();
                                                  Notiflix.Notify.success(
                                                    "Story successfully deleted."
                                                  );
                                                  GetDOW();
                                                  // window.location.reload();
                                                } else {
                                                  Notiflix.Loading.remove();
                                                  Notiflix.Notify.failure(
                                                    "Something went wrong, try again later."
                                                  );
                                                }
                                              })
                                            );
                                          },
                                        },
                                        {
                                          label: "No",
                                        },
                                      ],
                                    });
                                  }}
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </>
              ) : (
                <div className="mt-4 pt-2 row">
                  <div className="col-12 text-center">
                    <img
                      src={Nodata}
                      alt="block user"
                      className="customerhistory-icons ms-3 mt-1"
                      style={{ marginRight: "5px", width: "70px" }}
                    />
                  </div>
                  <div className="col-12 text-center my-3">
                    <h6>Records not available</h6>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />

      {/* **** Modal for Donar of the weeks Add form **** */}
      <Modal
        size="lg"
        show={showDonor}
        onHide={() => {
          handleCloseDonor();
        }}
      >
        {/* {console.log(editData)} */}
        <Modal.Header closeButton>
          <Modal.Title>
            {editData.length > 0 ? "Edit Donor Details" : "Add New Donor"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CKEditor
            editor={ClassicEditor}
            data={
              editData.length > 0 ? editData[0].fld_shortcontent : description
            }
            onChange={(event, editor) => {
              const data = editor.getData();
              setDescription(data);
            }}
          />
          <div className="row">
            <div className="form-field col-12 col-md-6 mt-3 me-0 form-radio">
              <label className="pb-2">Status</label>
              <div className="d-flex">
                <span>
                  <input
                    type="radio"
                    name="status"
                    className="ms-0"
                    checked={status == "Active" ? true : false}
                    onChange={() => {
                      setStatus("Active");
                    }}
                  />
                  Active
                </span>
                <span>
                  <input
                    type="radio"
                    name="status"
                    checked={status == "Inactive" ? true : false}
                    onChange={() => {
                      setStatus("Inactive");
                    }}
                  />
                  Inactive
                </span>
              </div>
            </div>
            <div className="col-12 col-md-6 mt-0 mt-3 d-flex align-items-center justify-content-end">
              {/* **** Show on home page **** */}
              <div className="form-group">
                <label htmlFor="CategoryName">
                  Show On Home Page
                  <span className="mandatory">*</span>
                </label>
                <br />
                <label className="radio-inline">
                  <input
                    type="radio"
                    name="homeradio"
                    //  disabled={!this.state.IsVisible}
                    checked={showonHomePage == "Yes" ? true : false}
                    onChange={() => {
                      setShowonHomePage("Yes");
                    }}
                  />{" "}
                  Yes
                </label>
                <label className="radio-inline" style={{ marginLeft: "10px" }}>
                  <input
                    type="radio"
                    name="homeradio"
                    checked={showonHomePage == "No" ? true : false}
                    onChange={() => {
                      setShowonHomePage("No");
                    }}
                  />{" "}
                  No
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleCloseDonor();
            }}
          >
            Close
          </Button>
          <Button
            className="default-btn py-2"
            onClick={() => {
              onSaveEventsData();
            }}
          >
            {editData.length > 0 ? "Update" : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
