import React, { useEffect, useState } from "react";
import "../Dashboard/Dashboard.css";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import GetApiCall from "../../GETAPI";
import Notiflix from "notiflix";
import { useNavigate } from "react-router-dom";
import moment from "moment";

function Dashboard() {
  let navigate = useNavigate();
  const [alleventsdata, setAllEventsData] = useState([]);

  useEffect(() => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetEventsData").then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          setAllEventsData(obj.data.reverse());
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("No Records Exits");
          setAllEventsData([]);
        }
      })
    );
  }, []);
  return (
    <>
      <Navbar />

      <section className="dashboard-section">
        <div className="container mt-5">
          <div className="row">
            <div className="col-12 text-center">
              <div className="main-heading pb-1">
                <h2 className="main-title wow animate__animated animate__fadeInUp">
                  Dashboard
                </h2>
                <h3 className="main-text wow animate__animated animate__fadeInUp">
                  A view of Statistics & Enquiries from the Website
                </h3>
              </div>
            </div>

            <div className="container mt-5">
              <div className="row">
                <div className="col-12">
                  <h3 class="main-text fw-bold">Latest Events</h3>
                  <div className="row service-section form-container event-right">
                    {alleventsdata.slice(0, 2).map((eventdata, i) => {
                      return (
                        <div className="col-md-6 mb-3 d-flex justify-content-between p-3">
                          {/* <div className="col-md-6 p-3"> */}
                          <div className="event-card">
                            <img
                              className="img-fluid"
                              src={eventdata.fld_cover_image}
                            />
                            <div className="event-right-content">
                              <span>
                                {moment(eventdata.fld_start_date).format(
                                  "DD-MM-YYYY"
                                )}
                              </span>
                              <h4>{eventdata.fld_event_name}</h4>
                              <p className="event-location">
                                {eventdata.fld_venue} <br />{" "}
                                {eventdata.fld_location}
                              </p>
                              <div
                                className={`form-field form-radio d-flex ${eventdata.fld_status}`}
                              >
                                <span
                                  className={`ms-0 ${eventdata.fld_status}`}
                                >
                                  {eventdata.fld_status}
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* </div> */}
                        </div>
                      );
                    })}
                    <div className="col-12 d-flex justify-content-end border-top pt-3">
                      <button
                        type="button"
                        className="default-btn"
                        onClick={() => {
                          navigate("/events");
                        }}
                      >
                        View All Event
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Dashboard;
