import React, { useEffect } from "react";
import "./Stories.css";
import { useState } from "react";
import Notiflix from "notiflix";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import GetApiCall from "../../GETAPI";
import PostApiCall from "../../PostApi";
import Modal from "react-bootstrap/Modal";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import imageCompression from "browser-image-compression";
import Sequentialize from "../../assets/images/Sequentialize.png";
import Offcanvas from "react-bootstrap/Offcanvas";
import DragDropList from "../Stories/DragDropList/DragDropList";
const ImgUpload = ({ onChange, src }) => (
  <label
    htmlFor="photo-upload"
    className="custom-file-upload fas"
    style={{ marginBottom: "-5px" }}
  >
    <div className="img-wrap obimages img-upload">
      <img
        htmlFor="photo-upload"
        src={src}
        style={{ width: "100%", height: "100%", borderRadius: "5%" }}
      />
    </div>
    <input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
  </label>
);
var randomnumber = Math.floor(100000 + Math.random() * 900000);
export default function Stories() {
  let navigate = useNavigate();
  const ImageApiUrl = "https://cms.nabdelhi.in/nabdapi/UploadGalleryImage";
  const [modalShow, setModalShow] = useState(false);
  const [show, setShow] = useState(false);
  const [ImagePreviewUrl, SetImagePreviewUrl] = useState(
    "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png"
  );
  const [OriginalImage, SetOriginalImage] = useState([]);
  const [outputFileName, SetOutputFileName] = useState("");
  const [UploadImage, SetUploadImage] = useState(false);
  const [allstorydata, SetAllStoryData] = useState([]);
  const [randomnum, SetRandomNumber] = useState(randomnumber);
  const [storyid, SetStoryId] = useState(null);
  const [storyname, SetStoryName] = useState("");
  const [storydesignation, SetStoryDesignation] = useState("");
  const [companyname, SetCompanyName] = useState("");
  const [storydescription, SetStoryDescription] = useState("");
  const [imagealt, SetImageAlt] = useState("");
  const [status, SetStatus] = useState("Active");
  const [allActiveStories, setAllActiveStories] = useState([]);

  const photoUpload = (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    SetImagePreviewUrl(URL.createObjectURL(imageFile));
    SetOriginalImage(imageFile);
    SetOutputFileName(imageFile.name);
    var imagename = imageFile.name.split(".");
    SetImageAlt(imagename[0]);
    SetUploadImage(true);
  };

  useEffect(() => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetStoriesData").then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          var allActiveStories = [];
          obj.data.map((story, i) => {
            if (story.fld_status == "Active") {
              allActiveStories.push(story);
            }
          });

          setAllActiveStories(allActiveStories);
          SetAllStoryData(obj.data);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("No Records Exits");
        }
      })
    );
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function onUploadImage(imageFileName, randomnumber) {
    var options = {
      maxSizeMB: 0.05,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    imageCompression(OriginalImage, options).then(async (compressedFile) => {
      const form = new FormData();
      form.append("file", compressedFile);
      form.append("foldername", "Activities");
      form.append("filename", randomnumber + "-" + imageFileName);
      let response = await fetch(ImageApiUrl, {
        method: "POST",
        body: form,
      }).then((response) => {
        if (response.status == 200 || response.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Story Added Successfully.");
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Image Uploading Failed");
        }
      });
    });
  }

  function onEditStoryData() {
    Notiflix.Loading.dots();
    var imagetype = outputFileName.split(".");
    var imagename = imagealt + "." + imagetype[1];
    var storyobj = {};

    if (OriginalImage.length != 0) {
      storyobj = {
        storyid: storyid,
        storyname: storyname,
        storydesignation: storydesignation,
        companyname: companyname,
        storydescription: storydescription,

        storyimage:
          "https://cms.nabdelhi.in/images/Activities/" +
          randomnum +
          "-" +
          imagename,
        imagealt: imagealt,
        status: status,
      };
    } else {
      storyobj = {
        storyid: storyid,
        storyname: storyname,
        storydesignation: storydesignation,
        companyname: companyname,
        storydescription: storydescription,

        storyimage: ImagePreviewUrl,
        imagealt: imagealt,
        status: status,
      };
    }
    PostApiCall.postRequest(storyobj, "AddStory").then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          if (OriginalImage.length != 0) {
            onUploadImage(imagename, randomnumber);
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.success("Story Updated Successfully");
          }
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
  }

  function onCloseStoryModal() {
    GetApiCall.getRequest("GetStoriesData").then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          SetAllStoryData(obj.data);
          setModalShow(false);
          SetStoryId(null);
          SetStoryName("");
          SetStoryDesignation("");
          SetCompanyName("");
          SetStoryDescription("");
          SetImageAlt("");
          SetStatus("Active");
          SetImagePreviewUrl(
            "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png"
          );
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("No Records Exits");
        }
      })
    );
  }

  function onAddNewStory() {
    navigate("/addstory");
  }
  return (
    <>
      <Navbar />

      <div className="container mt-5">
        <div className="row">
          <div className="col-12 text-center">
            <div className="main-heading pb-1">
              <h2 className="main-title wow animate__animated animate__fadeInUp">
                Impact Stories
              </h2>
              <h3 className="main-text wow animate__animated animate__fadeInUp">
                Add Stories of Students who have created an Impact
              </h3>
            </div>
          </div>

          <div className="col-12">
            <div className="row service-section form-container">
              <div className="mb-3 d-flex justify-content-between border-bottom pb-3">
                <h3 class="main-text fw-bold">Stories List</h3>
                <button
                  type="button"
                  className="default-btn ms-auto"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                  onClick={() => {
                    handleShow();
                  }}
                >
                  <img
                    alt="sequential-img"
                    src={Sequentialize}
                    className="sequentialize-btn"
                  />
                </button>
                <button
                  type="button"
                  className="default-btn"
                  onClick={() => {
                    onAddNewStory();
                  }}
                >
                  Add New Story
                </button>
              </div>
              {allstorydata.map((storydata, i) => {
                return (
                  <div className="col-md-4 px-3 mb-3">
                    <div className="story-card">
                      <div className="storie-img d-flex align-item-center justify-content-center">
                        <img
                          className="img-fluid"
                          src={storydata.fld_image}
                          alt={storydata.fld_image_alt}
                        ></img>
                      </div>
                      <div className="story-content">
                        <h4>{storydata.fld_name}</h4>
                        <h5>{storydata.fld_designation}</h5>
                        <h5>{storydata.fld_company_name}</h5>
                        <p className="stories-description">
                          {storydata.fld_description}
                        </p>
                        <div className="col-12 border-top pt-3 text-end d-flex">
                          <div className="form-field form-radio d-flex">
                            <span className={storydata.fld_status}>
                              {storydata.fld_status}
                            </span>
                          </div>
                          <div className="ms-auto">
                            <button
                              type="button"
                              className="default-btn ms-auto"
                              onClick={() => {
                                setModalShow(true);
                                SetStoryId(storydata.fld_id);
                                SetStoryName(storydata.fld_name);
                                SetStoryDesignation(storydata.fld_designation);
                                SetCompanyName(storydata.fld_company_name);
                                SetStoryDescription(storydata.fld_description);
                                SetImageAlt(storydata.fld_image_alt);
                                SetStatus(storydata.fld_status);
                                SetImagePreviewUrl(storydata.fld_image);
                              }}
                            >
                              Edit
                            </button>
                            <button
                              type="button"
                              className="default-btn"
                              onClick={() => {
                                confirmAlert({
                                  title: "Confirm to Delete",
                                  message:
                                    "Are you sure you want to delete the Story?",
                                  buttons: [
                                    {
                                      label: "Yes",
                                      onClick: () => {
                                        PostApiCall.postRequest(
                                          {
                                            id: storydata.fld_id,
                                          },
                                          "DeleteStory"
                                        ).then((results) =>
                                          results.json().then((obj) => {
                                            if (
                                              results.status == 200 ||
                                              results.status == 201
                                            ) {
                                              Notiflix.Loading.remove();
                                              Notiflix.Notify.success(
                                                "Story successfully deleted."
                                              );
                                              window.location.reload();
                                            } else {
                                              Notiflix.Loading.remove();
                                              Notiflix.Notify.failure(
                                                "Something went wrong, try again later."
                                              );
                                            }
                                          })
                                        );
                                      },
                                    },
                                    {
                                      label: "No",
                                    },
                                  ],
                                });
                              }}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-90w"
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 px-0">
            <div className="form-container">
              <h3 class="main-text fw-bold">Add New Story</h3>
              <form className="w-100">
                <div className="row w-100">
                  <div className="col-md-2">
                    <div className="row">
                      <div className="form-field col-12 me-0">
                        <span>Upload Image</span>
                        <ImgUpload
                          onChange={photoUpload}
                          src={ImagePreviewUrl}
                        />
                        <p className="mt-3">
                          Image Size: <br /> Width: 480px <br /> Height: 500px
                        </p>
                        <p className="">
                          Image Format: <br /> .jpg, .jpeg, .png, .webp
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="row">
                      <div className="form-field col-md-4 me-0">
                        <label>Name</label>
                        <input
                          type="text"
                          value={storyname}
                          onChange={(e) => {
                            SetStoryName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-field col-md-4 me-0">
                        <label>Designation</label>
                        <input
                          type="text"
                          value={storydesignation}
                          onChange={(e) => {
                            SetStoryDesignation(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-field col-md-4 me-0">
                        <label>Company Name</label>
                        <input
                          type="text"
                          value={companyname}
                          onChange={(e) => {
                            SetCompanyName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-field col-12 me-0">
                        <label>Description (max. 300 characters)</label>
                        <textarea
                          cols={1}
                          rows={5}
                          value={storydescription}
                          onChange={(e) => {
                            SetStoryDescription(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-12 mb-3">
                        <label className="me-3">Image Alt text / Title</label>
                        <input
                          className="w-100"
                          type="text"
                          value={imagealt}
                          onChange={(e) => {
                            SetImageAlt(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-field col-12 me-0 form-radio">
                    <label>Status</label>
                    <div className="d-flex">
                      <span className="d-flex">
                        <input
                          type="radio"
                          name="status"
                          className="ms-2"
                          checked={status == "Active" ? true : false}
                          onChange={() => {
                            SetStatus("Active");
                          }}
                        />
                        Active
                      </span>
                      <span className="d-flex">
                        <input
                          type="radio"
                          name="status"
                          checked={status == "Inactive" ? true : false}
                          onChange={() => {
                            SetStatus("Inactive");
                          }}
                        />
                        Inactive
                      </span>
                    </div>
                  </div>
                  <div className="col-12 border-top text-end pt-3">
                    <button
                      type="button"
                      className="default-btn"
                      onClick={onEditStoryData}
                    >
                      Update
                    </button>

                    <button
                      type="button"
                      className="default-btn d-inline-block mt-3 ms-auto"
                      onClick={onCloseStoryModal}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header className="border-bottom" closeButton>
          <Offcanvas.Title>Sequentialize</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <DragDropList
            verticalData={allActiveStories}
            for="Vertical"
            htmlFor="verticle"
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
