import React, { useEffect, useState } from "react";
import { Col, NavDropdown, Offcanvas, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import {
  AiFillInstagram,
  AiFillYoutube,
  AiOutlineTwitter,
} from "react-icons/ai";
import "./Navbar.css";
import Logo from "../../assets/images/nabdelhi-logo.png";
import { SlEnvolope } from "react-icons/sl";
import GetApiCall from "../../GETAPI";
import Notiflix from "notiflix";
import Button from "react-bootstrap/Button";
import { RxHamburgerMenu } from "react-icons/rx";

function SiteNavbar() {
  const [scroll, setScroll] = useState(false);
  const [scrollSticky, setScrollSticky] = useState(false);
  const [menulist, SetMenuList] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (window.pageYOffset > 100) {
      setScroll("scroll");
    } else {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 100);
      });
    }
    if (window.pageYOffset > 100) {
      setScroll("scrollSticky");
    } else {
      window.addEventListener("scrollSticky", () => {
        setScroll(window.scrollY > 100);
      });
    }
  }, []);

  useEffect(() => {
    GetApiCall.getRequest("GetAdminMenu").then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          SetMenuList(obj.data);

          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("No Records Exits");
        }
      })
    );
  }, []);

  return (
    <>
      <div
        className={
          scroll
            ? "sticky-top shadow-sm nav-container"
            : "shadow-sm nav-container"
        }
      >
        <Navbar expand="">
          <Container fluid className="px-lg-3">
            <Navbar.Brand
              href="/"
              className="mx-auto mx-lg-0"
              aria-label="NAB DELHI LOGO"
            >
              <img src={Logo} className="logo" alt="nab delhi"></img>
            </Navbar.Brand>
            <h1 className="ms-2 mt-2">
              National Association for the Blind Delhi
            </h1>

            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand`}
              className="ms-auto"
              aria-label="NAB Delhi Admin Panel Links"
            >
              <RxHamburgerMenu />
            </Navbar.Toggle>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand`}
              aria-labelledby={`offcanvasNavbarLabel-expand`}
              placement="end"
            >
              <Offcanvas.Header
                className="ms-auto"
                closeButton
              ></Offcanvas.Header>
              <Offcanvas.Body
                className=" order-1 order-lg-2"
                data-bs-dismiss="offcanvas"
              >
                {menulist.map((menuitem, i) => {
                  return menuitem.fld_menuname == "Logout" ? (
                    <NavLink
                      lang="id"
                      hrefLang="id"
                      className="nav-link mt-3 ms-auto me-auto justify-content-start d-lg-flex  border-bottom"
                      to={menuitem.fld_menulink}
                      tabIndex={1}
                      aria-label={`NAB Delhi ${menuitem.fld_menuname} Page Link`}
                      onClick={() => {
                        localStorage.removeItem("LoginDetail");
                        localStorage.removeItem("access");
                        if (
                          localStorage.getItem("RememberMe") !== "true" ||
                          localStorage.getItem("RememberMe") === "null"
                        ) {
                          localStorage.removeItem("ClientDetails");
                          localStorage.removeItem("RememberMe");
                        }
                        localStorage.removeItem("lastlogin");
                        localStorage.removeItem("LoginDetail");
                        localStorage.removeItem("SubMenuRights");
                        window.location.href = "/";
                      }}
                    >
                      <span>
                        <i class={menuitem.fld_menuicons}></i>
                      </span>{" "}
                      {menuitem.fld_menuname}
                    </NavLink>
                  ) : (
                    <NavLink
                      lang="id"
                      hrefLang="id"
                      className="nav-link mt-3 ms-auto me-auto justify-content-start d-lg-flex  border-bottom"
                      to={menuitem.fld_menulink}
                      tabIndex={1}
                      aria-label={`NAB Delhi ${menuitem.fld_menuname} Page Link`}
                    >
                      <span>
                        <i class={menuitem.fld_menuicons}></i>
                      </span>{" "}
                      {menuitem.fld_menuname}
                    </NavLink>
                  );
                })}{" "}
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </div>
    </>
  );
}

export default SiteNavbar;
