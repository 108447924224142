import React, { useState, useEffect } from "react";
import "./ContactReport.css";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Table from "react-bootstrap/Table";
import Notiflix from "notiflix";
import GetApiCall from "../../GETAPI";

export default function ContactReport() {
  const [allContactData, setAllContactData] = useState([]);

  useEffect(() => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetContactData").then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          setAllContactData(obj.data.reverse());
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("No Records Exits");
          setAllContactData([]);
        }
      })
    );
  }, []);
  return (
    <>
      <Navbar />
      <div className="container mt-5 pb-5">
        <div className="row pb-5 mb-5">
          <div className="col-12 text-center">
            <div className="main-heading pb-1">
              <h2 className="main-title wow animate__animated animate__fadeInUp">
                Contact Report
              </h2>
              <h3 className="main-text wow animate__animated animate__fadeInUp">
                See Your All Contacts Here
              </h3>
            </div>
          </div>
          <div className="col-12">
            <div className="form-container">
              <Table responsive>
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Name</th>
                    <th>Mobile Number</th>
                    <th>Email</th>
                    <th>Message</th>
                  </tr>
                </thead>
                <tbody>
                  {allContactData.map((contactdata, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{contactdata.fld_name}</td>
                        <td>{contactdata.fld_mob_number}</td>
                        <td>{contactdata.fld_email}</td>
                        <td>{contactdata.fld_message}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
