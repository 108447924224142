import React, { useEffect, useState } from "react";
import "./Organisation.css";
import GetApiCall from "../../GETAPI";
import PostApiCall from "../../PostApi";
import Notiflix from "notiflix";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Modal from "react-bootstrap/Modal";

export default function Organisation() {
  let navigate = useNavigate();
  const [companyinfoedit, SetCompanyInfoEdit] = useState("true");
  const [modalShow, setModalShow] = React.useState(false);
  const [infoid, SetInfoId] = useState(null);
  const [companyname, SetCompanyName] = useState("");
  const [address, SetAddress] = useState("");
  const [state, SetState] = useState("");
  const [city, SetCity] = useState("");
  const [pincode, SetPinCode] = useState("");
  const [country, SetCountry] = useState("");
  const [googlelocation, SetGoogleLocation] = useState("");
  const [companyphnno, SetCompanyPhnNo] = useState("");
  const [companymobno, SetCompanyMobNo] = useState("");
  const [companyhotlineno, SetCompanyHotlineNo] = useState("");
  const [companywhatsappno, SetCompanyWhatsappNo] = useState("");
  const [companyemailid, SetCompanyEmailId] = useState("");
  const [companywebsite, SetCompanyWebsite] = useState("");
  const [facebook, SetFacebook] = useState("");
  const [instagram, SetInstagram] = useState("");
  const [linkedin, SetLinkedin] = useState("");
  const [youtube, SetYoutube] = useState("");
  const [twitter, SetTwitter] = useState("");

  const [contactpersonid, SetContactPersonId] = useState(null);
  const [contactpersonname, SetContactPersonName] = useState("");
  const [contactpersondesignation, SetContactPersonDesignation] = useState("");
  const [contactpersondepartment, SetContactPersonDepartment] = useState("");
  const [contactpersonmobileno, SetContactPersonMobileNo] = useState("");
  const [contactpersonemailid, SetContactPersonEmailId] = useState("");
  const [allcontactpersondata, SetAllContactPersonData] = useState([]);

  useEffect(() => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetOrganisationInfo").then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          SetInfoId(obj.data[0].fld_id);
          SetCompanyName(obj.data[0].fld_company_name);
          SetAddress(obj.data[0].fld_address);
          SetState(obj.data[0].fld_state);
          SetCity(obj.data[0].fld_city);
          SetPinCode(obj.data[0].fld_pincode);
          SetCountry(obj.data[0].fld_country);
          SetGoogleLocation(obj.data[0].fld_google_location);
          SetCompanyPhnNo(obj.data[0].fld_company_phn_number);
          SetCompanyMobNo(obj.data[0].fld_company_mob_number);
          SetCompanyHotlineNo(obj.data[0].fld_company_hotline_number);
          SetCompanyWhatsappNo(obj.data[0].fld_company_whatsapp_number);
          SetCompanyEmailId(obj.data[0].fld_company_email_id);
          SetCompanyWebsite(obj.data[0].fld_company_website);
          SetFacebook(obj.data[0].fld_facebook);
          SetInstagram(obj.data[0].fld_instagram);
          SetLinkedin(obj.data[0].fld_linkedin);
          SetYoutube(obj.data[0].fld_youtube);
          SetTwitter(obj.data[0].fld_twitter);

          SetCompanyInfoEdit("true");
          let all_input_element = document.getElementsByTagName("input");

          for (let i = 0; i < all_input_element.length - 5; i++) {
            all_input_element[i].setAttribute("readonly", "readonly");
          }
          document
            .getElementsByTagName("textarea")[0]
            .setAttribute("readonly", "readonly");
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("No Records Exits");
        }
      })
    );

    GetApiCall.getRequest("GetContactPersonInfoData").then(
      (resultcontactpersondata) =>
        resultcontactpersondata.json().then((obj) => {
          if (obj.data.length != 0) {
            SetAllContactPersonData(obj.data);
            Notiflix.Loading.remove();
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("No Contact Person Records Exits");
          }
        })
    );
  }, []);

  function onCompanyInfoEdit() {
    Notiflix.Loading.dots();
    SetCompanyInfoEdit("false");
    let all_input_element = document.getElementsByTagName("input");

    for (let i = 0; i < all_input_element.length - 5; i++) {
      all_input_element[i].removeAttribute("readonly");
    }
    document.getElementsByTagName("textarea")[0].removeAttribute("readonly");
    document.getElementById("companyname").focus();
    Notiflix.Loading.remove();
    Notiflix.Notify.success("Now fields are editable");
  }

  function onCompanyInfoSave() {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        infoid: infoid,
        companyname: companyname,
        address: address,
        state: state,
        city: city,
        pincode: pincode,
        country: country,
        googlelocation: googlelocation,
        companyphnno: companyphnno,
        companymobno: companymobno,
        companyhotlineno: companyhotlineno,
        companywhatsappno: companywhatsappno,
        companyemailid: companyemailid,
        companywebsite: companywebsite,
        facebook: facebook,
        instagram: instagram,
        linkedin: linkedin,
        youtube: youtube,
        twitter: twitter,
      },
      "AddOrganisationInfo"
    ).then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          Notiflix.Notify.success(
            "Organisation Information Save Successfully."
          );

          GetApiCall.getRequest("GetOrganisationInfo").then((resultdes) =>
            resultdes.json().then((obj) => {
              if (obj.data.length != 0) {
                SetInfoId(obj.data[0].fld_id);
                SetCompanyName(obj.data[0].fld_company_name);
                SetAddress(obj.data[0].fld_address);
                SetState(obj.data[0].fld_state);
                SetCity(obj.data[0].fld_city);
                SetPinCode(obj.data[0].fld_pincode);
                SetCountry(obj.data[0].fld_country);
                SetGoogleLocation(obj.data[0].fld_google_location);
                SetCompanyPhnNo(obj.data[0].fld_company_phn_number);
                SetCompanyMobNo(obj.data[0].fld_company_mob_number);
                SetCompanyHotlineNo(obj.data[0].fld_company_hotline_number);
                SetCompanyWhatsappNo(obj.data[0].fld_company_whatsapp_number);
                SetCompanyEmailId(obj.data[0].fld_company_email_id);
                SetCompanyWebsite(obj.data[0].fld_company_website);
                SetFacebook(obj.data[0].fld_facebook);
                SetInstagram(obj.data[0].fld_instagram);
                SetLinkedin(obj.data[0].fld_linkedin);
                SetYoutube(obj.data[0].fld_youtube);
                SetTwitter(obj.data[0].fld_twitter);

                SetCompanyInfoEdit("true");
                let all_input_element = document.getElementsByTagName("input");

                for (let i = 0; i < all_input_element.length - 5; i++) {
                  all_input_element[i].setAttribute("readonly", "readonly");
                }
                document
                  .getElementsByTagName("textarea")[0]
                  .setAttribute("readonly", "readonly");
                Notiflix.Loading.remove();
              } else {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure("No Records Exits");
              }
            })
          );
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(obj1.data);
        }
      })
    );
  }

  function onContactPersonInfoSave() {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        contactpersonid: contactpersonid,
        contactpersonname: contactpersonname,
        contactpersondesignation: contactpersondesignation,
        contactpersondepartment: contactpersondepartment,
        contactpersonmobileno: contactpersonmobileno,
        contactpersonemailid: contactpersonemailid,
      },
      "AddContactPersonInfo"
    ).then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          if (modalShow == true) {
            Notiflix.Notify.success(
              "Contact Person Information Update Successfully."
            );
          } else {
            Notiflix.Notify.success(
              "Contact Person Information Save Successfully."
            );
          }
          setModalShow(false);
          SetContactPersonId(null);
          SetContactPersonName("");
          SetContactPersonDesignation("");
          SetContactPersonDepartment("");
          SetContactPersonMobileNo("");
          SetContactPersonEmailId("");
          GetApiCall.getRequest("GetContactPersonInfoData").then(
            (resultcontactpersondata) =>
              resultcontactpersondata.json().then((obj) => {
                if (obj.data.length != 0) {
                  SetAllContactPersonData(obj.data);
                  Notiflix.Loading.remove();
                } else {
                  Notiflix.Loading.remove();
                  Notiflix.Notify.failure("No Contact Person Records Exits");
                }
              })
          );
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("No Contact Person Records Exits");
        }
      })
    );
  }

  function onCloseContactPersonModal() {
    setModalShow(false);
    SetContactPersonId(null);
    SetContactPersonName("");
    SetContactPersonDesignation("");
    SetContactPersonDepartment("");
    SetContactPersonMobileNo("");
    SetContactPersonEmailId("");
  }

  return (
    <>
      <Navbar />
      <div className="container mt-5">
        <div className="row">
          <div className="col-12 text-center">
            <div className="main-heading pb-1">
              <h2 className="main-title wow animate__animated animate__fadeInUp">
                Organisation Details
              </h2>
              <h3 className="main-text wow animate__animated animate__fadeInUp">
                This module helps you in Managing the Organisation Details
              </h3>
            </div>
          </div>

          <div className="col-12">
            <div className="form-container">
              <h3 className="main-text fw-bold">Office Information</h3>
              <form>
                <div className="form-field w-100">
                  <label>Company Name</label>
                  <input
                    type="text"
                    id="companyname"
                    value={companyname}
                    readOnly="readonly"
                    onChange={(e) => {
                      SetCompanyName(e.target.value);
                    }}
                  />
                </div>
                <div className="form-field w-100">
                  <label>Address</label>
                  <textarea
                    rows={5}
                    cols={1}
                    readOnly="readonly"
                    value={address}
                    onChange={(e) => {
                      SetAddress(e.target.value);
                    }}
                  />
                </div>
                <div className="row w-100">
                  <div className="form-field col-md-3 me-0">
                    <label>State</label>
                    <input
                      type="text"
                      readOnly="readonly"
                      value={state}
                      onChange={(e) => {
                        SetState(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-field col-md-3 me-0">
                    <label>City</label>
                    <input
                      type="text"
                      readOnly="readonly"
                      value={city}
                      onChange={(e) => {
                        SetCity(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-field col-md-3 me-0">
                    <label>Pincode</label>
                    <input
                      type="text"
                      readOnly="readonly"
                      value={pincode}
                      onChange={(e) => {
                        SetPinCode(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-field col-md-3 me-0">
                    <label>Country</label>
                    <input
                      type="text"
                      readOnly="readonly"
                      value={country}
                      onChange={(e) => {
                        SetCountry(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="form-field w-100 border-bottom pb-4">
                  <label>Google Location</label>
                  <input
                    type="text"
                    readOnly="readonly"
                    value={googlelocation}
                    onChange={(e) => {
                      SetGoogleLocation(e.target.value);
                    }}
                  />
                </div>
              </form>

              <h3 className="main-text fw-bold">Contact Information</h3>
              <form>
                <div className="row w-100">
                  <div className="form-field col-md-3 me-0">
                    <label>Phone Number</label>
                    <input
                      type="text"
                      readOnly="readonly"
                      value={companyphnno}
                      onChange={(e) => {
                        SetCompanyPhnNo(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-field col-md-3 me-0">
                    <label>Mobile Number</label>
                    <input
                      type="text"
                      readOnly="readonly"
                      value={companymobno}
                      onChange={(e) => {
                        SetCompanyMobNo(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-field col-md-3 me-0">
                    <label>Hotline Number</label>
                    <input
                      type="text"
                      readOnly="readonly"
                      value={companyhotlineno}
                      onChange={(e) => {
                        SetCompanyHotlineNo(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-field col-md-3 me-0">
                    <label>Whatsapp Number</label>
                    <input
                      type="text"
                      readOnly="readonly"
                      value={companywhatsappno}
                      onChange={(e) => {
                        SetCompanyWhatsappNo(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row w-100">
                  <div className="form-field col-md-6 me-0">
                    <label>Email ID</label>
                    <input
                      type="text"
                      readOnly="readonly"
                      value={companyemailid}
                      onChange={(e) => {
                        SetCompanyEmailId(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-field col-md-6 me-0">
                    <label>Website</label>
                    <input
                      type="text"
                      readOnly="readonly"
                      value={companywebsite}
                      onChange={(e) => {
                        SetCompanyWebsite(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </form>
              <h3 className="main-text fw-bold mt-2 pt-3 border-top">
                Social Media
              </h3>
              <form>
                <div className="row w-100 border-bottom mx-auto">
                  <div className="form-field col-md-4 me-0">
                    <label>Facebook</label>
                    <input
                      type="text"
                      readOnly="readonly"
                      value={facebook}
                      onChange={(e) => {
                        SetFacebook(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-field col-md-4 me-0">
                    <label>Instagram</label>
                    <input
                      type="text"
                      readOnly="readonly"
                      value={instagram}
                      onChange={(e) => {
                        SetInstagram(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-field col-md-4 me-0">
                    <label>Linkedin</label>
                    <input
                      type="text"
                      readOnly="readonly"
                      value={linkedin}
                      onChange={(e) => {
                        SetLinkedin(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-field col-md-4 me-0">
                    <label>Youtube</label>
                    <input
                      type="text"
                      readOnly="readonly"
                      value={youtube}
                      onChange={(e) => {
                        SetYoutube(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-field col-md-4 me-0">
                    <label>Twitter</label>
                    <input
                      type="text"
                      readOnly="readonly"
                      value={twitter}
                      onChange={(e) => {
                        SetTwitter(e.target.value);
                      }}
                    />
                  </div>
                </div>
                {companyinfoedit == "true" ? (
                  <button
                    type="button"
                    className="default-btn d-inline-block mt-3 ms-auto"
                    onClick={onCompanyInfoEdit}
                  >
                    Edit Information
                  </button>
                ) : (
                  <button
                    type="button"
                    className="default-btn d-inline-block mt-3 ms-auto"
                    onClick={onCompanyInfoSave}
                  >
                    Save Information
                  </button>
                )}
              </form>
            </div>
          </div>
          <div className="col-12 mt-5">
            <div className="form-container">
              <h3 className="main-text fw-bold mt-2">Contact Persons</h3>
              {allcontactpersondata.map((persondata, i) => {
                return (
                  <form>
                    <div className="row w-100 border-bottom mx-auto">
                      <div className="form-field col-md-6 me-0">
                        <label>Name</label>
                        <input
                          type="text"
                          value={persondata.fld_name}
                          readOnly="readonly"
                          onChange={(e) => {
                            SetContactPersonName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-field col-md-6 me-0">
                        <label>Designation</label>
                        <input
                          type="text"
                          value={persondata.fld_designation}
                          readOnly="readonly"
                          onChange={(e) => {
                            SetContactPersonDesignation(e.target.name);
                          }}
                        />
                      </div>
                      <div className="form-field col-md-4 me-0">
                        <label>Department</label>
                        <input
                          type="text"
                          value={persondata.fld_department}
                          readOnly="readonly"
                          onChange={(e) => {
                            SetContactPersonDepartment(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-field col-md-4 me-0">
                        <label>Mobile Number</label>
                        <input
                          type="text"
                          value={persondata.fld_mobile_no}
                          readOnly="readonly"
                          onChange={(e) => {
                            SetContactPersonMobileNo(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-field col-md-4 me-0">
                        <label>Email Id</label>
                        <input
                          type="text"
                          value={persondata.fld_email_id}
                          readOnly="readonly"
                          onChange={(e) => {
                            SetContactPersonEmailId(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <button
                      type="button"
                      className="default-btn d-inline-block mt-3 ms-auto"
                      onClick={() => {
                        confirmAlert({
                          title: "Confirm to Delete",
                          message:
                            "Are you sure you want to delete the contact person information?",
                          buttons: [
                            {
                              label: "Yes",
                              onClick: () => {
                                PostApiCall.postRequest(
                                  {
                                    id: persondata.fld_id,
                                  },
                                  "DeleteContactPersonInfo"
                                ).then((results) =>
                                  results.json().then((obj) => {
                                    if (
                                      results.status == 200 ||
                                      results.status == 201
                                    ) {
                                      Notiflix.Loading.remove();
                                      Notiflix.Notify.success(
                                        "Contact person information successfully deleted."
                                      );
                                      window.location.reload();
                                    } else {
                                      Notiflix.Loading.remove();
                                      Notiflix.Notify.failure(
                                        "Something went wrong, try again later."
                                      );
                                    }
                                  })
                                );
                              },
                            },
                            {
                              label: "No",
                            },
                          ],
                        });
                      }}
                    >
                      Delete
                    </button>

                    <button
                      type="button"
                      className="default-btn d-inline-block mt-3"
                      onClick={() => {
                        setModalShow(true);
                        SetContactPersonId(persondata.fld_id);
                        SetContactPersonName(persondata.fld_name);
                        SetContactPersonDesignation(persondata.fld_designation);
                        SetContactPersonDepartment(persondata.fld_department);
                        SetContactPersonMobileNo(persondata.fld_mobile_no);
                        SetContactPersonEmailId(persondata.fld_email_id);
                      }}
                    >
                      Edit
                    </button>
                  </form>
                );
              })}
            </div>
          </div>
          <Modal
            show={modalShow}
            onHide={() => setModalShow(false)}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="modal-90w"
            backdrop="static"
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">Edit</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="col-12">
                <div className="form-container">
                  <h3 className="main-text fw-bold mt-2">Contact Persons</h3>
                  <form>
                    <div className="row w-100 border-bottom mx-auto">
                      <div className="form-field col-md-6 me-0">
                        <label>Name</label>
                        <input
                          type="text"
                          value={contactpersonname}
                          onChange={(e) => {
                            SetContactPersonName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-field col-md-6 me-0">
                        <label>Designation</label>
                        <input
                          type="text"
                          value={contactpersondesignation}
                          onChange={(e) => {
                            SetContactPersonDesignation(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-field col-md-4 me-0">
                        <label>Department</label>
                        <input
                          type="text"
                          value={contactpersondepartment}
                          onChange={(e) => {
                            SetContactPersonDepartment(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-field col-md-4 me-0">
                        <label>Mobile Number</label>
                        <input
                          type="text"
                          value={contactpersonmobileno}
                          onChange={(e) => {
                            SetContactPersonMobileNo(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-field col-md-4 me-0">
                        <label>Email Id</label>
                        <input
                          type="text"
                          value={contactpersonemailid}
                          onChange={(e) => {
                            SetContactPersonEmailId(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <button
                      type="button"
                      className="default-btn d-inline-block mt-3 ms-auto"
                      onClick={onCloseContactPersonModal}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="default-btn d-inline-block mt-3"
                      onClick={onContactPersonInfoSave}
                    >
                      Update Contact Information
                    </button>
                  </form>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <div className="col-12 mt-5">
            <div className="form-container">
              <h3 className="main-text fw-bold mt-2">Add Contact Persons</h3>
              <form>
                <div className="row w-100 border-bottom mx-auto">
                  <div className="form-field col-md-6 me-0">
                    <label>Name</label>
                    <input
                      type="text"
                      value={contactpersonname}
                      onChange={(e) => {
                        SetContactPersonName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-field col-md-6 me-0">
                    <label>Designation</label>
                    <input
                      type="text"
                      value={contactpersondesignation}
                      onChange={(e) => {
                        SetContactPersonDesignation(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-field col-md-4 me-0">
                    <label>Department</label>
                    <input
                      type="text"
                      value={contactpersondepartment}
                      onChange={(e) => {
                        SetContactPersonDepartment(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-field col-md-4 me-0">
                    <label>Mobile Number</label>
                    <input
                      type="text"
                      value={contactpersonmobileno}
                      onChange={(e) => {
                        SetContactPersonMobileNo(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-field col-md-4 me-0">
                    <label>Email Id</label>
                    <input
                      type="text"
                      value={contactpersonemailid}
                      onChange={(e) => {
                        SetContactPersonEmailId(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <button
                  type="button"
                  className="default-btn d-inline-block mt-3 ms-auto"
                  onClick={onContactPersonInfoSave}
                >
                  Save Contact Information
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
