import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { RxHamburgerMenu } from "react-icons/rx";
import PostApiCall from "../../PostApi";
import { BsFillFilePdfFill } from "react-icons/bs";
import "./DragDropList.css";
const DragDropList = (props) => {
	const [data, setData] = useState([]);
	const [image, setImage] = useState();
	const [description, setDescription] = useState();
	const [index, setIndex] = useState();
	const [id, setId] = useState();
	const handleDragEnd = (e) => {
		if (!e.destination) return;
		let tempData = Array.from(data);
		let [source_data] = tempData.splice(e.source.index, 1);
		tempData.splice(e.destination.index, 0, source_data);
		setData(tempData);
	};
	useEffect(() => {
		if (data.length <= 0) {
			setData(props.verticalData);
		}
	});
	return (
		<div className="App serialize-side-drawer">
			<DragDropContext onDragEnd={handleDragEnd}>
				<div className="table borderd mb-0 pb-0 mt-5 px-3">
					<Droppable droppableId="droppable-1">
						{(provider) => (
							<div
								className="text-capitalize"
								ref={provider.innerRef}
								{...provider.droppableProps}
							>
								{data?.map((user, index) => (
									<Draggable
										key={"" + user.fld_id}
										draggableId={"" + user.fld_id}
										index={index}
									>
										{(provider) => (
											<div
												className="container-content sequentialize-parent w-100 mt-3 d-flex justify-content-between align-items-center border-bottom"
												{...provider.draggableProps}
												value={index}
												ref={provider.innerRef}
											>
												<div
													key={index}
													style={{ width: "20%", height: "100%", flex: 1 }}
												>
													<a href={user.fld_document} target="_blank">
														<BsFillFilePdfFill />
													</a>
													<div
														className="w-100"
														value={description}
														onChange={(e) => setDescription(e.target.value)}
													>
														{user.fld_name}
													</div>
												</div>
												<div {...provider.dragHandleProps}>
													{" "}
													<RxHamburgerMenu className="p-0" />
												</div>
											</div>
										)}
									</Draggable>
								))}
								{provider.placeholder}
							</div>
						)}
					</Droppable>
				</div>
			</DragDropContext>
			<div className="sequence-save-btn bg-white">
				<button
					className="default-btn ms-auto mt-4 float-end"
					onClick={(e) => {
						{
							e.preventDefault();
							Notiflix.Loading.dots();
							PostApiCall.postRequest(
								{
									newsletterdata: data,
								},
								"AddNewsLetterSequence"
							).then((response) => {
								response.json().then((result) => {
									Notiflix.Loading.remove();
									Notiflix.Notify.success("Saved Successfully");
									window.location.reload();
								});
							});
						}
					}}
					style={{ fontSize: "15px" }}
				>
					Save this Sequence
				</button>
			</div>
		</div>
	);
};
export default DragDropList;
