import React, { useEffect, useCallback } from "react";
import "./Events.css";
import { useState } from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import Notiflix from "notiflix";
import GetApiCall from "../../GETAPI";
import DropBoxUploader from "../../components/DropBoxUploader/DropBoxUploader";
import ShowImage from "../../components/DropBoxUploader/ShowImage";
import moment from "moment";
import PostApiCall from "../../PostApi";
import imageCompression from "browser-image-compression";
import { confirmAlert } from "react-confirm-alert";
import { MdClose } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import createDOMPurify from "dompurify";
const DOMPurify = createDOMPurify(window);

const CoverImgUpload = ({ onChange, src }) => (
  <label
    htmlFor="cover-photo-upload"
    className="custom-file-upload fas"
    style={{ marginBottom: "-5px" }}
  >
    <div className="img-wrap obimages img-upload">
      <img
        htmlFor="cover-photo-upload"
        src={src}
        style={{ width: "100%", height: "100%", borderRadius: "5%" }}
      />
    </div>
    <input
      accept="image/*"
      id="cover-photo-upload"
      type="file"
      onChange={onChange}
    />
  </label>
);

const InnerImgUpload = ({ onChange, src }) => (
  <label
    htmlFor="inner-photo-upload"
    className="custom-file-upload fas"
    style={{ marginBottom: "-5px" }}
  >
    <div className="img-wrap obimages img-upload">
      <img
        htmlFor="inner-photo-upload"
        src={src}
        style={{ width: "100%", height: "100%", borderRadius: "5%" }}
      />
    </div>
    <input
      accept="image/*"
      id="inner-photo-upload"
      type="file"
      onChange={onChange}
    />
  </label>
);

const GalleryImgUpload = ({ onChange, src, id }) => (
  <label
    htmlFor={`galleryphoto-upload ${id}`}
    className="gallery-custom-file-upload fas"
    id={id}
  >
    <div className="img-wrap img-upload">
      <img htmlFor={`galleryphoto-upload ${id}`} src={src} />
    </div>
    <input
      accept="image/*"
      id={`galleryphoto-upload ${id}`}
      type="file"
      onChange={onChange}
    />
  </label>
);
export default function Events() {
  let navigate = useNavigate();
  const [ImagePreviewUrl, SetImagePreviewUrl] = useState(
    "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png"
  );
  const ImageApiUrl = "https://cms.nabdelhi.in/nabdapi/UploadGalleryImage";
  const [coverimagePreviewUrl, SetCoverImagePreviewUrl] = useState(
    "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png"
  );
  const [innerimagePreviewUrl, SetInnerImagePreviewUrl] = useState(
    "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png"
  );
  const [inneroriginalImage, SetInnerOriginalImage] = useState([]);
  const [inneroutputFileName, SetInnerOutputFileName] = useState("");
  const [inneruploadImage, SetInnerUploadImage] = useState(false);
  const [coveroriginalImage, SetCoverOriginalImage] = useState([]);
  const [coveroutputFileName, SetCoverOutputFileName] = useState("");
  const [coveruploadImage, SetCoverUploadImage] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [OriginalImage, SetOriginalImage] = useState([]);
  const [outputFileName, SetOutputFileName] = useState("");
  const [UploadImage, SetUploadImage] = useState(false);
  const [alleventsdata, setAllEventsData] = useState([]);
  const [innerimagealt, setInnerImageAlt] = useState("");
  const [eventname, setEventName] = useState("");
  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");
  const [venue, setVenue] = useState("");
  const [location, setLocation] = useState("");
  const [innerstartdate, setInnerStartDate] = useState("");
  const [innerenddate, setInnerEndDate] = useState("");
  const [description, setDescription] = useState("");
  const [coverimagealt, setCoverImageAlt] = useState("");
  const [currinnerimage, setCurrInnerImage] = useState("");
  const [images, SetImages] = useState([]);
  const [imageListHide, setImageListHide] = useState(false);
  const [alleventsgalleryimages, setAllEventsGalleryImages] = useState([]);
  const [eventsid, setEventsId] = useState(null);
  const [status, setStatus] = useState("Active");

  const coverphotoUpload = (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    SetCoverImagePreviewUrl(URL.createObjectURL(imageFile));
    SetCoverOriginalImage(imageFile);
    SetCoverOutputFileName(imageFile.name);
    var imagename = imageFile.name.split(".");
    setCoverImageAlt(imagename[0]);
    SetCoverUploadImage(true);
  };

  const innerphotoUpload = (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    SetInnerImagePreviewUrl(URL.createObjectURL(imageFile));
    SetInnerOriginalImage(imageFile);
    SetInnerOutputFileName(imageFile.name);
    var imagename = imageFile.name.split(".");
    setInnerImageAlt(imagename[0]);
    SetInnerUploadImage(true);
  };

  let onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file, index) => {
      var newrandomnumber = Math.floor(100000 + Math.random() * 900000);
      const reader = new FileReader();

      reader.onload = function (e) {
        SetImages((prevState) => [
          ...prevState,
          {
            id: index,
            src: e.target.result,
            name: file.name,
            file: file,
            randomnumber: newrandomnumber,
            uploadtype: "events",
          },
        ]);
      };

      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  useEffect(() => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetEventsData").then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          setAllEventsData(obj.data);
          setInnerImageAlt(obj.data[0].fld_inner_image_alt);
          setEventName(obj.data[0].fld_event_name);
          setInnerStartDate(
            moment(obj.data[0].fld_start_date).format("DD-MM-YYYY")
          );
          setInnerEndDate(
            moment(obj.data[0].fld_end_date).format("DD-MM-YYYY")
          );
          setDescription(obj.data[0].fld_description);
          setCurrInnerImage(obj.data[0].fld_inner_image);
          localStorage.setItem("eventsid", obj.data[0].fld_id);
          PostApiCall.postRequest(
            {
              whereClause: `where fld_event_id=${obj.data[0].fld_id}`,
            },
            "GetEventsGalleryImages"
          ).then((results1) =>
            results1.json().then((obj1) => {
              if (results1.status == 200 || results1.status == 201) {
                if (obj1.data.length != 0) {
                  setAllEventsGalleryImages(obj1.data);
                  SetImages([]);
                  Notiflix.Loading.remove();
                } else {
                  setAllEventsGalleryImages([]);
                }
              } else {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure("Error Occurred");
              }
            })
          );
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("No Records Exits");
          setAllEventsData([]);
        }
      })
    );
  }, []);

  function onEventsGalleryUploadImage(imageFile, randomnumber) {
    var options = {
      maxSizeMB: 0.05,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    imageCompression(imageFile, options).then(async (compressedFile) => {
      const form = new FormData();
      form.append("file", compressedFile);
      form.append("foldername", "Activities");
      form.append("filename", randomnumber + "-" + imageFile.name);
      let response = await fetch(ImageApiUrl, {
        method: "POST",
        body: form,
      }).then((response) => {
        if (response.status == 200 || response.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Image Uploaded Successfully");
          PostApiCall.postRequest(
            {
              whereClause: `where fld_event_id=${parseInt(
                localStorage.getItem("eventsid")
              )}`,
            },
            "GetEventsGalleryImages"
          ).then((results1) =>
            results1.json().then((obj1) => {
              if (results1.status == 200 || results1.status == 201) {
                if (obj1.data.length != 0) {
                  setAllEventsGalleryImages(obj1.data);
                  Notiflix.Loading.remove();
                } else {
                  setAllEventsGalleryImages([]);
                }
              } else {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure("Error Occurred");
              }
            })
          );
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Image Uploading Failed");
        }
      });
    });
  }

  function onUploadEventsMasterImage(imageFileName, randomnumber, imagetype) {
    var options = {
      maxSizeMB: 0.05,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    if (imagetype == "innerimage") {
      imageCompression(inneroriginalImage, options).then(
        async (compressedFile) => {
          const form = new FormData();
          form.append("file", compressedFile);
          form.append("foldername", "Activities");
          form.append("filename", randomnumber + "-" + imageFileName);
          let response = await fetch(ImageApiUrl, {
            method: "POST",
            body: form,
          }).then((response) => {
            if (response.status == 200 || response.status == 201) {
              Notiflix.Loading.remove();
              Notiflix.Notify.success("Services Updated Successfully.");
            } else {
              Notiflix.Loading.remove();
              Notiflix.Notify.failure("Image Uploading Failed");
            }
          });
        }
      );
    } else {
      imageCompression(coveroriginalImage, options).then(
        async (compressedFile) => {
          const form = new FormData();
          form.append("file", compressedFile);
          form.append("foldername", "Activities");
          form.append("filename", randomnumber + "-" + imageFileName);
          let response = await fetch(ImageApiUrl, {
            method: "POST",
            body: form,
          }).then((response) => {
            if (response.status == 200 || response.status == 201) {
              Notiflix.Loading.remove();
              Notiflix.Notify.success("Services Updated Successfully.");
            } else {
              Notiflix.Loading.remove();
              Notiflix.Notify.failure("Image Uploading Failed");
            }
          });
        }
      );
    }
    SetInnerOriginalImage([]);
    SetCoverOriginalImage([]);
  }

  function onSaveEventsData() {
    var randomnumber = Math.floor(100000 + Math.random() * 900000);
    var coverimagetype = coveroutputFileName.split(".");
    var coverimagename = coverimagealt + "." + coverimagetype[1];
    var innerimagetype = inneroutputFileName.split(".");
    var innerimagename = innerimagealt + "." + innerimagetype[1];
    Notiflix.Loading.dots();
    var eventsobj = {};
    if (inneroriginalImage.length != 0 || coveroriginalImage.length != 0) {
      if (inneroriginalImage.length != 0 && coveroriginalImage.length == 0) {
        eventsobj = {
          id: eventsid,
          innerimage:
            "https://cms.nabdelhi.in/images/Activities/" +
            randomnumber +
            "-" +
            innerimagename,
          innerimagealt: innerimagealt,
          eventname: eventname,
          startdate: startdate,
          enddate: enddate,
          venue: venue,
          location: location,
          description: description,
          coverimage: coverimagePreviewUrl,
          coverimagealt: coverimagealt,
          status: status,
          updatedon: new Date(),
          createdon: new Date(),
        };
      } else if (
        inneroriginalImage.length != 0 &&
        coveroriginalImage.length != 0
      ) {
        eventsobj = {
          id: eventsid,
          innerimage:
            "https://cms.nabdelhi.in/images/Activities/" +
            randomnumber +
            "-" +
            innerimagename,
          innerimagealt: innerimagealt,
          eventname: eventname,
          startdate: startdate,
          enddate: enddate,
          venue: venue,
          location: location,
          description: description,
          coverimage:
            "https://cms.nabdelhi.in/images/Activities/" +
            randomnumber +
            "-" +
            coverimagename,
          coverimagealt: coverimagealt,
          status: status,
          updatedon: new Date(),
          createdon: new Date(),
        };
      } else if (
        coveroriginalImage.length != 0 &&
        inneroriginalImage.length == 0
      ) {
        eventsobj = {
          id: eventsid,
          innerimage: innerimagePreviewUrl,
          innerimagealt: innerimagealt,
          eventname: eventname,
          startdate: startdate,
          enddate: enddate,
          venue: venue,
          location: location,
          description: description,
          coverimage:
            "https://cms.nabdelhi.in/images/Activities/" +
            randomnumber +
            "-" +
            coverimagename,
          coverimagealt: coverimagealt,
          status: status,
          updatedon: new Date(),
          createdon: new Date(),
        };
      }
    } else {
      eventsobj = {
        id: eventsid,
        innerimage: innerimagePreviewUrl,
        innerimagealt: innerimagealt,
        eventname: eventname,
        startdate: startdate,
        enddate: enddate,
        venue: venue,
        location: location,
        description: description,
        coverimage: coverimagePreviewUrl,
        coverimagealt: coverimagealt,
        status: status,
        updatedon: new Date(),
        createdon: new Date(),
      };
    }
    PostApiCall.postRequest(eventsobj, "AddEvents").then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          if (
            inneroriginalImage.length != 0 ||
            coveroriginalImage.length != 0
          ) {
            if (
              inneroriginalImage.length != 0 &&
              coveroriginalImage.length == 0
            ) {
              onUploadEventsMasterImage(
                innerimagename,
                randomnumber,
                "innerimage"
              );
            } else if (
              inneroriginalImage.length != 0 &&
              coveroriginalImage.length != 0
            ) {
              onUploadEventsMasterImage(
                innerimagename,
                randomnumber,
                "innerimage"
              );
              onUploadEventsMasterImage(
                coverimagename,
                randomnumber,
                "coverimage"
              );
            } else if (
              coveroriginalImage.length != 0 &&
              inneroriginalImage.length == 0
            ) {
              onUploadEventsMasterImage(
                coverimagename,
                randomnumber,
                "coverimage"
              );
            }
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.success("Events Updated Successfully");
          }
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
  }

  function onCloseEventsModal() {
    window.location.reload();
  }

  function onAddEvents() {
    navigate("/addevents");
  }
  return (
    <>
      <Navbar />

      <div className="container mt-5">
        <div className="row">
          <div className="col-12 text-center">
            <div className="main-heading pb-1">
              <h2 className="main-title wow animate__animated animate__fadeInUp">
                Events
              </h2>
              <h3 className="main-text wow animate__animated animate__fadeInUp">
                Manage Upcoming Events
              </h3>
            </div>
          </div>
          <div className="col-12">
            <div className="row service-section form-container event-right">
              <div className="mb-3 d-flex justify-content-between border-bottom pb-3">
                <h3 class="main-text fw-bold">Events List</h3>
                <button
                  type="button"
                  className="default-btn"
                  onClick={() => {
                    onAddEvents();
                  }}
                >
                  Add New Event
                </button>
              </div>
              <div
                className="col-12"
                style={{
                  display: alleventsdata.length == 0 ? "none" : "block",
                }}
              >
                <h3 class="main-text fw-bold">Events Inner Page List</h3>

                <div className="service-card service-card-inner">
                  <div className="s-card-img">
                    <img
                      src={currinnerimage}
                      alt={innerimagealt}
                      className="img-fluid"
                    />

                    <div className="s-content mt-3">
                      <div className="d-flex justify-content-between event-inner">
                        <h4>{eventname}</h4>
                        <div className="text-end mb-1">
                          <span>Start {innerstartdate}</span>
                          {"  "}
                          <span>End {innerenddate}</span>
                        </div>
                      </div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(description),
                        }}
                      />
                    </div>
                    <div className="mt-3 row">
                      <div className="col-12 mb-3">
                        <div className="row mt-3 ms-3">
                          {alleventsgalleryimages.length == 0 ? (
                            <h3 className="form-field">No images</h3>
                          ) : (
                            <div className="row mb-5">
                              <h3 className="form-field">Images</h3>
                              {alleventsgalleryimages.map((imagedata, i) => {
                                return (
                                  <div className="col-3 image-main image-main-2">
                                    <GalleryImgUpload
                                      id={imagedata.fld_id}
                                      onChange={(e) => {
                                        e.preventDefault();
                                        const imageFile = e.target.files[0];
                                        var randomnumber = Math.floor(
                                          100000 + Math.random() * 900000
                                        );

                                        if (imageFile.name != undefined) {
                                          PostApiCall.postRequest(
                                            {
                                              id: imagedata.fld_id,
                                              eventid: parseInt(
                                                localStorage.getItem("eventid")
                                              ),
                                              image:
                                                "https://cms.nabdelhi.in/images/Activities/" +
                                                randomnumber +
                                                "-" +
                                                imageFile.name,
                                              imagealt: imagedata.fld_image_alt,
                                              createdon: new Date(),
                                              updatedon: new Date(),
                                            },
                                            "AddEventsGalleryImage"
                                          ).then((results1) =>
                                            results1.json().then((obj1) => {
                                              if (
                                                results1.status == 200 ||
                                                results1.status == 201
                                              ) {
                                                Notiflix.Loading.remove();
                                                Notiflix.Notify.success(
                                                  "Image Uploading in Process"
                                                );
                                                onEventsGalleryUploadImage(
                                                  imageFile,
                                                  randomnumber
                                                );
                                              } else {
                                                Notiflix.Loading.remove();
                                                Notiflix.Notify.failure(obj1);
                                              }
                                            })
                                          );
                                        }
                                      }}
                                      src={imagedata.fld_image}
                                    />
                                    <span
                                      onClick={() => {
                                        confirmAlert({
                                          title: "Confirm to Delete",
                                          message:
                                            "Are you sure you want to delete the image?",

                                          buttons: [
                                            {
                                              label: "Yes",
                                              onClick: () => {
                                                Notiflix.Loading.dots();
                                                PostApiCall.postRequest(
                                                  {
                                                    id: imagedata.fld_id,
                                                  },
                                                  "DeleteEventsGalleryImage"
                                                ).then((results) =>
                                                  results.json().then((obj) => {
                                                    if (
                                                      results.status == 200 ||
                                                      results.status == 201
                                                    ) {
                                                      Notiflix.Loading.remove();
                                                      Notiflix.Notify.success(
                                                        "Image successfully deleted."
                                                      );

                                                      PostApiCall.postRequest(
                                                        {
                                                          whereClause: `where fld_event_id=${parseInt(
                                                            localStorage.getItem(
                                                              "eventsid"
                                                            )
                                                          )}`,
                                                        },
                                                        "GetEventsGalleryImages"
                                                      ).then((results1) =>
                                                        results1
                                                          .json()
                                                          .then((obj1) => {
                                                            if (
                                                              results1.status ==
                                                                200 ||
                                                              results1.status ==
                                                                201
                                                            ) {
                                                              if (
                                                                obj1.data
                                                                  .length != 0
                                                              ) {
                                                                setAllEventsGalleryImages(
                                                                  obj1.data
                                                                );
                                                                Notiflix.Loading.remove();
                                                              } else {
                                                                setAllEventsGalleryImages(
                                                                  []
                                                                );
                                                                Notiflix.Notify.failure(
                                                                  "No Data Exists"
                                                                );
                                                              }
                                                            } else {
                                                              Notiflix.Loading.remove();
                                                              Notiflix.Notify.failure(
                                                                "Error Occurred"
                                                              );
                                                            }
                                                          })
                                                      );
                                                    } else {
                                                      Notiflix.Loading.remove();
                                                      Notiflix.Notify.failure(
                                                        "Something went wrong, try again later."
                                                      );
                                                    }
                                                  })
                                                );
                                              },
                                            },
                                            {
                                              label: "No",
                                            },
                                          ],
                                          overlayClassName:
                                            "overlay-custom-class-name",
                                        });
                                      }}
                                    >
                                      <MdClose />
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                          <div className="col-12 inner-image mb-3 loader-backdrop mt-5">
                            <h3 className="form-field">Add Image</h3>
                            <DropBoxUploader
                              onDrop={onDrop}
                              imageListHide={imageListHide}
                            />
                            <ShowImage images={images} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {alleventsdata.map((eventdata, i) => {
                return (
                  <div className="col-md-6 p-3">
                    <div className="event-card">
                      <img
                        className="img-fluid"
                        src={eventdata.fld_cover_image}
                      />
                      <div className="event-right-content">
                        <span>
                          {moment(eventdata.fld_start_date).format(
                            "DD-MM-YYYY"
                          )}
                        </span>
                        <h4
                          onClick={() => {
                            setInnerImageAlt(eventdata.fld_inner_image_alt);
                            setEventName(eventdata.fld_event_name);
                            setInnerStartDate(
                              moment(eventdata.fld_start_date).format(
                                "DD-MM-YYYY"
                              )
                            );
                            setInnerEndDate(
                              moment(eventdata.fld_end_date).format(
                                "DD-MM-YYYY"
                              )
                            );
                            setDescription(eventdata.fld_description);
                            SetImages([]);
                            setImageListHide(true);
                            setCurrInnerImage(eventdata.fld_inner_image);
                            localStorage.setItem("eventsid", eventdata.fld_id);
                            Notiflix.Loading.dots();
                            PostApiCall.postRequest(
                              {
                                whereClause: `where fld_event_id=${eventdata.fld_id}`,
                              },
                              "GetEventsGalleryImages"
                            ).then((results1) =>
                              results1.json().then((obj1) => {
                                if (
                                  results1.status == 200 ||
                                  results1.status == 201
                                ) {
                                  if (obj1.data.length != 0) {
                                    setAllEventsGalleryImages(obj1.data);
                                    Notiflix.Loading.remove();
                                  } else {
                                    Notiflix.Loading.remove();
                                    setAllEventsGalleryImages([]);
                                  }
                                } else {
                                  Notiflix.Loading.remove();
                                  Notiflix.Notify.failure("Error Occurred");
                                }
                              })
                            );
                          }}
                        >
                          {eventdata.fld_event_name}
                        </h4>
                        <p className="event-location">
                          {eventdata.fld_venue} <br /> {eventdata.fld_location}
                        </p>
                        <div
                          className={`form-field form-radio d-flex ${eventdata.fld_status}`}
                        >
                          <span className={`ms-0 ${eventdata.fld_status}`}>
                            {eventdata.fld_status}
                          </span>
                        </div>
                        <div className="text-start border-top pt-3">
                          <button
                            type="button"
                            className="default-btn"
                            onClick={() => {
                              setModalShow(true);
                              setDescription(eventdata.fld_description);
                              setInnerImageAlt(eventdata.fld_inner_image_alt);
                              setCoverImageAlt(eventdata.fld_cover_image_alt);
                              SetCoverImagePreviewUrl(
                                eventdata.fld_cover_image
                              );
                              SetInnerImagePreviewUrl(
                                eventdata.fld_inner_image
                              );
                              setEventName(eventdata.fld_event_name);
                              setStatus(eventdata.fld_status);
                              setLocation(eventdata.fld_location);
                              setVenue(eventdata.fld_venue);
                              setStartDate(
                                moment(eventdata.fld_start_date).format(
                                  "YYYY-MM-DD"
                                )
                              );
                              setEndDate(
                                moment(eventdata.fld_end_date).format(
                                  "YYYY-MM-DD"
                                )
                              );
                              setEventsId(eventdata.fld_id);
                            }}
                          >
                            Edit
                          </button>
                          <button
                            type="button"
                            className="default-btn"
                            onClick={() => {
                              confirmAlert({
                                title: "Confirm to Delete",
                                message:
                                  "Are you sure you want to delete the event?",

                                buttons: [
                                  {
                                    label: "Yes",
                                    onClick: () => {
                                      Notiflix.Loading.dots();
                                      PostApiCall.postRequest(
                                        {
                                          id: eventdata.fld_id,
                                        },
                                        "DeleteEvent"
                                      ).then((results) =>
                                        results.json().then((obj) => {
                                          if (
                                            results.status == 200 ||
                                            results.status == 201
                                          ) {
                                            Notiflix.Loading.remove();
                                            Notiflix.Notify.success(
                                              "Event successfully deleted."
                                            );
                                            window.location.reload();
                                          } else {
                                            Notiflix.Loading.remove();
                                            Notiflix.Notify.failure(
                                              "Something went wrong, try again later."
                                            );
                                          }
                                        })
                                      );
                                    },
                                  },
                                  {
                                    label: "No",
                                  },
                                ],
                                overlayClassName: "overlay-custom-class-name",
                              });
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-90w"
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 px-0">
            <div className="form-container">
              <h3 class="main-text fw-bold">Add a New Event</h3>
              <form className="w-100">
                <div className="row w-100">
                  <div className="col-12 inner-image mb-3">
                    <label>Upload Inner Page Image</label>
                    <span className="ms-5">
                      Image Size: Width: 1170px Height: 500px
                    </span>
                    <span className="ms-5">
                      Image Format: .jpg, .jpeg, .png, .webp
                    </span>
                    <InnerImgUpload
                      onChange={innerphotoUpload}
                      src={innerimagePreviewUrl}
                    />
                  </div>
                  <div className="col-12 pe-0 mb-3 ps-3">
                    <label className="me-3">Inner Image Alt text / Title</label>
                    <input
                      className="w-100"
                      type="text"
                      value={innerimagealt}
                      onChange={(e) => {
                        setInnerImageAlt(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-md-2">
                    <div className="row">
                      <div className="form-field col-12 me-0">
                        <p>Upload Cover Image</p>
                        <CoverImgUpload
                          onChange={coverphotoUpload}
                          src={coverimagePreviewUrl}
                        />
                        <p className="mt-3">
                          Image Size: <br /> Width: 200px <br /> Height: 250px
                        </p>
                        <p className="">
                          Image Format: <br /> .jpg, .jpeg, .png, .webp
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="row">
                      <div className="form-field col-md-4 me-0">
                        <label>Event Name</label>
                        <input
                          type="text"
                          value={eventname}
                          onChange={(e) => {
                            setEventName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-field col-md-4 me-0">
                        <label>Start Date *</label>
                        <input
                          type="date"
                          value={startdate}
                          onChange={(e) => {
                            setStartDate(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-field col-md-4 me-0">
                        <label>End Date</label>
                        <input
                          type="date"
                          value={enddate}
                          onChange={(e) => {
                            setEndDate(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-field col-md-6 me-0">
                        <label>Venue</label>
                        <input
                          type="text"
                          value={venue}
                          onChange={(e) => {
                            setVenue(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-field col-md-6 me-0">
                        <label>Location</label>
                        <input
                          type="text"
                          value={location}
                          onChange={(e) => {
                            setLocation(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-field col-12 me-0">
                        <label>Description</label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={description}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setDescription(data);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mb-3 pe-0">
                    <label className="me-3">Cover Image Alt text / Title</label>
                    <input
                      className="w-100"
                      type="text"
                      value={coverimagealt}
                      onChange={(e) => {
                        setCoverImageAlt(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-field col-12 me-0 form-radio">
                    <label>Status</label>
                    <div className="d-flex">
                      <span className="d-flex">
                        <input
                          type="radio"
                          name="status"
                          className="ms-2"
                          checked={status == "Active" ? true : false}
                          onChange={() => {
                            setStatus("Active");
                          }}
                        />
                        Active
                      </span>
                      <span className="d-flex">
                        <input
                          type="radio"
                          name="status"
                          checked={status == "Inactive" ? true : false}
                          onChange={() => {
                            setStatus("Inactive");
                          }}
                        />
                        Inactive
                      </span>
                    </div>
                  </div>
                  <div className="col-12 border-top text-end pt-3">
                    <button
                      type="button"
                      className="default-btn"
                      onClick={onCloseEventsModal}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="default-btn"
                      onClick={onSaveEventsData}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
