import React, { useCallback, useState, useEffect } from "react";
import "./ImageGallery.css";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import ShowImage from "../../components/DropBoxUploader/ShowImage";
import DropBoxUploader from "../../components/DropBoxUploader/DropBoxUploader";
import imageCompression from "browser-image-compression";
import Notiflix from "notiflix";
import { confirmAlert } from "react-confirm-alert";
import Modal from "react-bootstrap/Modal";
import GetApiCall from "../../GETAPI";
import PostApiCall from "../../PostApi";
import { MdClose } from "react-icons/md";
const ImgUpload = ({ onChange, src }) => (
  <label
    htmlFor="photo-upload"
    className="custom-file-upload fas"
    style={{ marginBottom: "-5px" }}
  >
    <div className="img-wrap obimages img-upload">
      <img
        htmlFor="photo-upload"
        src={src}
        style={{ width: "100%", height: "100%", borderRadius: "5%" }}
      />
    </div>
    <input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
  </label>
);

const GalleryImgUpload = ({ onChange, src, id }) => (
  <label
    htmlFor={`galleryphoto-upload ${id}`}
    className="gallery-custom-file-upload fas"
    id={id}
  >
    <div className="img-wrap img-upload">
      <img htmlFor={`galleryphoto-upload ${id}`} src={src} />
    </div>
    <input
      accept="image/*"
      id={`galleryphoto-upload ${id}`}
      type="file"
      onChange={onChange}
    />
  </label>
);

export default function ImageGallery() {
  let navigate = useNavigate();
  const [imagemodalShow, setImageModalShow] = useState(false);
  const ImageApiUrl = "https://cms.nabdelhi.in/nabdapi/UploadGalleryImage";
  const [ImagePreviewUrl, SetImagePreviewUrl] = useState(
    "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png"
  );

  const [modalShow, setModalShow] = useState(false);
  const [OriginalImage, SetOriginalImage] = useState([]);
  const [outputFileName, SetOutputFileName] = useState("");
  const [UploadImage, SetUploadImage] = useState(false);
  const [progress, setProgress] = React.useState(10);
  const [images, SetImages] = useState([]);
  const [allgalleryalbumdata, setAllGalleryAlbumData] = useState([]);
  const [allgalleryimages, setAllGalleryImages] = useState([]);
  const [id, setId] = useState(null);
  const [galleryid, setGalleryId] = useState(null);
  const [albumname, setAlbumName] = useState("");
  const [status, setStatus] = useState("Active");

  const photoUpload = (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    SetImagePreviewUrl(URL.createObjectURL(imageFile));
    SetOriginalImage(imageFile);
    SetOutputFileName(imageFile.name);
    SetUploadImage(true);
  };

  useEffect(() => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetImageGalleryAlbumData").then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          setAllGalleryAlbumData(obj.data);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("No Records Exits");
        }
      })
    );
  }, []);

  const onDrop = useCallback((acceptedFiles) => {
    handleOpen();

    acceptedFiles.map((file, index) => {
      var newrandomnumber = Math.floor(100000 + Math.random() * 900000);
      const reader = new FileReader();

      reader.onload = function (e) {
        SetImages((prevState) => [
          ...prevState,
          {
            id: index,
            src: e.target.result,
            name: file.name,
            file: file,
            randomnumber: newrandomnumber,
            uploadtype: "photogallery",
          },
        ]);
      };

      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  function onOpenImageModal(album_id) {
    setImageModalShow(true);
    PostApiCall.postRequest(
      {
        whereClause: `where fld_album_id=${album_id}`,
      },
      "GetImageGalleryImages"
    ).then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          if (obj1.data.length != 0) {
            setAllGalleryImages(obj1.data);
            Notiflix.Loading.remove();
          } else {
            Notiflix.Notify.failure("No Data Exists");
            setAllGalleryImages([]);
          }
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
  }

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setProgress(10);
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  function onGalleryUploadImage(imageFile, randomnumber) {
    var options = {
      maxSizeMB: 0.05,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    imageCompression(imageFile, options).then(async (compressedFile) => {
      const form = new FormData();
      form.append("file", compressedFile);
      form.append("foldername", "Activities");
      form.append("filename", randomnumber + "-" + imageFile.name);
      let response = await fetch(ImageApiUrl, {
        method: "POST",
        body: form,
      }).then((response) => {
        if (response.status == 200 || response.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Image Uploaded Successfully");
          PostApiCall.postRequest(
            {
              whereClause: `where fld_album_id=${parseInt(
                localStorage.getItem("albumid")
              )}`,
            },
            "GetImageGalleryImages"
          ).then((results1) =>
            results1.json().then((obj1) => {
              if (results1.status == 200 || results1.status == 201) {
                if (obj1.data.length != 0) {
                  setAllGalleryImages(obj1.data);
                  Notiflix.Loading.remove();
                } else {
                  Notiflix.Notify.failure("No Data Exists");
                  setAllGalleryImages([]);
                }
              } else {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure("Error Occurred");
              }
            })
          );
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Image Uploading Failed");
        }
      });
    });
  }

  function onSaveImageGalleryData() {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        id: galleryid,
        album_name: albumname,
        status: status,
        createdon: new Date(),
        updatedon: new Date(),
      },
      "AddImageGalleryAlbum"
    ).then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Image Gallery Album Updated Successfully.");
          GetApiCall.getRequest("GetImageGalleryAlbumData").then((resultdes) =>
            resultdes.json().then((obj) => {
              if (obj.data.length != 0) {
                setAllGalleryAlbumData(obj.data);
                setModalShow(false);
                Notiflix.Loading.remove();
              } else {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure("No Records Exits");
              }
            })
          );
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
  }

  function onCloseEditGalleryModal() {
    setModalShow(false);
    setAlbumName("");
    setStatus("Active");
  }

  function onAddImageGallery() {
    navigate("/addimagegallery");
  }

  return (
    <>
      <Navbar />

      <div className="container mt-5">
        <div className="row">
          <div className="col-12 text-center">
            <div className="main-heading pb-1">
              <h2 className="main-title wow animate__animated animate__fadeInUp">
                Photo Gallery
              </h2>
              <h3 className="main-text wow animate__animated animate__fadeInUp">
                Manage Photo Albums of happenings in NAB
              </h3>
            </div>
          </div>

          <div className="col-12 mt-5">
            <div className="form-container download-main">
              <div className="mb-3 d-flex justify-content-between border-bottom pb-3">
                <h3 class="main-text fw-bold">Albums List</h3>
                <button
                  type="button"
                  className="default-btn"
                  onClick={() => {
                    onAddImageGallery();
                  }}
                >
                  Add New Album
                </button>
              </div>
              <form className="mt-3 row">
                <div className="col-12 mb-3">
                  <div className="row photo-gallery mt-3 ms-3">
                    {allgalleryalbumdata.map((albumdata, i) => {
                      return (
                        <div className="col-md-3 px-2">
                          <div className="video-card">
                            <img
                              id={i}
                              src={albumdata.fld_image}
                              className="img-fluid"
                              onClick={() => {
                                setId(albumdata.fld_id);
                                localStorage.setItem(
                                  "albumid",
                                  albumdata.fld_id
                                );
                                onOpenImageModal(albumdata.fld_id);
                              }}
                              alt={albumdata.fld_image_alt}
                            />
                            <h5 className="form-field mt-2">
                              {albumdata.fld_album_name}
                            </h5>
                            <div className="border-top pt-3 mt-3 text-end d-flex flex-column">
                              <div className="form-field form-radio align-items-start">
                                <span className={albumdata.fld_status}>
                                  {albumdata.fld_status}
                                </span>
                              </div>
                              <div className="d-flex justify-content-between">
                                <button
                                  type="button"
                                  className="default-btn"
                                  onClick={() => {
                                    setModalShow(true);
                                    setGalleryId(albumdata.fld_id);
                                    setAlbumName(albumdata.fld_album_name);
                                    setStatus(albumdata.fld_status);
                                  }}
                                >
                                  Edit
                                </button>
                                <button
                                  type="button"
                                  className="default-btn me-0"
                                  onClick={() => {
                                    confirmAlert({
                                      title: "Confirm to Delete",
                                      message:
                                        "Are you sure you want to delete the album?",

                                      buttons: [
                                        {
                                          label: "Yes",
                                          onClick: () => {
                                            Notiflix.Loading.dots();
                                            PostApiCall.postRequest(
                                              {
                                                id: albumdata.fld_id,
                                              },
                                              "DeleteAlbum"
                                            ).then((results) =>
                                              results.json().then((obj) => {
                                                if (
                                                  results.status == 200 ||
                                                  results.status == 201
                                                ) {
                                                  Notiflix.Loading.remove();
                                                  Notiflix.Notify.success(
                                                    "Album successfully deleted."
                                                  );
                                                  window.location.reload();
                                                } else {
                                                  Notiflix.Loading.remove();
                                                  Notiflix.Notify.failure(
                                                    "Something went wrong, try again later."
                                                  );
                                                }
                                              })
                                            );
                                          },
                                        },
                                        {
                                          label: "No",
                                        },
                                      ],
                                    });
                                  }}
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={imagemodalShow}
        onHide={() => {
          setImageModalShow(false);
          SetImages([]);
        }}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-90w"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mt-3 row">
            <div className="col-12 mb-3">
              <div className="row mt-3 ms-3">
                {allgalleryimages.length == 0 ? (
                  <h3 className="form-field">No images</h3>
                ) : (
                  <div className="row mb-5">
                    <h3 className="form-field">Images</h3>
                    {allgalleryimages.map((imagedata, i) => {
                      return (
                        <div className="col-3 image-main">
                          <GalleryImgUpload
                            id={imagedata.fld_id}
                            onChange={(e) => {
                              e.preventDefault();
                              const imageFile = e.target.files[0];
                              var randomnumber = Math.floor(
                                100000 + Math.random() * 900000
                              );

                              if (imageFile.name != undefined) {
                                PostApiCall.postRequest(
                                  {
                                    id: imagedata.fld_id,
                                    albumid: parseInt(
                                      localStorage.getItem("albumid")
                                    ),
                                    image:
                                      "https://cms.nabdelhi.in/images/Activities/" +
                                      randomnumber +
                                      "-" +
                                      imageFile.name,
                                    imagealt: imagedata.fld_image_alt,
                                    createdon: new Date(),
                                    updatedon: new Date(),
                                  },
                                  "AddGalleryImage"
                                ).then((results1) =>
                                  results1.json().then((obj1) => {
                                    if (
                                      results1.status == 200 ||
                                      results1.status == 201
                                    ) {
                                      Notiflix.Loading.remove();
                                      Notiflix.Notify.success(
                                        "Image Uploading in Process"
                                      );
                                      onGalleryUploadImage(
                                        imageFile,
                                        randomnumber
                                      );
                                    } else {
                                      Notiflix.Loading.remove();
                                      Notiflix.Notify.failure(obj1);
                                    }
                                  })
                                );
                              }
                            }}
                            src={imagedata.fld_image}
                          />
                          <span
                            onClick={() => {
                              confirmAlert({
                                title: "Confirm to Delete",
                                message:
                                  "Are you sure you want to delete the image?",

                                buttons: [
                                  {
                                    label: "Yes",
                                    onClick: () => {
                                      Notiflix.Loading.dots();
                                      PostApiCall.postRequest(
                                        {
                                          id: imagedata.fld_id,
                                        },
                                        "DeleteGalleryImage"
                                      ).then((results) =>
                                        results.json().then((obj) => {
                                          if (
                                            results.status == 200 ||
                                            results.status == 201
                                          ) {
                                            Notiflix.Loading.remove();
                                            Notiflix.Notify.success(
                                              "Image successfully deleted."
                                            );

                                            PostApiCall.postRequest(
                                              {
                                                whereClause: `where fld_album_id=${parseInt(
                                                  localStorage.getItem(
                                                    "albumid"
                                                  )
                                                )}`,
                                              },
                                              "GetImageGalleryImages"
                                            ).then((results1) =>
                                              results1.json().then((obj1) => {
                                                if (
                                                  results1.status == 200 ||
                                                  results1.status == 201
                                                ) {
                                                  if (obj1.data.length != 0) {
                                                    setAllGalleryImages(
                                                      obj1.data
                                                    );
                                                    Notiflix.Loading.remove();
                                                  } else {
                                                    setAllGalleryImages([]);
                                                    Notiflix.Notify.failure(
                                                      "No Data Exists"
                                                    );
                                                  }
                                                } else {
                                                  Notiflix.Loading.remove();
                                                  Notiflix.Notify.failure(
                                                    "Error Occurred"
                                                  );
                                                }
                                              })
                                            );
                                          } else {
                                            Notiflix.Loading.remove();
                                            Notiflix.Notify.failure(
                                              "Something went wrong, try again later."
                                            );
                                          }
                                        })
                                      );
                                    },
                                  },
                                  {
                                    label: "No",
                                  },
                                ],
                                overlayClassName: "overlay-custom-class-name",
                              });
                            }}
                          >
                            <MdClose />
                          </span>
                        </div>
                      );
                    })}
                  </div>
                )}
                <div className="col-12 inner-image mb-3 loader-backdrop mt-5">
                  <h3 className="form-field">Add Image</h3>
                  <DropBoxUploader onDrop={onDrop} />
                  <ShowImage images={images} />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-90w"
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12">
            <div className="form-container">
              <h3 class="main-text fw-bold">Add Gallery Image</h3>
              <form className="row pe-3">
                <div className="col-12 pe-0 mb-3 ps-3">
                  <label className="me-3">Album Name</label>
                  <input
                    className="w-100"
                    type="text"
                    value={albumname}
                    onChange={(e) => {
                      setAlbumName(e.target.value);
                    }}
                  />
                </div>

                <div className="form-field col-12 me-0 form-radio">
                  <label>Status</label>
                  <div className="d-flex">
                    <span className="d-flex">
                      <input
                        type="radio"
                        name="status"
                        className="ms-2"
                        checked={status == "Active" ? true : false}
                        onChange={() => {
                          setStatus("Active");
                        }}
                      />
                      Active
                    </span>
                    <span className="d-flex">
                      <input
                        type="radio"
                        name="status"
                        checked={status == "Inactive" ? true : false}
                        onChange={() => {
                          setStatus("Inactive");
                        }}
                      />
                      Inactive
                    </span>
                  </div>
                </div>
                <div className="col-12 border-top text-end pt-3">
                  <button
                    type="button"
                    className="default-btn d-inline-block mt-3 ms-auto"
                    onClick={onCloseEditGalleryModal}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="default-btn"
                    onClick={onSaveImageGalleryData}
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
}
