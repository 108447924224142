import React, { useState } from "react";
import "./Footer.css";
import moment from "moment";
import Logo from "../../assets/images/nabdelhi-logo.png";
import packageInfo from "../../../package.json";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { HiOutlineMailOpen } from "react-icons/hi";
import {
  AiFillInstagram,
  AiFillYoutube,
  AiOutlineTwitter,
} from "react-icons/ai";
function Footer() {
  return (
    <>
      <footer className="footer-section mt-auto w-100">
        <div className="container pt-5">
          <div className="row border-top copyright-section flex-column-reverse flex-lg-row align-items-center footer-bottom-content  py-4">
            <div className="col-lg-5 col-md-6 text-center text-lg-start">
              Design{" "}
              <a href="http://globaltrendz.com/" target={"_blank"}>
                {" "}
                <b>Global Trendz</b>
              </a>
              <span className="ms-2">v-{packageInfo.version}</span>
            </div>

            <div className="col-lg-7 ps-lg-5 col-md-6 text-center text-lg-end">
              <p className="m-0">
                Copyright &copy; {moment().format("YYYY")}{" "}
                <b> NATIONAL ASSOCIATION FOR THE BLIND, DELHI </b>. All Rights
                Reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
