import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import createDOMPurify from "dompurify";
import Notiflix from "notiflix";
import React, { useEffect, useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Offcanvas from "react-bootstrap/Offcanvas";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";
import GetApiCall from "../../GETAPI";
import PostApiCall from "../../PostApi";
import DragDropList from "../Stories/DragDropList/DragDropList";
import "./Academy.css";
import imageCompression from "browser-image-compression";
import { store } from "../../components/Context/store";

const DOMPurify = createDOMPurify(window);

const CoverImgUpload = ({ onChange, src }) => (
  <label
    htmlFor="inner-academy-cover"
    className="custom-file-upload fas"
    style={{ marginBottom: "-5px" }}
  >
    <div className="img-wrap obimages img-upload">
      <img
        htmlFor="inner-academy-cover"
        src={src}
        style={{ width: "100%", height: "100%", borderRadius: "5%" }}
      />
    </div>
    <input
      className="inner-academy-image-size"
      accept="image/*"
      id="inner-academy-cover"
      type="file"
      onChange={onChange}
    />
  </label>
);

export default function AcademyInnerList() {
  let navigate = useNavigate();
  const [coverimagePreviewUrl, SetCoverImagePreviewUrl] = useState(
    "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png"
  );
  const [modalShow, setModalShow] = useState(false);
  const [academyid, setInnerAcademyId] = useState(null);
  const [shortdescription, setShortDescription] = useState("");
  const [coverimagealt, setCoverImageAlt] = useState("");
  const [title, setTitle] = useState("");
  const [longdescription, setLongDescription] = useState("");
  const [status, setStatus] = useState("Active");
  const [allInnerAcademydata, setInnerAcademyData] = useState([]);
  const [OriginalImage, SetOriginalImage] = useState([]);
  const [outputFileName, SetOutputFileName] = useState("");
  const [imagealt, SetImageAlt] = useState("");
  const ImageApiUrl = "https://cms.nabdelhi.in/nabdapi/UploadGalleryImage";
  const randomnum = 4478;
  const [id, setId] = useState(null);
  const { academyMasterId } = useContext(store);

  useEffect(() => {
    getInnerAcedamyData();
  }, []);
  const getInnerAcedamyData = () => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetInnerAcademyData").then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.data.length !== 0) {
          setInnerAcademyData(obj.data);
          setTitle(obj.data[0].fld_title);
          setLongDescription(obj.data[0].fld_longdescription);
          setInnerAcademyId(obj.data[0].fld_id);
        }
        Notiflix.Loading.remove();
      })
    );
  };

  // image FTP on server
  function onUploadImage(imageFileName, randomnumber) {
    var options = {
      maxSizeMB: 0.05,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    imageCompression(OriginalImage, options).then(async (compressedFile) => {
      const form = new FormData();
      form.append("file", compressedFile);
      form.append("foldername", "Academy");
      form.append("filename", randomnumber + "-" + imageFileName);
      let response = await fetch(ImageApiUrl, {
        method: "POST",
        body: form,
      }).then((response) => {
        if (response.status == 200 || response.status == 201) {
          Notiflix.Loading.remove();
          // Notiflix.Notify.success("Academy Added Successfully.");
        } else {
          Notiflix.Loading.remove();
          // Notiflix.Notify.failure("Image Uploading Failed");
        }
      });
    });
  }

  const handleImageChange = (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    SetCoverImagePreviewUrl(URL.createObjectURL(imageFile));
    SetOriginalImage(imageFile);
    SetOutputFileName(imageFile.name);
    var imagename = imageFile.name.split(".");
    SetImageAlt(imagename[0]);
  };

  function onSaveInnerAcademyData() {
    var imagetype = outputFileName.split(".");
    var imagename = imagealt + "." + imagetype[1];
    const trimmedImageName = imagename.trim().split(/\s+/).join("-");

    Notiflix.Loading.dots();
    let apiPayload = {};
    if (OriginalImage.length != 0) {
      try {
        onUploadImage(trimmedImageName, randomnum);
        apiPayload = {
          academymasterid: academyMasterId,
          id: academyid,
          title: title,
          shortdescription: shortdescription,
          longdescription: longdescription,
          coverimage:
            "https://cms.nabdelhi.in/images/Academy/" +
            randomnum +
            "-" +
            trimmedImageName,
          coverimagealt: coverimagealt,
          status: status,
        };
      } catch (error) {
        Notiflix.Notify.failure("Image upload failed");
        console.log(error);
      }
    } else {
      apiPayload = {
        academymasterid: academyMasterId,
        id: academyid,
        title: title,
        shortdescription: shortdescription,
        longdescription: longdescription,
        coverimage: coverimagePreviewUrl,
        coverimagealt: coverimagealt,
        status: status,
      };
    }

    PostApiCall.postRequest(apiPayload, "AddInnerAcademy").then((results1) =>
      results1.json().then((obj1) => {
        Notiflix.Loading.remove();
        if (results1.status === 200 || results1.status === 201) {
          Notiflix.Notify.success("Inner Academy Updated Successfully");
          setModalShow(false);
          SetOriginalImage([]);
          getInnerAcedamyData();
        } else {
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
  }

  function onAddNewInnerAcademy() {
    navigate("/addacademyinner");
  }

  const handleDeleteInnerAcademy = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete the inner Academy?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            Notiflix.Loading.dots();
            PostApiCall.postRequest({ id }, "DeleteInnerAcademy")
              .then((response) => {
                Notiflix.Loading.remove();
                return response
                  .json()
                  .then((obj) => ({ status: response.status, obj }));
              })
              .then(({ status, obj }) => {
                if (status === 200 || status === 201) {
                  Notiflix.Notify.success(
                    "Inner academy successfully deleted."
                  );
                  // Fetch Update data after delete inner academy without refreshing the page
                  setInnerAcademyData((prevData) =>
                    prevData.filter((item) => item.fld_id !== id)
                  );
                } else {
                  Notiflix.Notify.failure(
                    "Something went wrong, try again later."
                  );
                }
              })
              .catch((error) => {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure("Error occurred while deleting.");
                console.error("Delete error:", error);
              });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  return (
    <>
      {console.log(coverimagePreviewUrl)}
      <div className="container mt-3">
        <div className="row service-section form-container academy-section mt-0 pt-0">
          <div className="mt-4 mb-3 d-flex justify-content-between border-bottom pb-3">
            <h3 className="main-text fw-bold mt-0 mb-3 pb-0">
              Academy Inner Pages
            </h3>
            <button
              type="button"
              className="default-btn"
              onClick={onAddNewInnerAcademy}
            >
              Add New Academy Inner Page
            </button>
          </div>

          {!allInnerAcademydata.length ? (
            <div className="no-data-found w-100 text-center">
              <h4>No data</h4>
            </div>
          ) : (
            allInnerAcademydata.map((innerAcademyData, i) => (
              <div className="col-md-6 col-lg-4" key={innerAcademyData.fld_id}>
                <div className="service-card">
                  <div className="s-card-img">
                    <img
                      src={innerAcademyData.fld_coverimage}
                      alt={innerAcademyData.fld_coverimagealt}
                      className="img-fluid"
                    />
                    <div className="s-content">
                      <h4 className="inneracademytitlesize">
                        {innerAcademyData.fld_title}
                      </h4>
                      <p className="inneracademypehrasize">
                        {innerAcademyData.fld_shortdescription}
                      </p>
                      <div className="col-12 border-top pt-3 text-end d-flex">
                        <div className="form-field form-radio d-flex">
                          <span className={innerAcademyData.fld_status}>
                            {innerAcademyData.fld_status}
                          </span>
                        </div>
                        <div className="ms-auto">
                          <button
                            type="button"
                            className="default-btn ms-auto"
                            onClick={() => {
                              setModalShow(true);
                              setInnerAcademyId(innerAcademyData.fld_id);
                              setTitle(innerAcademyData.fld_title);
                              setShortDescription(
                                innerAcademyData.fld_shortdescription
                              );
                              setLongDescription(
                                innerAcademyData.fld_longdescription
                              );
                              setCoverImageAlt(
                                innerAcademyData.fld_coverimagealt
                              );
                              setStatus(innerAcademyData.fld_status);
                              SetCoverImagePreviewUrl(
                                innerAcademyData.fld_coverimage
                              );
                            }}
                          >
                            Edit
                          </button>
                          <button
                            type="button"
                            className="default-btn"
                            onClick={() =>
                              handleDeleteInnerAcademy(innerAcademyData.fld_id)
                            }
                            // onClick={() => {
                            //   confirmAlert({
                            //     title: "Confirm to Delete",
                            //     message:
                            //       "Are you sure you want to delete the inner Academy?",
                            //     buttons: [
                            //       {
                            //         label: "Yes",
                            //         onClick: () => {
                            //           Notiflix.Loading.dots();
                            //           PostApiCall.postRequest(
                            //             { id: innerAcademyData.fld_id },
                            //             "DeleteInnerAcademy"
                            //           ).then((results) =>
                            //             results.json().then((obj) => {
                            //               Notiflix.Loading.remove();
                            //               if (
                            //                 results.status === 200 ||
                            //                 results.status === 201
                            //               ) {
                            //                 Notiflix.Notify.success(
                            //                   "Inner academy successfully deleted."
                            //                 );
                            //                 getInnerAcedamyData();
                            //                 // window.location.reload();
                            //               } else {
                            //                 Notiflix.Notify.failure(
                            //                   "Something went wrong, try again later."
                            //                 );
                            //               }
                            //             })
                            //           );
                            //         },
                            //       },
                            //       {
                            //         label: "No",
                            //       },
                            //     ],
                            //   });
                            // }}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-90w"
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h3 className="main-text fw-bold">Update Inner Academy</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 px-0">
            <div className="form-container">
              <form className="w-100">
                <div className="row w-100">
                  <div className="col-md-2">
                    <div className="row">
                      <div className="form-field col-12 me-0">
                        <label>Upload Cover Image</label>
                        <CoverImgUpload
                          src={coverimagePreviewUrl}
                          onChange={handleImageChange}
                        />
                        <p className="mt-4">
                          Image Size: <br /> Width: 400px <br /> Height: 250px
                        </p>
                        <p>
                          Image Format: <br /> .jpg, .jpeg, .png, .webp
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-10 pe-0">
                    <div className="row w-100 me-0 pe-0 gx-0">
                      <div className="form-field col-12 me-0">
                        <label>Title (max. 70 characters)</label>
                        <input
                          type="text"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>
                      <div className="form-field col-12 me-0">
                        <label>Short Description (max. 180 characters)</label>
                        <input
                          type="text"
                          value={shortdescription}
                          onChange={(e) => setShortDescription(e.target.value)}
                        />
                      </div>
                      <div className="form-field col-12 me-0">
                        <label>Long Description</label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={longdescription}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setLongDescription(data);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mb-3 pe-0">
                    <label className="me-3">Cover Image Alt text / Title</label>
                    <input
                      className="w-100"
                      type="text"
                      value={coverimagealt}
                      onChange={(e) => setCoverImageAlt(e.target.value)}
                    />
                  </div>

                  <div className="form-field col-12 me-0 form-radio">
                    <label>Status</label>
                    <div className="d-flex">
                      <span className="d-flex">
                        <input
                          type="radio"
                          name="status"
                          className="ms-2"
                          checked={status === "Active"}
                          onChange={() => setStatus("Active")}
                        />
                        Active
                      </span>
                      <span className="d-flex">
                        <input
                          type="radio"
                          name="status"
                          checked={status === "Inactive"}
                          onChange={() => setStatus("Inactive")}
                        />
                        Inactive
                      </span>
                    </div>
                  </div>
                  <div className="col-12 border-top mx-auto text-end pt-3">
                    <button
                      type="button"
                      className="default-btn"
                      onClick={() => setModalShow(false)}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="default-btn"
                      onClick={onSaveInnerAcademyData}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Offcanvas show={false} onHide={() => {}} placement="end">
        <Offcanvas.Header className="border-bottom" closeButton>
          <Offcanvas.Title>Sequentialize</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <DragDropList verticalData={[]} for="Vertical" htmlFor="vertical" />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
