import React, { useEffect, useState } from 'react'
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import { useNavigate } from 'react-router-dom';
import Notiflix from 'notiflix';
import GetApiCall from '../../GETAPI';
import PostApiCall from '../../PostApi';
import { confirmAlert } from 'react-confirm-alert';
import { Offcanvas } from 'react-bootstrap';
import Sequentialize from '../../assets/images/Sequentialize.png'
import DragDropBanner from './DragDropBanner';
const HomeBannerList = () => {
	let navigate = useNavigate();
	const [bannerList, setBannerList] = useState([]);
	const [show, setShow] = useState(false);
	const [canvasData, setCanvasData] = useState(null);
	const [alldownloadsdata, SetAllDownloadsData] = useState([]);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);


	useEffect(() => {
		localStorage.removeItem("BannerDetails")
		fetchBanner()
	}, []);

	const fetchBanner = () => {
		Notiflix.Loading.dots()
		GetApiCall.getRequest("GetBannerList").then((resultdes) =>
			resultdes.json().then((obj) => {
				if (obj.data.length != 0) {
					const sequenceData = obj.data.filter((item) => item.status === "Active").sort((a, b) => a.sequence - b.sequence)
					setBannerList(sequenceData);
					setCanvasData(sequenceData);
					SetAllDownloadsData(sequenceData);

					Notiflix.Loading.remove();
				} else {
					Notiflix.Loading.remove();
					Notiflix.Notify.failure("No Records Exits");
				}
			})
		);

	}
	function onAddNewBanner() {
		navigate("/addhomebanner");
	}
	return (
		<>
			<Navbar />
			<div className="container mt-5">
				<div className="row">
					<div className="col-12 text-center">
						<div className="main-heading pb-1">
							<h2 className="main-title wow animate__animated animate__fadeInUp">
								Manage Banners
							</h2>
							<h3 className="main-text wow animate__animated animate__fadeInUp">
								Add and Manage Banners
							</h3>
						</div>
					</div>

					<div className="col-12">
						<div className="row service-section form-container">
							<div className="mb-3 d-flex justify-content-between border-bottom pb-3">
								<h3 class="main-text fw-bold">Banner List</h3>
								<button
									type="button"
									className="default-btn ms-auto"
									data-bs-toggle="offcanvas"
									data-bs-target="#offcanvasRight"
									aria-controls="offcanvasRight"
									onClick={() => {
										handleShow();
									}}
								>
									<img
										alt="sequential-img"
										src={Sequentialize}
										className="sequentialize-btn"
									/>
								</button>
								<button
									type="button"
									className="default-btn"
									onClick={() => {
										onAddNewBanner();
									}}
								>
									Add New Banner
								</button>
							</div>
							{bannerList?.map((bannerdata, i) => {
								let bannerDetail = bannerdata?.desktop
								if (bannerDetail !== null) {
									const bannerInfo = JSON.parse(bannerDetail)

									return (
										<div className="col-lg-4 px-3">
											<div className="project-card">
												<img
													className="img-fluid w-100"
													src={bannerInfo[0]?.FLD_Banner_URL}
													alt={bannerInfo[0]?.FLD_Alt}
												// alt={bannerdata.fld_cover_image_alt}
												></img>
												<div className="project-content p-3">
													<h5
													>
														{bannerdata.heading}
													</h5>
													<p>
														{bannerdata.pagename}
													</p>
													<div className="col-12 border-top pt-3 text-end d-flex">
														<div className="form-field form-radio d-flex">
															{/* <span className={bannerdata.fld_status}>
															{bannerdata.fld_status}
														</span> */}
														</div>
														<div className="ms-auto">
															<button
																type="button"
																className="default-btn ms-auto"

																onClick={() => {
																	localStorage.setItem("BannerDetails", JSON.stringify(bannerdata)); // to edit 
																	window.location.href = "/addhomebanner";
																}}
															>
																Edit
															</button>
															<button
																type="button"
																className="default-btn"
																onClick={() => {
																	confirmAlert({
																		title: "Confirm to Delete",
																		message:
																			"Are you sure you want to delete the project?",

																		buttons: [
																			{
																				label: "Yes",
																				onClick: () => {
																					Notiflix.Loading.dots();
																					PostApiCall.postRequest(
																						{
																							bannerid: bannerdata.id,
																							bannerlocation: "Main",
																							bannerstatus: "Inactive",
																							pagename: "Home",
																							bannerurl: bannerInfo[0]?.FLD_Banner_URL,
																							title: null,
																							slug: bannerdata.slug,
																							images: [{
																								imageid: bannerInfo[0]?.FLD_Image_Id,
																								bannerid: bannerdata.id,
																								status: "Inactive",
																								url: bannerInfo[0]?.FLD_Banner_URL,
																								width: null,
																								height: null,
																								bannerfor: "Desktop",
																								alt: bannerInfo[0]?.FLD_Alt,
																							}],
																							heading: bannerdata.heading
																						},
																						"AddBanner"
																					).then((resultcategory) =>
																						resultcategory.json().then((obj) => {
																							if (resultcategory.status == 200 || resultcategory.status == 201) {
																								Notiflix.Notify.success("Banner Deleted");
																								// window.location.href = '/banner-list'
																								Notiflix.Loading.remove();
																								fetchBanner()
																							} else {
																								Notiflix.Loading.remove();
																								Notiflix.Notify.failure("Banner Upload Failed")
																							}
																						})
																					);
																				},
																			},
																			{
																				label: "No",
																			},
																		],
																		overlayClassName: "overlay-custom-class-name",
																	});
																}}
															>
																Delete
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									);
								}
							})}
						</div>
					</div>
				</div>
			</div>
			<Offcanvas show={show} onHide={handleClose} placement="end">
				<Offcanvas.Header className="border-bottom" closeButton>
					<Offcanvas.Title>Sequentialize</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<DragDropBanner
						onClose={handleClose}
						getBannerList={fetchBanner}
						verticalData={canvasData === null ? alldownloadsdata : canvasData}
						// verticalData={
						//   fillActive == "tab1"
						//     ? allActiveOfficeBearers
						//     : allActiveGoverningCouncil
						// }
						for="Vertical"
						htmlFor="verticle"
					/>
				</Offcanvas.Body>
			</Offcanvas>
			<Footer />
		</>
	)
}

export default HomeBannerList