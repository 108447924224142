import React, { useEffect, useCallback } from "react";
import "./Projects.css";
import { useState } from "react";
import pimg from "../../assets/images/projects.png";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import Notiflix from "notiflix";
import GetApiCall from "../../GETAPI";
import PostApiCall from "../../PostApi";
import DropBoxUploader from "../../components/DropBoxUploader/DropBoxUploader";
import ShowImage from "../../components/DropBoxUploader/ShowImage";
import imageCompression from "browser-image-compression";
import { confirmAlert } from "react-confirm-alert";
import { MdClose } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import createDOMPurify from "dompurify";
const DOMPurify = createDOMPurify(window);

const ImgUpload = ({ onChange, src }) => (
  <label
    htmlFor="photo-upload"
    className="custom-file-upload fas"
    style={{ marginBottom: "-5px" }}
  >
    <div className="img-wrap obimages img-upload">
      <img
        htmlFor="photo-upload"
        src={src}
        style={{ width: "100%", height: "100%", borderRadius: "5%" }}
      />
    </div>
    <input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
  </label>
);

const GalleryImgUpload = ({ onChange, src, id, imagealt }) => (
  <label
    htmlFor={`galleryphoto-upload ${id}`}
    className="gallery-custom-file-upload fas"
    id={id}
  >
    <div className="img-wrap img-upload">
      <img htmlFor={`galleryphoto-upload ${id}`} src={src} alt={imagealt} />
    </div>
    <input
      accept="image/*"
      id={`galleryphoto-upload ${id}`}
      type="file"
      onChange={onChange}
    />
  </label>
);

export default function Projects() {
  let navigate = useNavigate();
  const ImageApiUrl = "https://cms.nabdelhi.in/nabdapi/UploadGalleryImage";
  const [ImagePreviewUrl, SetImagePreviewUrl] = useState(
    "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png"
  );
  const [modalShow, setModalShow] = useState(false);
  const [OriginalImage, SetOriginalImage] = useState([]);
  const [outputFileName, SetOutputFileName] = useState("");
  const [UploadImage, SetUploadImage] = useState(false);
  const [images, SetImages] = useState([]);
  const [projectsid, setProjectsId] = useState(null);
  const [allprojectsdata, setAllProjectsData] = useState([]);
  const [currprojectname, setCurrProjectName] = useState("");
  const [currlongdescription, setCurrLongDescription] = useState("");
  const [allprojectsgalleryimages, setAllProjectsGalleryImages] = useState([]);
  const [projectname, setProjectName] = useState("");
  const [coverimagealt, setCoverImageAlt] = useState("");
  const [shortdescription, setShortDescription] = useState("");
  const [longdescription, setLongDescription] = useState([]);
  const [status, setStatus] = useState("Active");
  const [imageListHide, setImageListHide] = useState(false);
  const [projectCategory, setProjectCategory] = useState("");

  const photoUpload = (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    SetImagePreviewUrl(URL.createObjectURL(imageFile));
    SetOriginalImage(imageFile);
    SetOutputFileName(imageFile.name);
    var imagename = imageFile.name.split(".");
    setCoverImageAlt(imagename[0]);
    SetUploadImage(true);
  };

  useEffect(() => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetProjectsData").then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          setAllProjectsData(obj.data);
          setCurrProjectName(obj.data[0].fld_project_name);
          setCurrLongDescription(obj.data[0].fld_long_description);
          localStorage.setItem("projectsid", obj.data[0].fld_id);
          PostApiCall.postRequest(
            {
              whereClause: `where fld_projects_id=${obj.data[0].fld_id}`,
            },
            "GetProjectsGalleryImages"
          ).then((results1) =>
            results1.json().then((obj1) => {
              if (results1.status == 200 || results1.status == 201) {
                if (obj1.data.length != 0) {
                  setAllProjectsGalleryImages(obj1.data);
                  SetImages([]);
                  Notiflix.Loading.remove();
                } else {
                  setAllProjectsGalleryImages([]);
                  Notiflix.Loading.remove();
                }
              } else {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure("Error Occurred");
              }
            })
          );
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("No Records Exits");
          setAllProjectsData([]);
        }
      })
    );
  }, []);

  let onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file, index) => {
      var newrandomnumber = Math.floor(100000 + Math.random() * 900000);
      const reader = new FileReader();

      reader.onload = function (e) {
        SetImages((prevState) => [
          ...prevState,
          {
            id: index,
            src: e.target.result,
            name: file.name,
            file: file,
            randomnumber: newrandomnumber,
            uploadtype: "projects",
          },
        ]);
      };

      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  function onProjectsGalleryUploadImage(imageFile, randomnumber) {
    var options = {
      maxSizeMB: 0.05,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    imageCompression(imageFile, options).then(async (compressedFile) => {
      const form = new FormData();
      form.append("file", compressedFile);
      form.append("foldername", "Activities");
      form.append("filename", randomnumber + "-" + imageFile.name);
      let response = await fetch(ImageApiUrl, {
        method: "POST",
        body: form,
      }).then((response) => {
        if (response.status == 200 || response.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Image Uploaded Successfully");
          PostApiCall.postRequest(
            {
              whereClause: `where fld_projects_id=${parseInt(
                localStorage.getItem("projectsid")
              )}`,
            },
            "GetProjectsGalleryImages"
          ).then((results1) =>
            results1.json().then((obj1) => {
              if (results1.status == 200 || results1.status == 201) {
                if (obj1.data.length != 0) {
                  setAllProjectsGalleryImages(obj1.data);
                  Notiflix.Loading.remove();
                } else {
                  setAllProjectsGalleryImages([]);
                  Notiflix.Loading.remove();
                }
              } else {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure("Error Occurred");
              }
            })
          );
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Image Uploading Failed");
        }
      });
    });
  }

  function onUploadProjectsMasterImage(imageFileName, randomnumber, imagetype) {
    var options = {
      maxSizeMB: 0.05,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    imageCompression(OriginalImage, options).then(async (compressedFile) => {
      const form = new FormData();
      form.append("file", compressedFile);
      form.append("foldername", "Activities");
      form.append("filename", randomnumber + "-" + imageFileName);
      let response = await fetch(ImageApiUrl, {
        method: "POST",
        body: form,
      }).then((response) => {
        if (response.status == 200 || response.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Projects Added Successfully.");
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Image Uploading Failed");
        }
      });
    });
  }

  function onSaveProjectsData() {
    var randomnumber = Math.floor(100000 + Math.random() * 900000);
    Notiflix.Loading.dots();
    var imagetype = outputFileName.split(".");
    var imagename = coverimagealt + "." + imagetype[1];
    var projectsobj = {};

    if (OriginalImage.length != 0) {
      projectsobj = {
        id: projectsid,
        projectname: projectname,
        shortdescription: shortdescription,
        projectcategory: projectCategory,
        longdescription: longdescription,
        coverimage:
          "https://cms.nabdelhi.in/images/Activities/" +
          randomnumber +
          "-" +
          imagename,
        coverimagealt: coverimagealt,
        status: status,
        updatedon: new Date(),
        createdon: new Date(),
      };
    } else {
      projectsobj = {
        id: projectsid,
        projectname: projectname,
        shortdescription: shortdescription,
        projectcategory: projectCategory,
        longdescription: longdescription,
        coverimage: ImagePreviewUrl,
        coverimagealt: coverimagealt,
        status: status,
        updatedon: new Date(),
        createdon: new Date(),
      };
    }

    PostApiCall.postRequest(projectsobj, "AddProject").then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          if (OriginalImage.length != 0) {
            onUploadProjectsMasterImage(imagename, randomnumber);
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.success("Projects Updated Successfully");
          }
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
  }

  function onCloseServicesModal() {
    window.location.reload();
  }

  function onAddNewProjects() {
    navigate("/addprojects");
  }
  return (
    <>
      <Navbar />

      <div className="container mt-5">
        <div className="row">
          <div className="col-12 text-center">
            <div className="main-heading pb-1">
              <h2 className="main-title wow animate__animated animate__fadeInUp">
                Manage Projects
              </h2>
              <h3 className="main-text wow animate__animated animate__fadeInUp">
                Add and Manage Projects undertaken by NAB
              </h3>
            </div>
          </div>

          <div className="col-12">
            <div className="row service-section form-container">
              <div className="mb-3 d-flex justify-content-between border-bottom pb-3">
                <h3 class="main-text fw-bold">Projects List</h3>
                <button
                  type="button"
                  className="default-btn"
                  onClick={() => {
                    onAddNewProjects();
                  }}
                >
                  Add New Project
                </button>
              </div>
              <div
                className="col-12"
                style={{
                  display: allprojectsdata.length == 0 ? "none" : "block",
                }}
              >
                <h3 class="main-text fw-bold">Projects Inner Page List</h3>

                <div className="service-card service-card-inner">
                  <div className="s-card-img">
                    <div className="s-content">
                      <h4>{currprojectname}</h4>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(currlongdescription),
                        }}
                      />
                    </div>
                    <div className="mt-3 row">
                      <div className="col-12 mb-3">
                        <div className="row mt-3 ms-3">
                          {allprojectsgalleryimages.length == 0 ? (
                            <h3 className="form-field">No images</h3>
                          ) : (
                            <div className="row mb-5">
                              <h3 className="form-field">Images</h3>
                              {allprojectsgalleryimages.map((imagedata, i) => {
                                return (
                                  <div className="col-3 image-main image-main-2">
                                    <GalleryImgUpload
                                      id={imagedata.fld_id}
                                      onChange={(e) => {
                                        e.preventDefault();
                                        const imageFile = e.target.files[0];
                                        var randomnumber = Math.floor(
                                          100000 + Math.random() * 900000
                                        );

                                        if (imageFile.name != undefined) {
                                          PostApiCall.postRequest(
                                            {
                                              id: imagedata.fld_id,
                                              projectsid: parseInt(
                                                localStorage.getItem(
                                                  "projectsid"
                                                )
                                              ),
                                              image:
                                                "https://cms.nabdelhi.in/images/Activities/" +
                                                randomnumber +
                                                "-" +
                                                imageFile.name,
                                              imagealt: imagedata.fld_image_alt,
                                              createdon: new Date(),
                                              updatedon: new Date(),
                                            },
                                            "AddProjectsGalleryImage"
                                          ).then((results1) =>
                                            results1.json().then((obj1) => {
                                              if (
                                                results1.status == 200 ||
                                                results1.status == 201
                                              ) {
                                                Notiflix.Loading.remove();
                                                Notiflix.Notify.success(
                                                  "Image Uploading in Process"
                                                );
                                                onProjectsGalleryUploadImage(
                                                  imageFile,
                                                  randomnumber
                                                );
                                              } else {
                                                Notiflix.Loading.remove();
                                                Notiflix.Notify.failure(obj1);
                                              }
                                            })
                                          );
                                        }
                                      }}
                                      src={imagedata.fld_image}
                                      imagealt={imagedata.fld_image_alt}
                                    />
                                    <span
                                      onClick={() => {
                                        confirmAlert({
                                          title: "Confirm to Delete",
                                          message:
                                            "Are you sure you want to delete the image?",

                                          buttons: [
                                            {
                                              label: "Yes",
                                              onClick: () => {
                                                Notiflix.Loading.dots();
                                                PostApiCall.postRequest(
                                                  {
                                                    id: imagedata.fld_id,
                                                  },
                                                  "DeleteProjectsGalleryImage"
                                                ).then((results) =>
                                                  results.json().then((obj) => {
                                                    if (
                                                      results.status == 200 ||
                                                      results.status == 201
                                                    ) {
                                                      Notiflix.Loading.remove();
                                                      Notiflix.Notify.success(
                                                        "Image successfully deleted."
                                                      );

                                                      PostApiCall.postRequest(
                                                        {
                                                          whereClause: `where fld_projects_id=${parseInt(
                                                            localStorage.getItem(
                                                              "projectsid"
                                                            )
                                                          )}`,
                                                        },
                                                        "GetProjectsGalleryImages"
                                                      ).then((results1) =>
                                                        results1
                                                          .json()
                                                          .then((obj1) => {
                                                            if (
                                                              results1.status ==
                                                                200 ||
                                                              results1.status ==
                                                                201
                                                            ) {
                                                              if (
                                                                obj1.data
                                                                  .length != 0
                                                              ) {
                                                                setAllProjectsGalleryImages(
                                                                  obj1.data
                                                                );
                                                                Notiflix.Loading.remove();
                                                              } else {
                                                                setAllProjectsGalleryImages(
                                                                  []
                                                                );
                                                                Notiflix.Notify.failure(
                                                                  "No Data Exists"
                                                                );
                                                              }
                                                            } else {
                                                              Notiflix.Loading.remove();
                                                              Notiflix.Notify.failure(
                                                                "Error Occurred"
                                                              );
                                                            }
                                                          })
                                                      );
                                                    } else {
                                                      Notiflix.Loading.remove();
                                                      Notiflix.Notify.failure(
                                                        "Something went wrong, try again later."
                                                      );
                                                    }
                                                  })
                                                );
                                              },
                                            },
                                            {
                                              label: "No",
                                            },
                                          ],
                                          overlayClassName:
                                            "overlay-custom-class-name",
                                        });
                                      }}
                                    >
                                      <MdClose />
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                          <div className="col-12 inner-image mb-3 loader-backdrop mt-5">
                            <h3 className="form-field">Add Image</h3>
                            <DropBoxUploader
                              onDrop={onDrop}
                              imageListHide={imageListHide}
                            />
                            <ShowImage images={images} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {allprojectsdata.map((projectdata, i) => {
                return (
                  <div className="col-lg-4 px-3">
                    <div className="project-card">
                      <img
                        src={projectdata.fld_cover_image}
                        className="img-fluid w-100"
                        alt={projectdata.fld_cover_image_alt}
                      ></img>
                      <div className="project-content p-3">
                        <h5
                          onClick={() => {
                            setCurrProjectName(projectdata.fld_title);
                            setCurrLongDescription(
                              projectdata.fld_long_description
                            );
                            SetImages([]);
                            setImageListHide(true);
                            localStorage.setItem(
                              "projectsid",
                              projectdata.fld_id
                            );
                            Notiflix.Loading.dots();
                            PostApiCall.postRequest(
                              {
                                whereClause: `where fld_projects_id=${projectdata.fld_id}`,
                              },
                              "GetProjectsGalleryImages"
                            ).then((results1) =>
                              results1.json().then((obj1) => {
                                if (
                                  results1.status == 200 ||
                                  results1.status == 201
                                ) {
                                  if (obj1.data.length != 0) {
                                    setAllProjectsGalleryImages(obj1.data);
                                    Notiflix.Loading.remove();
                                  } else {
                                    Notiflix.Loading.remove();
                                    setAllProjectsGalleryImages([]);
                                  }
                                } else {
                                  Notiflix.Loading.remove();
                                  Notiflix.Notify.failure("Error Occurred");
                                }
                              })
                            );
                          }}
                        >
                          {projectdata.fld_project_name}
                        </h5>
                        <p>
                          {projectdata.fld_short_description}
                        </p>
                        <span className="fw-bold">
                          {projectdata.fld_project_category}
                        </span>
                        <div className="col-12 border-top pt-3 text-end d-flex">
                          <div className="form-field form-radio d-flex">
                            <span className={projectdata.fld_status}>
                              {projectdata.fld_status}
                            </span>
                          </div>
                          <div className="ms-auto">
                            <button
                              type="button"
                              className="default-btn ms-auto"
                              onClick={() => {
                                setModalShow(true);
                                setShortDescription(
                                  projectdata.fld_short_description
                                );
                                setLongDescription(
                                  projectdata.fld_long_description
                                );

                                setCoverImageAlt(
                                  projectdata.fld_cover_image_alt
                                );
                                SetImagePreviewUrl(projectdata.fld_cover_image);

                                setProjectName(projectdata.fld_project_name);
                                setStatus(projectdata.fld_status);
                                setProjectsId(projectdata.fld_id);
                                setProjectCategory(
                                  projectdata.fld_project_category
                                );
                              }}
                            >
                              Edit
                            </button>
                            <button
                              type="button"
                              className="default-btn"
                              onClick={() => {
                                confirmAlert({
                                  title: "Confirm to Delete",
                                  message:
                                    "Are you sure you want to delete the project?",

                                  buttons: [
                                    {
                                      label: "Yes",
                                      onClick: () => {
                                        Notiflix.Loading.dots();
                                        PostApiCall.postRequest(
                                          {
                                            id: projectdata.fld_id,
                                          },
                                          "DeleteProject"
                                        ).then((results) =>
                                          results.json().then((obj) => {
                                            if (
                                              results.status == 200 ||
                                              results.status == 201
                                            ) {
                                              Notiflix.Loading.remove();
                                              Notiflix.Notify.success(
                                                "Projects successfully deleted."
                                              );
                                              window.location.reload();
                                            } else {
                                              Notiflix.Loading.remove();
                                              Notiflix.Notify.failure(
                                                "Something went wrong, try again later."
                                              );
                                            }
                                          })
                                        );
                                      },
                                    },
                                    {
                                      label: "No",
                                    },
                                  ],
                                  overlayClassName: "overlay-custom-class-name",
                                });
                              }}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-90w"
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 px-0">
            <div className="form-container">
              <h3 class="main-text fw-bold">Update Project</h3>
              <form className="w-100">
                <div className="row w-100">
                  <div className="col-md-2">
                    <div className="row">
                      <div className="form-field col-12 me-0">
                        <p>Upload Cover Image</p>
                        <ImgUpload
                          onChange={photoUpload}
                          src={ImagePreviewUrl}
                        />
                        <p className="mt-3">
                          Image Size: <br /> Width: 400px <br /> Height: 280px
                        </p>
                        <p className="">
                          Image Format: <br /> .jpg, .jpeg, .png, .webp
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-10 pe-0">
                    <div className="row gx-0 pe-0">
                      <div className="d-flex justify-content-between">
                        <div className="form-field col-5 pe-0 me-0">
                          <label>Project Name</label>
                          <input
                            type="text"
                            value={projectname}
                            onChange={(e) => {
                              setProjectName(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-field col-md-6 me-0 ms-3">
                          <label>Document Category</label>
                          <select
                            class="w-100"
                            value={projectCategory}
                            onChange={(e) => {
                              setProjectCategory(e.target.value);
                            }}
                          >
                            <option value="Select Document Category">
                              Select Projects Category
                            </option>
                            <option value="Ongoing Projects">
                              Ongoing Projects
                            </option>
                            <option value="Completed Projects">
                              Completed Projects
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="form-field col-12 pe-0 me-0">
                        <label>Short Description (max. 140 characters)</label>
                        <input
                          type="text"
                          value={shortdescription}
                          onChange={(e) => {
                            setShortDescription(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-field col-12 pe-0 me-0">
                        <label>Long Description</label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={longdescription}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setLongDescription(data);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 pe-0">
                    <label className="me-3">Cover Image Alt text / Title</label>
                    <input
                      className="w-100"
                      type="text"
                      value={coverimagealt}
                      onChange={(e) => {
                        setCoverImageAlt(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-field col-12 me-0 form-radio">
                    <label>Status</label>
                    <div className="d-flex">
                      <span className="d-flex">
                        <input
                          type="radio"
                          name="status"
                          className="ms-2"
                          checked={status == "Active" ? true : false}
                          onChange={() => {
                            setStatus("Active");
                          }}
                        />
                        Active
                      </span>
                      <span className="d-flex">
                        <input
                          type="radio"
                          name="status"
                          checked={status == "Inactive" ? true : false}
                          onChange={() => {
                            setStatus("Inactive");
                          }}
                        />
                        Inactive
                      </span>
                    </div>
                  </div>
                  <div className="col-12 border-top text-end pt-3">
                    <button
                      type="button"
                      className="default-btn"
                      onClick={onCloseServicesModal}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="default-btn"
                      onClick={onSaveProjectsData}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
