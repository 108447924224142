import React from "react";
import "./Members.css";
import Notiflix from "notiflix";
import PostApiCall from "../../PostApi";
import { useState } from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import imageCompression from "browser-image-compression";

const ImgUpload = ({ onChange, src }) => (
  <label
    htmlFor="photo-upload"
    className="custom-file-upload fas"
    style={{ marginBottom: "-5px" }}
  >
    <div className="img-wrap obimages img-upload">
      <img
        htmlFor="photo-upload"
        src={src}
        style={{ width: "100%", height: "100%", borderRadius: "5%" }}
      />
    </div>
    <input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
  </label>
);

export default function AddMembers() {
  const [ImagePreviewUrl, SetImagePreviewUrl] = useState(
    "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png"
  );
  const ImageApiUrl = "https://cms.nabdelhi.in/nabdapi/UploadGalleryImage";
  const [OriginalImage, SetOriginalImage] = useState([]);
  const [outputFileName, SetOutputFileName] = useState("");
  const [UploadImage, SetUploadImage] = useState(false);
  const [memberid, SetMemberId] = useState(null);
  const [membertype, SetMemberType] = useState("Office Bearers");
  const [membername, SetmemberName] = useState("");
  const [memberdesignation, SetMemberDesignation] = useState("");
  const [memberimagealt, SetMemberImageAlt] = useState("");
  const [status, SetStatus] = useState("Active");

  const photoUpload = (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    SetImagePreviewUrl(URL.createObjectURL(imageFile));
    SetOriginalImage(imageFile);
    SetOutputFileName(imageFile.name);
    var imagename = imageFile.name.split(".");
    SetMemberImageAlt(imagename[0]);
    SetUploadImage(true);
  };

  function onUploadImage(imageFileName, randomnumber) {
    var options = {
      maxSizeMB: 0.05,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    imageCompression(OriginalImage, options).then(async (compressedFile) => {
      const form = new FormData();
      form.append("file", compressedFile);
      form.append("foldername", "Activities");
      form.append("filename", randomnumber + "-" + imageFileName);
      let response = await fetch(ImageApiUrl, {
        method: "POST",
        body: form,
      }).then((response) => {
        if (response.status == 200 || response.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Member Added Successfully.");
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Image Uploading Failed");
        }
      });
    });
  }

  function onSaveMemberData() {
    var randomnumber = Math.floor(100000 + Math.random() * 900000);
    Notiflix.Loading.dots();
    var imagetype = outputFileName.split(".");
    var imagename = memberimagealt + "." + imagetype[1];
    var memberobj = {};
    if (membertype == "Office Bearers") {
      if (OriginalImage.length != 0) {
        memberobj = {
          memberid: memberid,
          membertype: membertype,
          membername: membername,
          memberimage:
            "https://cms.nabdelhi.in/images/Activities/" +
            randomnumber +
            "-" +
            imagename,
          memberdesignation: memberdesignation,
          memberimagealt: memberimagealt,
          status: status,
        };
      } else {
        memberobj = {
          memberid: memberid,
          membertype: membertype,
          membername: membername,
          memberimage:
            "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930",
          memberdesignation: memberdesignation,
          memberimagealt: "no-image",
          status: status,
        };
      }
    } else if (membertype == "Governing Council") {
      memberobj = {
        memberid: memberid,
        membertype: membertype,
        membername: membername,
        memberimage: "",
        memberdesignation: memberdesignation,
        memberimagealt: "",
        status: status,
      };
    }
    PostApiCall.postRequest(memberobj, "AddMember").then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          if (OriginalImage.length != 0) {
            onUploadImage(imagename, randomnumber);
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.success("Member Added Successfully.");
          }
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
  }

  return (
    <>
      <Navbar />

      <div className="container mt-5">
        <div className="row">
          <div className="col-12 text-center">
            <div className="main-heading pb-1">
              <h2 className="main-title wow animate__animated animate__fadeInUp">
                Manage Members
              </h2>
              <h3 className="main-text wow animate__animated animate__fadeInUp">
                This module helps you in Managing the Office Bearers and
                Governing Council
              </h3>
            </div>
          </div>

          <div className="col-12">
            <div className="form-container">
              <h3 class="main-text fw-bold">Add New Member</h3>
              <form className="">
                <div className="row">
                  <div
                    className="form-field col-md-2 me-0"
                    style={{
                      display:
                        membertype == "Office Bearers" ? "block" : "none",
                    }}
                  >
                    <p>Upload Image</p>
                    <ImgUpload onChange={photoUpload} src={ImagePreviewUrl} />
                    <p className="mt-4">
                      Image Size: <br /> Width: 200px <br /> Height: 200px
                    </p>
                    <p className="">
                      Image Format: <br /> .jpg, .jpeg, .png, .webp
                    </p>
                  </div>
                  <div className="col-md-10">
                    <div className="row">
                      <div className="form-field col-md-6 me-0 form-radio">
                        <label>Choose Members Type</label>
                        <div className="d-flex">
                          <span className="d-flex">
                            <input
                              type="radio"
                              name="member"
                              className="ms-2"
                              checked={
                                membertype == "Office Bearers" ? true : false
                              }
                              onChange={() => {
                                SetMemberType("Office Bearers");
                              }}
                            />
                            Office Bearers
                          </span>
                          <span className="d-flex">
                            <input
                              type="radio"
                              name="member"
                              checked={
                                membertype == "Governing Council" ? true : false
                              }
                              onChange={() => {
                                SetMemberType("Governing Council");
                              }}
                            />
                            Governing Council
                          </span>
                        </div>
                      </div>
                      <div className="form-field col-md-12 me-0">
                        <label>Name</label>
                        <input
                          type="text"
                          value={membername}
                          onChange={(e) => {
                            SetmemberName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-field col-md-12 me-0">
                        <label>Designation</label>
                        <input
                          type="text"
                          value={memberdesignation}
                          onChange={(e) => {
                            SetMemberDesignation(e.target.value);
                          }}
                        />
                      </div>
                      <div
                        className="col-12 mb-3"
                        style={{
                          display:
                            membertype == "Office Bearers" ? "block" : "none",
                        }}
                      >
                        <label className="me-3">Image Alt text / Title</label>
                        <input
                          className="w-100"
                          type="text"
                          value={memberimagealt}
                          onChange={(e) => {
                            SetMemberImageAlt(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-field col-md-6 me-0 form-radio">
                        <label>Status</label>
                        <div className="d-flex">
                          <span className="d-flex">
                            <input
                              type="radio"
                              name="status"
                              className="ms-2"
                              checked={status == "Active" ? true : false}
                              onChange={() => {
                                SetStatus("Active");
                              }}
                            />
                            Active
                          </span>
                          <span className="d-flex">
                            <input
                              type="radio"
                              name="status"
                              checked={status == "Inactive" ? true : false}
                              onChange={() => {
                                SetStatus("Inactive");
                              }}
                            />
                            Inactive
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 border-top text-end pt-3">
                    <button
                      type="button"
                      className="default-btn"
                      onClick={onSaveMemberData}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
