import React, { useEffect, useState } from "react";
import { Col, NavDropdown, Offcanvas, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import {
  AiFillInstagram,
  AiFillYoutube,
  AiOutlineTwitter,
} from "react-icons/ai";
import "./Navbar.css";
import Logo from "../../assets/images/nabdelhi-logo.png";
import { SlEnvolope } from "react-icons/sl";
function SiteNavbar() {
  const [scroll, setScroll] = useState(false);
  const [scrollSticky, setScrollSticky] = useState(false);
  useEffect(() => {
    if (window.pageYOffset > 100) {
      setScroll("scroll");
    } else {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 100);
      });
    }
    if (window.pageYOffset > 100) {
      setScroll("scrollSticky");
    } else {
      window.addEventListener("scrollSticky", () => {
        setScroll(window.scrollY > 100);
      });
    }
  }, []);

  return (
    <>
      <div
        className={
          scroll ? "sticky-top shadow-sm nav-container" : "nav-container"
        }
      >
        <Navbar expand="lg">
          <Container className="px-lg-3 justify-content-lg-start ms-0">
            <Navbar.Brand
              href="#home"
              className="mx-auto mx-lg-0"
              aria-label="NAB DELHI LOGO"
            >
              <img src={Logo} className="logo" alt="nab delhi"></img>
            </Navbar.Brand>
            <h1 className="ms-2 mt-2">
              National Association for the Blind Delhi
            </h1>
          </Container>
        </Navbar>
      </div>
    </>
  );
}

export default SiteNavbar;
