import React, { useState } from "react";
import Notiflix from "notiflix";
import PostApiCall from "../../PostApi";
import imageCompression from "browser-image-compression";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Modal from "react-bootstrap/Modal";

function LinearProgressWithLabel(props) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        marginTop: "auto",
        marginBottom: "auto",
      }}
    >
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

function Image({ image }) {
  const [imagealt, setImageAlt] = useState(image.name.split(".")[0]);
  const [imagetype, SetImageType] = useState(image.name.split(".")[1]);
  const ImageApiUrl = "https://cms.nabdelhi.in/nabdapi/UploadGalleryImage";
  const [progress, setProgress] = React.useState(10);
  const [open, setOpen] = React.useState(false);

  function onUploadImage(imagename) {
    var options = {
      maxSizeMB: 0.05,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    handleOpen();
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? "" : prevProgress + 10
      );
    }, 400);

    imageCompression(image.file, options).then(async (compressedFile) => {
      const form = new FormData();
      form.append("file", compressedFile);
      form.append("foldername", "Activities");
      form.append(
        "filename",
        image.randomnumber + "-" + imagename + "." + imagetype
      );
      let response = await fetch(ImageApiUrl, {
        method: "POST",
        body: form,
      }).then((response) => {
        if (response.status == 200 || response.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Image Uploaded Successfully");
          clearInterval(timer);
          handleClose();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Image Uploading Failed");
        }
      });
    });
  }

  const handleClose = () => {
    setProgress(10);
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <div className="d-flex flex-column gap-3 me-3 drop-image">
        <img alt="" src={image.src} className="w-100 h-100" />
        <input
          type="text"
          className="image-alt"
          value={imagealt}
          onChange={(e) => {
            setImageAlt(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              Notiflix.Loading.dots();
              if (image.uploadtype == "photogallery") {
                PostApiCall.postRequest(
                  {
                    id: null,
                    albumid: parseInt(localStorage.getItem("albumid")),
                    image:
                      "https://cms.nabdelhi.in/images/Activities/" +
                      image.randomnumber +
                      "-" +
                      e.target.value +
                      "." +
                      imagetype,
                    imagealt: e.target.value,
                    createdon: new Date(),
                    updatedon: new Date(),
                  },
                  "AddGalleryImage"
                ).then((results1) =>
                  results1.json().then((obj1) => {
                    if (results1.status == 200 || results1.status == 201) {
                      onUploadImage(e.target.value);
                      Notiflix.Loading.remove();
                      // Notiflix.Notify.success("Image Added Successfully.");
                    } else {
                      Notiflix.Loading.remove();
                      Notiflix.Notify.failure("Error Occurred");
                    }
                  })
                );
              } else if (image.uploadtype == "services") {
                PostApiCall.postRequest(
                  {
                    id: null,
                    servicesid: parseInt(localStorage.getItem("servicesid")),
                    image:
                      "https://cms.nabdelhi.in/images/Activities/" +
                      image.randomnumber +
                      "-" +
                      e.target.value +
                      "." +
                      imagetype,
                    imagealt: e.target.value,
                    createdon: new Date(),
                    updatedon: new Date(),
                  },
                  "AddServicesGalleryImage"
                ).then((results1) =>
                  results1.json().then((obj1) => {
                    if (results1.status == 200 || results1.status == 201) {
                      onUploadImage(e.target.value);
                      Notiflix.Loading.remove();
                      // Notiflix.Notify.success("Image Added Successfully.");
                    } else {
                      Notiflix.Loading.remove();
                      Notiflix.Notify.failure("Error Occurred");
                    }
                  })
                );
              } else if (image.uploadtype == "events") {
                PostApiCall.postRequest(
                  {
                    id: null,
                    eventid: parseInt(localStorage.getItem("eventsid")),
                    image:
                      "https://cms.nabdelhi.in/images/Activities/" +
                      image.randomnumber +
                      "-" +
                      e.target.value +
                      "." +
                      imagetype,
                    imagealt: e.target.value,
                    createdon: new Date(),
                    updatedon: new Date(),
                  },
                  "AddEventsGalleryImage"
                ).then((results1) =>
                  results1.json().then((obj1) => {
                    if (results1.status == 200 || results1.status == 201) {
                      onUploadImage(e.target.value);
                      Notiflix.Loading.remove();
                    } else {
                      Notiflix.Loading.remove();
                      Notiflix.Notify.failure("Error Occurred");
                    }
                  })
                );
              } else if (image.uploadtype == "projects") {
                PostApiCall.postRequest(
                  {
                    id: null,
                    projectsid: parseInt(localStorage.getItem("projectsid")),
                    image:
                      "https://cms.nabdelhi.in/images/Activities/" +
                      image.randomnumber +
                      "-" +
                      e.target.value +
                      "." +
                      imagetype,
                    imagealt: e.target.value,
                    createdon: new Date(),
                    updatedon: new Date(),
                  },
                  "AddProjectsGalleryImage"
                ).then((results1) =>
                  results1.json().then((obj1) => {
                    if (results1.status == 200 || results1.status == 201) {
                      onUploadImage(e.target.value);
                      Notiflix.Loading.remove();
                    } else {
                      Notiflix.Loading.remove();
                      Notiflix.Notify.failure("Error Occurred");
                    }
                  })
                );
              }
            }
          }}
        />
      </div>

      <Modal
        show={open}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-90w"
        backdrop="static"
      >
        <Modal.Body>
          <Box sx={{ width: "100%" }}>
            <LinearProgressWithLabel value={progress} />
          </Box>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Image;
