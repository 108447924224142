import React from "react";
import "./Services.css";
import { useState } from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Notiflix from "notiflix";
import PostApiCall from "../../PostApi";
import imageCompression from "browser-image-compression";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const CoverImgUpload = ({ onChange, src }) => (
  <label
    htmlFor="cover-photo-upload"
    className="custom-file-upload fas"
    style={{ marginBottom: "-5px" }}
  >
    <div className="img-wrap obimages img-upload">
      <img
        htmlFor="cover-photo-upload"
        src={src}
        style={{ width: "100%", height: "100%", borderRadius: "5%" }}
      />
    </div>
    <input
      accept="image/*"
      id="cover-photo-upload"
      type="file"
      onChange={onChange}
    />
  </label>
);

const InnerImgUpload = ({ onChange, src }) => (
  <label
    htmlFor="inner-photo-upload"
    className="custom-file-upload fas"
    style={{ marginBottom: "-5px" }}
  >
    <div className="img-wrap obimages img-upload">
      <img
        htmlFor="inner-photo-upload"
        src={src}
        style={{ width: "100%", height: "100%", borderRadius: "5%" }}
      />
    </div>
    <input
      accept="image/*"
      id="inner-photo-upload"
      type="file"
      onChange={onChange}
    />
  </label>
);
export default function AddServices() {
  const ImageApiUrl = "https://cms.nabdelhi.in/nabdapi/UploadGalleryImage";
  const [coverimagePreviewUrl, SetCoverImagePreviewUrl] = useState(
    "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png"
  );
  const [innerimagePreviewUrl, SetInnerImagePreviewUrl] = useState(
    "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png"
  );
  const [inneroriginalImage, SetInnerOriginalImage] = useState([]);
  const [inneroutputFileName, SetInnerOutputFileName] = useState("");
  const [inneruploadImage, SetInnerUploadImage] = useState(false);
  const [coveroriginalImage, SetCoverOriginalImage] = useState([]);
  const [coveroutputFileName, SetCoverOutputFileName] = useState("");
  const [coveruploadImage, SetCoverUploadImage] = useState(false);
  const [id, setId] = useState(null);
  const [innerimagealt, setInnerImageAlt] = useState("");
  const [title, setTitle] = useState("");
  const [shortdescription, setShortDescription] = useState("");
  const [longdescription, setLongDescription] = useState("");
  const [coverimagealt, setCoverImageAlt] = useState("");
  const [status, setStatus] = useState("Active");

  const coverphotoUpload = (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    SetCoverImagePreviewUrl(URL.createObjectURL(imageFile));
    SetCoverOriginalImage(imageFile);
    SetCoverOutputFileName(imageFile.name);
    var imagename = imageFile.name.split(".");
    setCoverImageAlt(imagename[0]);
    SetCoverUploadImage(true);
  };

  const innerphotoUpload = (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    SetInnerImagePreviewUrl(URL.createObjectURL(imageFile));
    SetInnerOriginalImage(imageFile);
    SetInnerOutputFileName(imageFile.name);
    var imagename = imageFile.name.split(".");
    setInnerImageAlt(imagename[0]);
    SetInnerUploadImage(true);
  };

  function onUploadImage(imageFileName, randomnumber, imagetype) {
    var options = {
      maxSizeMB: 0.05,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    if (imagetype == "innerimage") {
      imageCompression(inneroriginalImage, options).then(
        async (compressedFile) => {
          const form = new FormData();
          form.append("file", compressedFile);
          form.append("foldername", "Activities");
          form.append("filename", randomnumber + "-" + imageFileName);
          let response = await fetch(ImageApiUrl, {
            method: "POST",
            body: form,
          }).then((response) => {
            if (response.status == 200 || response.status == 201) {
              Notiflix.Loading.remove();
              Notiflix.Notify.success("Services Added Successfully.");
            } else {
              Notiflix.Loading.remove();
              Notiflix.Notify.failure("Image Uploading Failed");
            }
          });
        }
      );
    } else {
      imageCompression(coveroriginalImage, options).then(
        async (compressedFile) => {
          const form = new FormData();
          form.append("file", compressedFile);
          form.append("foldername", "Activities");
          form.append("filename", randomnumber + "-" + imageFileName);
          let response = await fetch(ImageApiUrl, {
            method: "POST",
            body: form,
          }).then((response) => {
            if (response.status == 200 || response.status == 201) {
              Notiflix.Loading.remove();
              Notiflix.Notify.success("Services Added Successfully.");
            } else {
              Notiflix.Loading.remove();
              Notiflix.Notify.failure("Image Uploading Failed");
            }
          });
        }
      );
    }
  }

  function onSaveServicesData() {
    var randomnumber = Math.floor(100000 + Math.random() * 900000);
    Notiflix.Loading.dots();
    var coverimagetype = coveroutputFileName.split(".");
    var coverimagename = coverimagealt + "." + coverimagetype[1];

    var innerimagetype = inneroutputFileName.split(".");
    var innerimagename = innerimagealt + "." + innerimagetype[1];
    PostApiCall.postRequest(
      {
        id: id,
        innerimage:
          "https://cms.nabdelhi.in/images/Activities/" +
          randomnumber +
          "-" +
          innerimagename,
        innerimagealt: innerimagealt,
        title: title,
        shortdescription: shortdescription,
        longdescription: longdescription,
        coverimage:
          "https://cms.nabdelhi.in/images/Activities/" +
          randomnumber +
          "-" +
          coverimagename,
        coverimagealt: coverimagealt,
        status: status,
        updatedon: new Date(),
        createdon: new Date(),
      },
      "AddServices"
    ).then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          onUploadImage(innerimagename, randomnumber, "innerimage");
          onUploadImage(coverimagename, randomnumber, "coverimage");
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
  }
  return (
    <>
      <Navbar />

      <div className="container mt-5">
        <div className="row">
          <div className="col-12 text-center">
            <div className="main-heading pb-1">
              <h2 className="main-title wow animate__animated animate__fadeInUp">
                Manage Services
              </h2>
              <h3 className="main-text wow animate__animated animate__fadeInUp">
                You can manage the content for the Services Offered
              </h3>
            </div>
          </div>

          <div className="col-12 px-0">
            <div className="form-container">
              <h3 class="main-text fw-bold">Add New Service</h3>
              <form className="w-100">
                <div className="row w-100">
                  <div className="col-12 inner-image mb-3">
                    <label>Upload Inner Page Image</label>
                    <span className="ms-5">
                      Image Size: Width: 1170px Height: 500px
                    </span>
                    <span className="ms-5">
                      Image Format: .jpg, .jpeg, .png, .webp
                    </span>
                    <InnerImgUpload
                      onChange={innerphotoUpload}
                      src={innerimagePreviewUrl}
                    />
                  </div>
                  <div className="col-12 pe-0 mb-3 ps-3">
                    <label className="me-3">Inner Image Alt text / Title</label>
                    <input
                      className="w-100"
                      type="text"
                      value={innerimagealt}
                      onChange={(e) => {
                        setInnerImageAlt(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-md-2">
                    <div className="row">
                      <div className="form-field col-12 me-0">
                        <label>Upload Cover Image</label>
                        <CoverImgUpload
                          onChange={coverphotoUpload}
                          src={coverimagePreviewUrl}
                        />

                        <p className="mt-4">
                          Image Size: <br /> Width: 400px <br /> Height: 250px
                        </p>
                        <p className="">
                          Image Format: <br /> .jpg, .jpeg, .png, .webp
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-10 pe-0">
                    <div className="row w-100 me-0 pe-0 gx-0">
                      <div className="form-field col-12 me-0">
                        <label>Title (max. 70 characters)</label>
                        <input
                          type="text"
                          value={title}
                          onChange={(e) => {
                            setTitle(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-field col-12 me-0">
                        <label>Short Description (max. 180 characters)</label>
                        <input
                          type="text"
                          value={shortdescription}
                          onChange={(e) => {
                            setShortDescription(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-field col-12 me-0">
                        <label>Long Description</label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={longdescription}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setLongDescription(data);
                          }}
                        />
                        {/* <textarea
                          cols={1}
                          rows={5}
                          value={longdescription}
                          onChange={(e) => {
                            setLongDescription(e.target.value);
                          }}
                        /> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mb-3 pe-0">
                    <label className="me-3">Cover Image Alt text / Title</label>
                    <input
                      className="w-100"
                      type="text"
                      value={coverimagealt}
                      onChange={(e) => {
                        setCoverImageAlt(e.target.value);
                      }}
                    />
                  </div>

                  <div className="form-field col-12 me-0 form-radio">
                    <label>Status</label>
                    <div className="d-flex">
                      <span className="d-flex">
                        <input
                          type="radio"
                          name="status"
                          className="ms-2"
                          checked={status == "Active" ? true : false}
                          onChange={() => {
                            setStatus("Active");
                          }}
                        />
                        Active
                      </span>
                      <span className="d-flex">
                        <input
                          type="radio"
                          name="status"
                          checked={status == "Inactive" ? true : false}
                          onChange={() => {
                            setStatus("Inactive");
                          }}
                        />
                        Inactive
                      </span>
                    </div>
                  </div>
                  <div className="col-12 border-top mx-auto text-end pt-3">
                    <button
                      type="button"
                      className="default-btn"
                      onClick={onSaveServicesData}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
