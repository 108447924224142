import React, { useEffect, useState } from "react";
import "./Members.css";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Notiflix from "notiflix";
import GetApiCall from "../../GETAPI";
import PostApiCall from "../../PostApi";
import Modal from "react-bootstrap/Modal";
import imageCompression from "browser-image-compression";
import Sequentialize from "../../assets/images/Sequentialize.png";
import Offcanvas from "react-bootstrap/Offcanvas";
import DragDropList from "./DragDropList/DragDropList";
const ImgUpload = ({ onChange, src }) => (
  <label
    htmlFor="photo-upload"
    className="custom-file-upload fas"
    style={{ marginBottom: "-5px" }}
  >
    <div className="img-wrap obimages img-upload">
      <img
        htmlFor="photo-upload"
        src={src}
        style={{ width: "100%", height: "100%", borderRadius: "5%" }}
      />
    </div>
    <input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
  </label>
);

export default function Members() {
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const ImageApiUrl = "https://cms.nabdelhi.in/nabdapi/UploadGalleryImage";
  const [ImagePreviewUrl, SetImagePreviewUrl] = useState(
    "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png"
  );
  const [OriginalImage, SetOriginalImage] = useState([]);
  const [outputFileName, SetOutputFileName] = useState("");
  const [UploadImage, SetUploadImage] = useState(false);
  const [allmemberdata, SetAllMemberData] = useState([]);
  const [allofficebearersmemberdata, SetAllOfficeBearersMemberData] = useState(
    []
  );
  const [allgoverningcouncilmemberdata, SetAllGoverningCouncilMemberData] =
    useState([]);
  const [memberid, SetMemberId] = useState(null);
  const [membertype, SetMemberType] = useState("Office Bearers");
  const [membername, SetmemberName] = useState("");
  const [memberdesignation, SetMemberDesignation] = useState("");
  const [memberimagealt, SetMemberImageAlt] = useState("");
  const [status, SetStatus] = useState("Active");
  const [allActiveGoverningCouncil, setAllActiveGoverningCouncil] = useState(
    []
  );
  const [allActiveOfficeBearers, setAllActiveOfficeBearers] = useState([]);

  const photoUpload = (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    SetImagePreviewUrl(URL.createObjectURL(imageFile));
    SetOriginalImage(imageFile);
    SetOutputFileName(imageFile.name);
    var imagename = imageFile.name.split(".");
    SetMemberImageAlt(imagename[0]);
    SetUploadImage(true);
  };

  const [fillActive, setFillActive] = useState("tab1");

  const handleFillClick = (value) => {
    if (value === fillActive) {
      return;
    }

    setFillActive(value);
  };

  useEffect(() => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetMemberData").then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          SetAllMemberData(obj.data);
          var officebearersdata = [];
          var allactiveofficebearersdata = [];
          var governingcouncildata = [];
          var allactivegoverningcouncildata = [];
          obj.data.map((data, i) => {
            if (data.fld_member_type == "Office Bearers") {
              if (data.fld_status == "Active") {
                allactiveofficebearersdata.push(data);
              }
              officebearersdata.push(data);
            } else {
              if (data.fld_status) {
                allactivegoverningcouncildata.push(data);
              }
              governingcouncildata.push(data);
            }
          });
          SetAllOfficeBearersMemberData(officebearersdata);
          setAllActiveOfficeBearers(allactiveofficebearersdata);
          SetAllGoverningCouncilMemberData(governingcouncildata);
          setAllActiveGoverningCouncil(allactivegoverningcouncildata);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("No Records Exits");
        }
      })
    );
  }, []);

  function onUploadMemmber(imageFileName, randomnumber) {
    var options = {
      maxSizeMB: 0.05,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    imageCompression(OriginalImage, options).then(async (compressedFile) => {
      const form = new FormData();
      form.append("file", compressedFile);
      form.append("foldername", "Activities");
      form.append("filename", randomnumber + "-" + imageFileName);
      let response = await fetch(ImageApiUrl, {
        method: "POST",
        body: form,
      }).then((response) => {
        if (response.status == 200 || response.status == 201) {
          GetApiCall.getRequest("GetMemberData").then((resultdes) =>
            resultdes.json().then((obj) => {
              if (obj.data.length != 0) {
                SetAllMemberData(obj.data);
                var officebearersdata = [];
                var governingcouncildata = [];
                obj.data.map((data, i) => {
                  if (data.fld_member_type == "Office Bearers") {
                    officebearersdata.push(data);
                  } else {
                    governingcouncildata.push(data);
                  }
                  SetAllOfficeBearersMemberData(officebearersdata);
                  SetAllGoverningCouncilMemberData(governingcouncildata);
                });
                setModalShow(false);
                SetMemberId(null);
                SetMemberType("Office Bearers");
                SetmemberName("");
                SetMemberDesignation("");
                SetMemberImageAlt("");
                SetStatus("Active");
                SetImagePreviewUrl(
                  "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png"
                );
                Notiflix.Loading.remove();
                Notiflix.Notify.success("Member Updated Successfully.");
              } else {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure("No Records Exits");
              }
            })
          );
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Image Uploading Failed");
        }
      });
    });
    OriginalImage([]);
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function onSaveMemberData() {
    var randomnumber = Math.floor(100000 + Math.random() * 900000);
    Notiflix.Loading.dots();
    var imagetype = outputFileName.split(".");
    var imagename = memberimagealt + "." + imagetype[1];
    var memberobj = {};

    if (OriginalImage.length != 0) {
      memberobj = {
        memberid: memberid,
        membertype: membertype,
        membername: membername,
        memberimage:
          "https://cms.nabdelhi.in/images/Activities/" +
          randomnumber +
          "-" +
          imagename,
        memberdesignation: memberdesignation,
        memberimagealt: memberimagealt,
        status: status,
      };
    } else {
      memberobj = {
        memberid: memberid,
        membertype: membertype,
        membername: membername,
        memberimage: ImagePreviewUrl,
        memberdesignation: memberdesignation,
        memberimagealt: memberimagealt,
        status: status,
      };
    }
    PostApiCall.postRequest(memberobj, "AddMember").then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          if (OriginalImage.length != 0) {
            onUploadMemmber(imagename, randomnumber);
          } else {
            GetApiCall.getRequest("GetMemberData").then((resultdes) =>
              resultdes.json().then((obj) => {
                if (obj.data.length != 0) {
                  SetAllMemberData(obj.data);
                  var officebearersdata = [];
                  var governingcouncildata = [];
                  obj.data.map((data, i) => {
                    if (data.fld_member_type == "Office Bearers") {
                      officebearersdata.push(data);
                    } else {
                      governingcouncildata.push(data);
                    }
                    SetAllOfficeBearersMemberData(officebearersdata);
                    SetAllGoverningCouncilMemberData(governingcouncildata);
                  });
                  setModalShow(false);
                  SetMemberId(null);
                  SetMemberType("Office Bearers");
                  SetmemberName("");
                  SetMemberDesignation("");
                  SetMemberImageAlt("");
                  SetStatus("Active");
                  SetImagePreviewUrl(
                    "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png"
                  );
                  Notiflix.Loading.remove();
                  Notiflix.Notify.success("Member Updated Successfully.");
                } else {
                  Notiflix.Loading.remove();
                  Notiflix.Notify.failure("No Records Exits");
                }
              })
            );
          }
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
  }

  function onCloseEditMemberModal() {
    setModalShow(false);
    SetMemberId(null);
    SetMemberType("Office Bearers");
    SetmemberName("");
    SetMemberDesignation("");
    SetMemberImageAlt("");
    SetStatus("Active");
    SetImagePreviewUrl(
      "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png"
    );
  }

  function onAddNewMember() {
    navigate("/addnewmember");
  }
  return (
    <>
      <Navbar />

      <div className="container mt-5">
        <div className="row">
          <div className="col-12 text-center">
            <div className="main-heading pb-1">
              <h2 className="main-title wow animate__animated animate__fadeInUp">
                Manage Members
              </h2>
              <h3 className="main-text wow animate__animated animate__fadeInUp">
                This module helps you in Managing the Office Bearers and
                Governing Council
              </h3>
            </div>
          </div>
          <div className="col-12 mt-5">
            <div className="div-container">
              <div className="mb-3 d-flex justify-content-between border-bottom pb-3">
                <h3 class="main-text fw-bold">All Members Records</h3>
                <button
                  type="button"
                  className="default-btn ms-auto"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                  onClick={() => {
                    handleShow();
                  }}
                >
                  <img
                    alt="sequential-img"
                    src={Sequentialize}
                    className="sequentialize-btn"
                  />
                </button>
                <button
                  type="button"
                  className="default-btn"
                  onClick={() => {
                    onAddNewMember();
                  }}
                >
                  Add New Member
                </button>
              </div>
              <div className="mt-3 flex-column">
                <MDBTabs fill className="mb-3">
                  <MDBTabsItem>
                    <MDBTabsLink
                      onClick={() => handleFillClick("tab1")}
                      active={fillActive === "tab1"}
                    >
                      Office Bearers
                    </MDBTabsLink>
                  </MDBTabsItem>
                  <MDBTabsItem>
                    <MDBTabsLink
                      onClick={() => handleFillClick("tab2")}
                      active={fillActive === "tab2"}
                    >
                      Governing Council
                    </MDBTabsLink>
                  </MDBTabsItem>
                </MDBTabs>

                <MDBTabsContent className="mt-4">
                  <MDBTabsPane show={fillActive === "tab1"}>
                    <div className="row">
                      {allofficebearersmemberdata.map((memberdata, i) => {
                        return (
                          <div className="col-md-4 px-3 mb-4">
                            <div className="members-card members-card-main">
                              <div className="div-field w-100">
                                <img
                                  src={memberdata.fld_image}
                                  alt={memberdata.fld_image_alt}
                                  className="img-fluid"
                                />
                              </div>
                              <div className="col-md-10">
                                <div className="row">
                                  <div className="div-field col-12 flex-row me-0">
                                    <span>{memberdata.fld_name}</span>
                                  </div>
                                  <div className="div-field col-12 flex-row me-0">
                                    <span>{memberdata.fld_designation}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 border-top pt-3 text-end d-flex">
                                <div className="div-field div-radio d-flex">
                                  <span className={memberdata.fld_status}>
                                    {memberdata.fld_status}
                                  </span>
                                </div>
                                <div className="ms-auto">
                                  <button
                                    type="button"
                                    className="default-btn ms-auto"
                                    onClick={() => {
                                      setModalShow(true);
                                      SetMemberId(memberdata.fld_id);
                                      SetMemberType(memberdata.fld_member_type);
                                      SetmemberName(memberdata.fld_name);
                                      SetMemberDesignation(
                                        memberdata.fld_designation
                                      );
                                      SetMemberImageAlt(
                                        memberdata.fld_image_alt
                                      );
                                      SetStatus(memberdata.fld_status);
                                      SetImagePreviewUrl(memberdata.fld_image);
                                    }}
                                  >
                                    Edit
                                  </button>
                                  <button
                                    type="button"
                                    className="default-btn"
                                    onClick={() => {
                                      confirmAlert({
                                        title: "Confirm to Delete",
                                        message:
                                          "Are you sure you want to delete the Member?",
                                        buttons: [
                                          {
                                            label: "Yes",
                                            onClick: () => {
                                              PostApiCall.postRequest(
                                                {
                                                  id: memberdata.fld_id,
                                                },
                                                "DeleteMember"
                                              ).then((results) =>
                                                results.json().then((obj) => {
                                                  if (
                                                    results.status == 200 ||
                                                    results.status == 201
                                                  ) {
                                                    Notiflix.Loading.remove();
                                                    Notiflix.Notify.success(
                                                      "Contact person information successfully deleted."
                                                    );
                                                    window.location.reload();
                                                  } else {
                                                    Notiflix.Loading.remove();
                                                    Notiflix.Notify.failure(
                                                      "Something went wrong, try again later."
                                                    );
                                                  }
                                                })
                                              );
                                            },
                                          },
                                          {
                                            label: "No",
                                          },
                                        ],
                                      });
                                    }}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </MDBTabsPane>
                  <MDBTabsPane show={fillActive === "tab2"}>
                    <div className="row">
                      {allgoverningcouncilmemberdata.map((memberdata, i) => {
                        return (
                          <div className="col-md-4 px-3 mb-4">
                            <div className="members-card members-card-main">
                              <div className="col-md-10">
                                <div className="row">
                                  <div className="div-field col-12 flex-row me-0">
                                    <span>{memberdata.fld_name}</span>
                                  </div>
                                  <div className="div-field col-12 flex-row me-0">
                                    <span>{memberdata.fld_designation}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 border-top pt-3 text-end d-flex">
                                <div className="div-field div-radio d-flex">
                                  <span className={memberdata.fld_status}>
                                    {memberdata.fld_status}
                                  </span>
                                </div>
                                <div className="ms-auto">
                                  <button
                                    type="button"
                                    className="default-btn ms-auto"
                                    onClick={() => {
                                      setModalShow(true);
                                      SetMemberId(memberdata.fld_id);
                                      SetMemberType(memberdata.fld_member_type);
                                      SetmemberName(memberdata.fld_name);
                                      SetMemberDesignation(
                                        memberdata.fld_designation
                                      );
                                      SetMemberImageAlt(
                                        memberdata.fld_image_alt
                                      );
                                      SetStatus(memberdata.fld_status);
                                      SetImagePreviewUrl(memberdata.fld_image);
                                    }}
                                  >
                                    Edit
                                  </button>
                                  <button
                                    type="button"
                                    className="default-btn"
                                    onClick={() => {
                                      confirmAlert({
                                        title: "Confirm to Delete",
                                        message:
                                          "Are you sure you want to delete the Member?",
                                        buttons: [
                                          {
                                            label: "Yes",
                                            onClick: () => {
                                              PostApiCall.postRequest(
                                                {
                                                  id: memberdata.fld_id,
                                                },
                                                "DeleteMember"
                                              ).then((results) =>
                                                results.json().then((obj) => {
                                                  if (
                                                    results.status == 200 ||
                                                    results.status == 201
                                                  ) {
                                                    Notiflix.Loading.remove();
                                                    Notiflix.Notify.success(
                                                      "Member successfully deleted."
                                                    );
                                                    window.location.reload();
                                                  } else {
                                                    Notiflix.Loading.remove();
                                                    Notiflix.Notify.failure(
                                                      "Something went wrong, try again later."
                                                    );
                                                  }
                                                })
                                              );
                                            },
                                          },
                                          {
                                            label: "No",
                                          },
                                        ],
                                      });
                                    }}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </MDBTabsPane>
                </MDBTabsContent>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-90w"
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12">
            <div className="form-container">
              <h3 class="main-text fw-bold">Add New Member</h3>
              <form className="">
                <div className="row">
                  <div
                    className="form-field col-md-2 me-0"
                    style={{
                      display:
                        membertype == "Office Bearers" ? "block" : "none",
                    }}
                  >
                    <p>Upload Image</p>
                    <ImgUpload onChange={photoUpload} src={ImagePreviewUrl} />
                    <p className="mt-4">
                      Image Size: <br /> Width: 200px <br /> Height: 200px
                    </p>
                    <p className="">
                      Image Format: <br /> .jpg, .jpeg, .png, .webp
                    </p>
                  </div>
                  <div className="col-md-10">
                    <div className="row">
                      <div className="form-field col-md-6 me-0 form-radio">
                        <label>Choose Members Type</label>
                        <div className="d-flex">
                          <span className="d-flex">
                            <input
                              type="radio"
                              name="member"
                              className="ms-2"
                              checked={
                                membertype == "Office Bearers" ? true : false
                              }
                              onChange={() => {
                                SetMemberType("Office Bearers");
                              }}
                            />
                            Office Bearers
                          </span>
                          <span className="d-flex">
                            <input
                              type="radio"
                              name="member"
                              checked={
                                membertype == "Governing Council" ? true : false
                              }
                              onChange={() => {
                                SetMemberType("Governing Council");
                              }}
                            />
                            Governing Council
                          </span>
                        </div>
                      </div>
                      <div className="form-field col-md-12 me-0">
                        <label>Name</label>
                        <input
                          type="text"
                          value={membername}
                          onChange={(e) => {
                            SetmemberName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-field col-md-12 me-0">
                        <label>Designation</label>
                        <input
                          type="text"
                          value={memberdesignation}
                          onChange={(e) => {
                            SetMemberDesignation(e.target.value);
                          }}
                        />
                      </div>
                      <div
                        className="col-12 mb-3"
                        style={{
                          display:
                            membertype == "Office Bearers" ? "block" : "none",
                        }}
                      >
                        <label className="me-3">Image Alt text / Title</label>
                        <input
                          className="w-100"
                          type="text"
                          value={memberimagealt}
                          onChange={(e) => {
                            SetMemberImageAlt(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-field col-md-6 me-0 form-radio">
                        <label>Status</label>
                        <div className="d-flex">
                          <span className="d-flex">
                            <input
                              type="radio"
                              name="status"
                              className="ms-2"
                              checked={status == "Active" ? true : false}
                              onChange={() => {
                                SetStatus("Active");
                              }}
                            />
                            Active
                          </span>
                          <span className="d-flex">
                            <input
                              type="radio"
                              name="status"
                              checked={status == "Inactive" ? true : false}
                              onChange={() => {
                                SetStatus("Inactive");
                              }}
                            />
                            Inactive
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 border-top text-end pt-3">
                    <button
                      type="button"
                      className="default-btn d-inline-block mt-3 ms-auto"
                      onClick={onCloseEditMemberModal}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="default-btn"
                      onClick={onSaveMemberData}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Footer />
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header className="border-bottom" closeButton>
          <Offcanvas.Title>Sequentialize</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <DragDropList
            verticalData={
              fillActive == "tab1"
                ? allActiveOfficeBearers
                : allActiveGoverningCouncil
            }
            for="Vertical"
            htmlFor="verticle"
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
