import React, { useCallback, useEffect } from "react";
import "./Services.css";
import { useState } from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import ShowImage from "../../components/DropBoxUploader/ShowImage";
import DropBoxUploader from "../../components/DropBoxUploader/DropBoxUploader";
import Notiflix from "notiflix";
import GetApiCall from "../../GETAPI";
import PostApiCall from "../../PostApi";
import { MdClose } from "react-icons/md";
import { confirmAlert } from "react-confirm-alert";
import imageCompression from "browser-image-compression";
import Modal from "react-bootstrap/Modal";
import Sequentialize from "../../assets/images/Sequentialize.png";
import Offcanvas from "react-bootstrap/Offcanvas";
import DragDropList from "./DragDropList/DragDropList";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import createDOMPurify from "dompurify";
const DOMPurify = createDOMPurify(window);

const CoverImgUpload = ({ onChange, src }) => (
  <label
    htmlFor="cover-photo-upload"
    className="custom-file-upload fas"
    style={{ marginBottom: "-5px" }}
  >
    <div className="img-wrap obimages img-upload">
      <img
        htmlFor="cover-photo-upload"
        src={src}
        style={{ width: "100%", height: "100%", borderRadius: "5%" }}
      />
    </div>
    <input
      accept="image/*"
      id="cover-photo-upload"
      type="file"
      onChange={onChange}
    />
  </label>
);

const InnerImgUpload = ({ onChange, src }) => (
  <label
    htmlFor="inner-photo-upload"
    className="custom-file-upload fas"
    style={{ marginBottom: "-5px" }}
  >
    <div className="img-wrap obimages img-upload">
      <img
        htmlFor="inner-photo-upload"
        src={src}
        style={{ width: "100%", height: "100%", borderRadius: "5%" }}
      />
    </div>
    <input
      accept="image/*"
      id="inner-photo-upload"
      type="file"
      onChange={onChange}
    />
  </label>
);

const GalleryImgUpload = ({ onChange, src, id, imagealt }) => (
  <label
    htmlFor={`galleryphoto-upload ${id}`}
    className="gallery-custom-file-upload fas"
    id={id}
  >
    <div className="img-wrap img-upload">
      <img htmlFor={`galleryphoto-upload ${id}`} src={src} alt={imagealt} />
    </div>
    <input
      accept="image/*"
      id={`galleryphoto-upload ${id}`}
      type="file"
      onChange={onChange}
    />
  </label>
);

export default function Services() {
  let navigate = useNavigate();
  const [coverimagePreviewUrl, SetCoverImagePreviewUrl] = useState(
    "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png"
  );
  const [innerimagePreviewUrl, SetInnerImagePreviewUrl] = useState(
    "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png"
  );
  const ImageApiUrl = "http://localhost:8013/api/AddImage";
  const [modalShow, setModalShow] = useState(false);
  const [show, setShow] = useState(false);
  const [inneroriginalImage, SetInnerOriginalImage] = useState([]);
  const [inneroutputFileName, SetInnerOutputFileName] = useState("");
  const [inneruploadImage, SetInnerUploadImage] = useState(false);
  const [coveroriginalImage, SetCoverOriginalImage] = useState([]);
  const [coveroutputFileName, SetCoverOutputFileName] = useState("");
  const [coveruploadImage, SetCoverUploadImage] = useState(false);
  const [images, SetImages] = useState([]);
  const [servicesid, setServicesId] = useState(null);
  const [allservicesgalleryimages, setAllServicesGalleryImages] = useState([]);
  const [innerimagealt, setInnerImageAlt] = useState("");
  const [shortdescription, setShortDescription] = useState("");
  const [coverimagealt, setCoverImageAlt] = useState("");
  const [title, setTitle] = useState("");
  const [longdescription, setLongDescription] = useState("");
  const [status, setStatus] = useState("Active");
  const [currinnerimage, setCurrInnerImage] = useState("");
  const [allservicesdata, setAllServicesData] = useState([]);
  const [imageListHide, setImageListHide] = useState(false);
  const [allActiveServices, setAllActiveServices] = useState([]);

  const coverphotoUpload = (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    SetCoverImagePreviewUrl(URL.createObjectURL(imageFile));
    SetCoverOriginalImage(imageFile);
    SetCoverOutputFileName(imageFile.name);
    var imagename = imageFile.name.split(".");
    setCoverImageAlt(imagename[0]);
    SetCoverUploadImage(true);
  };

  const innerphotoUpload = (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    SetInnerImagePreviewUrl(URL.createObjectURL(imageFile));
    SetInnerOriginalImage(imageFile);
    SetInnerOutputFileName(imageFile.name);
    var imagename = imageFile.name.split(".");
    setInnerImageAlt(imagename[0]);
    SetInnerUploadImage(true);
  };

  let onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file, index) => {
      var newrandomnumber = Math.floor(100000 + Math.random() * 900000);
      const reader = new FileReader();

      reader.onload = function (e) {
        SetImages((prevState) => [
          ...prevState,
          {
            id: index,
            src: e.target.result,
            name: file.name,
            file: file,
            randomnumber: newrandomnumber,
            uploadtype: "services",
          },
        ]);
      };

      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  useEffect(() => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetServicesData").then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          var allactiveservices = [];
          obj.data.map((service, i) => {
            if (service.fld_status == "Active") {
              allactiveservices.push(service);
            }
          });
          setAllActiveServices(allactiveservices);
          setAllServicesData(obj.data);
          setInnerImageAlt(obj.data[0].fld_inner_image_alt);
          setTitle(obj.data[0].fld_title);
          setLongDescription(obj.data[0].fld_long_description);
          setCurrInnerImage(obj.data[0].fld_inner_image);
          localStorage.setItem("servicesid", obj.data[0].fld_id);
          PostApiCall.postRequest(
            {
              whereClause: `where fld_services_id=${obj.data[0].fld_id}`,
            },
            "GetServicesGalleryImages"
          ).then((results1) =>
            results1.json().then((obj1) => {
              if (results1.status == 200 || results1.status == 201) {
                if (obj1.data.length != 0) {
                  setAllServicesGalleryImages(obj1.data);
                  SetImages([]);
                  Notiflix.Loading.remove();
                } else {
                  setAllServicesGalleryImages([]);
                  Notiflix.Loading.remove();
                }
              } else {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure("Error Occurred");
              }
            })
          );
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("No Records Exits");
          setAllServicesData([]);
        }
      })
    );
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function onServicesGalleryUploadImage(imageFile, randomnumber) {
    var options = {
      maxSizeMB: 0.05,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    imageCompression(imageFile, options).then(async (compressedFile) => {
      const form = new FormData();
      form.append("file", compressedFile);
      form.append("foldername", "Activities");
      form.append("filename", randomnumber + "-" + imageFile.name);
      let response = await fetch(ImageApiUrl, {
        method: "POST",
        body: form,
      }).then((response) => {
        if (response.status == 200 || response.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Image Uploaded Successfully");
          PostApiCall.postRequest(
            {
              whereClause: `where fld_services_id=${parseInt(
                localStorage.getItem("servicesid")
              )}`,
            },
            "GetServicesGalleryImages"
          ).then((results1) =>
            results1.json().then((obj1) => {
              if (results1.status == 200 || results1.status == 201) {
                if (obj1.data.length != 0) {
                  setAllServicesGalleryImages(obj1.data);
                  Notiflix.Loading.remove();
                } else {
                  setAllServicesGalleryImages([]);
                  Notiflix.Loading.remove();
                }
              } else {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure("Error Occurred");
              }
            })
          );
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Image Uploading Failed");
        }
      });
    });
  }

  function onUploadServiceMasterImage(imageFileName, randomnumber, imagetype) {
    var options = {
      maxSizeMB: 0.05,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    if (imagetype == "innerimage") {
      imageCompression(inneroriginalImage, options).then(
        async (compressedFile) => {
          // console.log(compressedFile);
          const form = new FormData();
          form.append("file", compressedFile);
          form.append("foldername", "Activities");
          form.append("filename", randomnumber + "-" + imageFileName);
          let response = await fetch(ImageApiUrl, {
            method: "POST",
            body: form,
          }).then((response) => {
            if (response.status == 200 || response.status == 201) {
              Notiflix.Loading.remove();
              Notiflix.Notify.success("Services Updated Successfully.");
            } else {
              Notiflix.Loading.remove();
              Notiflix.Notify.failure("Image Uploading Failed");
            }
          });
        }
      );
    } else {
      imageCompression(coveroriginalImage, options).then(
        async (compressedFile) => {
          const form = new FormData();
          form.append("file", compressedFile);
          form.append("foldername", "Activities");
          form.append("filename", randomnumber + "-" + imageFileName);
          // console.log(compressedFile);
          // console.log(form.files);
          let response = await fetch(ImageApiUrl, {
            method: "POST",
            body: form,
          }).then((response) => {
            if (response.status == 200 || response.status == 201) {
              Notiflix.Loading.remove();
              Notiflix.Notify.success("Services Updated Successfully.");
            } else {
              Notiflix.Loading.remove();
              Notiflix.Notify.failure("Image Uploading Failed");
            }
          });
        }
      );
    }
    SetInnerOriginalImage([]);
    SetCoverOriginalImage([]);
    // window.location.reload();
  }

  function onSaveServicesData() {
    var randomnumber = Math.floor(100000 + Math.random() * 900000);
    var coverimagetype = coveroutputFileName.split(".");
    var coverimagename = coverimagealt + "." + coverimagetype[1];
    var innerimagetype = inneroutputFileName.split(".");
    var innerimagename = innerimagealt + "." + innerimagetype[1];
    Notiflix.Loading.dots();
    var serviceobj = {};
    if (inneroriginalImage.length != 0 || coveroriginalImage.length != 0) {
      if (inneroriginalImage.length != 0 && coveroriginalImage.length == 0) {
        serviceobj = {
          id: servicesid,
          innerimage:
            "https://cms.nabdelhi.in/images/Activities/" +
            randomnumber +
            "-" +
            innerimagename,
          innerimagealt: innerimagealt,
          title: title,
          shortdescription: shortdescription,
          longdescription: longdescription,
          coverimage: coverimagePreviewUrl,
          coverimagealt: coverimagealt,
          status: status,
          updatedon: new Date(),
          createdon: new Date(),
        };
      } else if (
        inneroriginalImage.length != 0 &&
        coveroriginalImage.length != 0
      ) {
        serviceobj = {
          id: servicesid,
          innerimage:
            "https://cms.nabdelhi.in/images/Activities/" +
            randomnumber +
            "-" +
            innerimagename,
          innerimagealt: innerimagealt,
          title: title,
          shortdescription: shortdescription,
          longdescription: longdescription,
          coverimage:
            "https://cms.nabdelhi.in/images/Activities/" +
            randomnumber +
            "-" +
            coverimagename,
          coverimagealt: coverimagealt,
          status: status,
          updatedon: new Date(),
          createdon: new Date(),
        };
      } else if (
        coveroriginalImage.length != 0 &&
        inneroriginalImage.length == 0
      ) {
        serviceobj = {
          id: servicesid,
          innerimage: innerimagePreviewUrl,
          innerimagealt: innerimagealt,
          title: title,
          shortdescription: shortdescription,
          longdescription: longdescription,
          coverimage:
            "https://cms.nabdelhi.in/images/Activities/" +
            randomnumber +
            "-" +
            coverimagename,
          coverimagealt: coverimagealt,
          status: status,
          updatedon: new Date(),
          createdon: new Date(),
        };
      }
    } else {
      serviceobj = {
        id: servicesid,
        innerimage: innerimagePreviewUrl,
        innerimagealt: innerimagealt,
        title: title,
        shortdescription: shortdescription,
        longdescription: longdescription,
        coverimage: coverimagePreviewUrl,
        coverimagealt: coverimagealt,
        status: status,
        updatedon: new Date(),
        createdon: new Date(),
      };
    }
    PostApiCall.postRequest(serviceobj, "AddServices").then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          if (
            inneroriginalImage.length != 0 ||
            coveroriginalImage.length != 0
          ) {
            if (
              inneroriginalImage.length != 0 &&
              coveroriginalImage.length == 0
            ) {
              onUploadServiceMasterImage(
                innerimagename,
                randomnumber,
                "innerimage"
              );
            } else if (
              inneroriginalImage.length != 0 &&
              coveroriginalImage.length != 0
            ) {
              onUploadServiceMasterImage(
                innerimagename,
                randomnumber,
                "innerimage"
              );
              onUploadServiceMasterImage(
                coverimagename,
                randomnumber,
                "coverimage"
              );
            } else if (
              coveroriginalImage.length != 0 &&
              inneroriginalImage.length == 0
            ) {
              onUploadServiceMasterImage(
                coverimagename,
                randomnumber,
                "coverimage"
              );
            }
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.success("Services Updated Successfully");
            // window.location.reload();
          }
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
  }

  function onCloseServicesModal() {
    window.location.reload();
  }
  function onAddNewServices() {
    navigate("/addservices");
  }
  return (
    <>
      <Navbar />

      <div className="container mt-5">
        <div className="row">
          <div className="col-12 text-center">
            <div className="main-heading pb-1">
              <h2 className="main-title wow animate__animated animate__fadeInUp">
                Manage Services
              </h2>
              <h3 className="main-text wow animate__animated animate__fadeInUp">
                You can manage the content for the Services Offered
              </h3>
            </div>
          </div>

          <div className="col-12">
            <div className="row service-section form-container">
              <div className="mb-3 d-flex justify-content-between border-bottom pb-3">
                <h3 class="main-text fw-bold">Services List</h3>
                <button
                  type="button"
                  className="default-btn ms-auto"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                  onClick={() => {
                    handleShow();
                  }}
                >
                  <img
                    alt="sequential-img"
                    src={Sequentialize}
                    className="sequentialize-btn"
                  />
                </button>
                <button
                  type="button"
                  className="default-btn"
                  onClick={() => {
                    onAddNewServices();
                  }}
                >
                  Add New Service
                </button>
              </div>
              <div
                className="col-12"
                style={{
                  display: allservicesdata.length == 0 ? "none" : "block",
                }}
              >
                <h3 class="main-text fw-bold">Services Inner Page List</h3>

                <div className="service-card service-card-inner">
                  <div className="s-card-img">
                    <img
                      src={currinnerimage}
                      alt={innerimagealt}
                      className="img-fluid"
                    />
                    <div className="s-content mt-3">
                      <h4>{title}</h4>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(longdescription),
                        }}
                      />
                    </div>

                    <div className="mt-3 row">
                      <div className="col-12 mb-3">
                        <div className="row mt-3 ms-3">
                          {allservicesgalleryimages.length == 0 ? (
                            <h3 className="form-field">No images</h3>
                          ) : (
                            <div className="row mb-5">
                              <h3 className="form-field">Images</h3>
                              {allservicesgalleryimages.map((imagedata, i) => {
                                return (
                                  <div className="col-3 image-main image-main-2">
                                    <GalleryImgUpload
                                      id={imagedata.fld_id}
                                      onChange={(e) => {
                                        e.preventDefault();
                                        const imageFile = e.target.files[0];
                                        var randomnumber = Math.floor(
                                          100000 + Math.random() * 900000
                                        );

                                        if (imageFile.name != undefined) {
                                          PostApiCall.postRequest(
                                            {
                                              id: imagedata.fld_id,
                                              servicesid: parseInt(
                                                localStorage.getItem(
                                                  "servicesid"
                                                )
                                              ),
                                              image:
                                                "https://cms.nabdelhi.in/images/Activities/" +
                                                randomnumber +
                                                "-" +
                                                imageFile.name,
                                              imagealt: imagedata.fld_image_alt,
                                              createdon: new Date(),
                                              updatedon: new Date(),
                                            },
                                            "AddServicesGalleryImage"
                                          ).then((results1) =>
                                            results1.json().then((obj1) => {
                                              if (
                                                results1.status == 200 ||
                                                results1.status == 201
                                              ) {
                                                Notiflix.Loading.remove();
                                                Notiflix.Notify.success(
                                                  "Image Uploading in Process"
                                                );
                                                onServicesGalleryUploadImage(
                                                  imageFile,
                                                  randomnumber
                                                );
                                              } else {
                                                Notiflix.Loading.remove();
                                                Notiflix.Notify.failure(obj1);
                                              }
                                            })
                                          );
                                        }
                                      }}
                                      src={imagedata.fld_image}
                                      imagealt={imagedata.fld_image_alt}
                                    />
                                    <span
                                      onClick={() => {
                                        confirmAlert({
                                          title: "Confirm to Delete",
                                          message:
                                            "Are you sure you want to delete the image?",

                                          buttons: [
                                            {
                                              label: "Yes",
                                              onClick: () => {
                                                Notiflix.Loading.dots();
                                                PostApiCall.postRequest(
                                                  {
                                                    id: imagedata.fld_id,
                                                  },
                                                  "DeleteServicesGalleryImage"
                                                ).then((results) =>
                                                  results.json().then((obj) => {
                                                    if (
                                                      results.status == 200 ||
                                                      results.status == 201
                                                    ) {
                                                      Notiflix.Loading.remove();
                                                      Notiflix.Notify.success(
                                                        "Image successfully deleted."
                                                      );

                                                      PostApiCall.postRequest(
                                                        {
                                                          whereClause: `where fld_services_id=${parseInt(
                                                            localStorage.getItem(
                                                              "servicesid"
                                                            )
                                                          )}`,
                                                        },
                                                        "GetServicesGalleryImages"
                                                      ).then((results1) =>
                                                        results1
                                                          .json()
                                                          .then((obj1) => {
                                                            if (
                                                              results1.status ==
                                                                200 ||
                                                              results1.status ==
                                                                201
                                                            ) {
                                                              if (
                                                                obj1.data
                                                                  .length != 0
                                                              ) {
                                                                setAllServicesGalleryImages(
                                                                  obj1.data
                                                                );
                                                                Notiflix.Loading.remove();
                                                              } else {
                                                                setAllServicesGalleryImages(
                                                                  []
                                                                );
                                                                Notiflix.Notify.failure(
                                                                  "No Data Exists"
                                                                );
                                                              }
                                                            } else {
                                                              Notiflix.Loading.remove();
                                                              Notiflix.Notify.failure(
                                                                "Error Occurred"
                                                              );
                                                            }
                                                          })
                                                      );
                                                    } else {
                                                      Notiflix.Loading.remove();
                                                      Notiflix.Notify.failure(
                                                        "Something went wrong, try again later."
                                                      );
                                                    }
                                                  })
                                                );
                                              },
                                            },
                                            {
                                              label: "No",
                                            },
                                          ],
                                          overlayClassName:
                                            "overlay-custom-class-name",
                                        });
                                      }}
                                    >
                                      <MdClose />
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                          <div className="col-12 inner-image mb-3 loader-backdrop mt-5">
                            <h3 className="form-field">Add Image</h3>
                            <DropBoxUploader
                              onDrop={onDrop}
                              imageListHide={imageListHide}
                            />
                            <ShowImage images={images} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {allservicesdata.map((servicedata, i) => {
                return (
                  <div className="col-md-6 col-lg-4">
                    <div className="service-card">
                      <div className="s-card-img">
                        <img
                          src={servicedata.fld_cover_image}
                          alt={servicedata.fld_cover_image_alt}
                          className="img-fluid"
                        />
                        <div className="s-content">
                          <h4
                            onClick={() => {
                              setInnerImageAlt(servicedata.fld_inner_image_alt);
                              setTitle(servicedata.fld_title);
                              setLongDescription(
                                servicedata.fld_long_description
                              );
                              SetImages([]);
                              setImageListHide(true);
                              setCurrInnerImage(servicedata.fld_inner_image);
                              localStorage.setItem(
                                "servicesid",
                                servicedata.fld_id
                              );
                              Notiflix.Loading.dots();
                              PostApiCall.postRequest(
                                {
                                  whereClause: `where fld_services_id=${servicedata.fld_id}`,
                                },
                                "GetServicesGalleryImages"
                              ).then((results1) =>
                                results1.json().then((obj1) => {
                                  if (
                                    results1.status == 200 ||
                                    results1.status == 201
                                  ) {
                                    if (obj1.data.length != 0) {
                                      setAllServicesGalleryImages(obj1.data);
                                      Notiflix.Loading.remove();
                                    } else {
                                      Notiflix.Loading.remove();
                                      setAllServicesGalleryImages([]);
                                    }
                                  } else {
                                    Notiflix.Loading.remove();
                                    Notiflix.Notify.failure("Error Occurred");
                                  }
                                })
                              );
                            }}
                          >
                            {servicedata.fld_title}
                          </h4>
                          <p>{servicedata.fld_short_description}</p>
                          <div className="col-12 border-top pt-3 text-end d-flex">
                            <div className="form-field form-radio d-flex">
                              <span className={servicedata.fld_status}>
                                {servicedata.fld_status}
                              </span>
                            </div>
                            <div className="ms-auto">
                              <button
                                type="button"
                                className="default-btn ms-auto"
                                onClick={() => {
                                  setModalShow(true);
                                  setShortDescription(
                                    servicedata.fld_short_description
                                  );
                                  setLongDescription(
                                    servicedata.fld_long_description
                                  );
                                  setInnerImageAlt(
                                    servicedata.fld_inner_image_alt
                                  );
                                  setCoverImageAlt(
                                    servicedata.fld_cover_image_alt
                                  );
                                  SetCoverImagePreviewUrl(
                                    servicedata.fld_cover_image
                                  );
                                  SetInnerImagePreviewUrl(
                                    servicedata.fld_inner_image
                                  );
                                  setTitle(servicedata.fld_title);
                                  setStatus(servicedata.fld_status);
                                  setServicesId(servicedata.fld_id);
                                }}
                              >
                                Edit
                              </button>
                              <button
                                type="button"
                                className="default-btn"
                                onClick={() => {
                                  confirmAlert({
                                    title: "Confirm to Delete",
                                    message:
                                      "Are you sure you want to delete the service?",

                                    buttons: [
                                      {
                                        label: "Yes",
                                        onClick: () => {
                                          Notiflix.Loading.dots();
                                          PostApiCall.postRequest(
                                            {
                                              id: servicedata.fld_id,
                                            },
                                            "DeleteService"
                                          ).then((results) =>
                                            results.json().then((obj) => {
                                              if (
                                                results.status == 200 ||
                                                results.status == 201
                                              ) {
                                                Notiflix.Loading.remove();
                                                Notiflix.Notify.success(
                                                  "Service successfully deleted."
                                                );
                                                window.location.reload();
                                              } else {
                                                Notiflix.Loading.remove();
                                                Notiflix.Notify.failure(
                                                  "Something went wrong, try again later."
                                                );
                                              }
                                            })
                                          );
                                        },
                                      },
                                      {
                                        label: "No",
                                      },
                                    ],
                                    overlayClassName:
                                      "overlay-custom-class-name",
                                  });
                                }}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-90w"
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 px-0">
            <div className="form-container">
              <h3 class="main-text fw-bold">Add New Service</h3>
              <form className="w-100">
                <div className="row w-100">
                  <div className="col-12 inner-image mb-3">
                    <label>Upload Inner Page Image</label>
                    <span className="ms-5">
                      Image Size: Width: 1170px Height: 500px
                    </span>
                    <span className="ms-5">
                      Image Format: .jpg, .jpeg, .png, .webp
                    </span>
                    <InnerImgUpload
                      onChange={innerphotoUpload}
                      src={innerimagePreviewUrl}
                    />
                  </div>
                  <div className="col-12 pe-0 mb-3 ps-3">
                    <label className="me-3">Inner Image Alt text / Title</label>
                    <input
                      className="w-100"
                      type="text"
                      value={innerimagealt}
                      onChange={(e) => {
                        setInnerImageAlt(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-md-2">
                    <div className="row">
                      <div className="form-field col-12 me-0">
                        <label>Upload Cover Image</label>
                        <CoverImgUpload
                          onChange={coverphotoUpload}
                          src={coverimagePreviewUrl}
                        />

                        <p className="mt-4">
                          Image Size: <br /> Width: 400px <br /> Height: 250px
                        </p>
                        <p className="">
                          Image Format: <br /> .jpg, .jpeg, .png, .webp
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-10 pe-0">
                    <div className="row w-100 me-0 pe-0 gx-0">
                      <div className="form-field col-12 me-0">
                        <label>Title (max. 70 characters)</label>
                        <input
                          type="text"
                          value={title}
                          onChange={(e) => {
                            setTitle(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-field col-12 me-0">
                        <label>Short Description (max. 180 characters)</label>
                        <input
                          type="text"
                          value={shortdescription}
                          onChange={(e) => {
                            setShortDescription(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-field col-12 me-0">
                        <label>Long Description</label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={longdescription}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setLongDescription(data);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mb-3 pe-0">
                    <label className="me-3">Cover Image Alt text / Title</label>
                    <input
                      className="w-100"
                      type="text"
                      value={coverimagealt}
                      onChange={(e) => {
                        setCoverImageAlt(e.target.value);
                      }}
                    />
                  </div>

                  <div className="form-field col-12 me-0 form-radio">
                    <label>Status</label>
                    <div className="d-flex">
                      <span className="d-flex">
                        <input
                          type="radio"
                          name="status"
                          className="ms-2"
                          checked={status == "Active" ? true : false}
                          onChange={() => {
                            setStatus("Active");
                          }}
                        />
                        Active
                      </span>
                      <span className="d-flex">
                        <input
                          type="radio"
                          name="status"
                          checked={status == "Inactive" ? true : false}
                          onChange={() => {
                            setStatus("Inactive");
                          }}
                        />
                        Inactive
                      </span>
                    </div>
                  </div>
                  <div className="col-12 border-top mx-auto text-end pt-3">
                    <button
                      type="button"
                      className="default-btn"
                      onClick={onCloseServicesModal}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="default-btn"
                      onClick={onSaveServicesData}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header className="border-bottom" closeButton>
          <Offcanvas.Title>Sequentialize</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <DragDropList
            verticalData={allActiveServices}
            for="Vertical"
            htmlFor="verticle"
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
