import React, { useCallback, useState } from "react";
import "./ImageGallery.css";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import ShowImage from "../../components/DropBoxUploader/ShowImage";
import DropBoxUploader from "../../components/DropBoxUploader/DropBoxUploader";
import imageCompression from "browser-image-compression";
import Notiflix from "notiflix";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Modal from "react-bootstrap/Modal";
import PostApiCall from "../../PostApi";

const ImgUpload = ({ onChange, src }) => (
  <label
    htmlFor="photo-upload"
    className="custom-file-upload fas"
    style={{ marginBottom: "-5px" }}
  >
    <div className="img-wrap obimages img-upload">
      <img
        htmlFor="photo-upload"
        src={src}
        style={{ width: "100%", height: "100%", borderRadius: "5%" }}
      />
    </div>
    <input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
  </label>
);

function LinearProgressWithLabel(props) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        marginTop: "auto",
        marginBottom: "auto",
      }}
    >
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

var randomnumber = Math.floor(100000 + Math.random() * 900000);
export default function ImageGallery() {
  const ImageApiUrl = "https://cms.nabdelhi.in/nabdapi/UploadGalleryImage";
  const [ImagePreviewUrl, SetImagePreviewUrl] = useState(
    "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png"
  );
  const [OriginalImage, SetOriginalImage] = useState([]);
  const [outputFileName, SetOutputFileName] = useState("");
  const [UploadImage, SetUploadImage] = useState(false);
  const [randomnum, SetRandomNumber] = useState(randomnumber);
  const [progress, setProgress] = React.useState(10);
  const [images, SetImages] = useState([]);
  const [imagealt, setImageAlt] = useState("");
  const [id, setId] = useState(null);
  const [albumname, setAlbumName] = useState("");
  const [status, setStatus] = useState("Active");
  let counter = 0;

  const photoUpload = (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    SetImagePreviewUrl(URL.createObjectURL(imageFile));
    SetOriginalImage(imageFile);
    SetOutputFileName(imageFile.name);
    var imagename = imageFile.name.split(".");
    setImageAlt(imagename[0]);
    SetUploadImage(true);
  };

  function onUploadImage(imageFileName, randomnumber) {
    var options = {
      maxSizeMB: 0.05,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    imageCompression(OriginalImage, options).then(async (compressedFile) => {
      const form = new FormData();
      form.append("file", compressedFile);
      form.append("foldername", "Activities");
      form.append("filename", randomnumber + "-" + imageFileName);
      let response = await fetch(ImageApiUrl, {
        method: "POST",
        body: form,
      }).then((response) => {
        if (response.status == 200 || response.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Image Gallery Album Added Successfully.");
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Image Uploading Failed");
        }
      });
    });
  }

  function onSaveImageGalleryData() {
    Notiflix.Loading.dots();
    var imagetype = outputFileName.split(".");
    var imagename = imagealt + "." + imagetype[1];
    PostApiCall.postRequest(
      {
        id: id,
        album_name: albumname,
        status: status,
        image:
          "https://cms.nabdelhi.in/images/Activities/" +
          randomnum +
          "-" +
          imagename,
        imagealt: imagealt,
        createdon: new Date(),
        updatedon: new Date(),
      },
      "AddImageGalleryAlbum"
    ).then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          onUploadImage(imagename, randomnum);
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
  }

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setProgress(10);
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Navbar />

      <div className="container mt-5">
        <div className="row">
          <div className="col-12 text-center">
            <div className="main-heading pb-1">
              <h2 className="main-title wow animate__animated animate__fadeInUp">
                Photo Gallery
              </h2>
              <h3 className="main-text wow animate__animated animate__fadeInUp">
                Manage Photo Albums of happenings in NAB
              </h3>
            </div>
          </div>

          <div className="col-12">
            <div className="form-container">
              <h3 class="main-text fw-bold">Add Gallery Image</h3>
              <form className="row pe-3">
                <div className="col-12 pe-0 mb-3 ps-3">
                  <label className="me-3">Album Name</label>
                  <input
                    className="w-100"
                    type="text"
                    value={albumname}
                    onChange={(e) => {
                      setAlbumName(e.target.value);
                    }}
                  />
                </div>

                <div className="col-12 inner-image mb-3">
                  <label>Upload Image</label>

                  <span className="ms-4">
                    Image Format: .jpg, .jpeg, .png, .webp
                  </span>
                  <ImgUpload onChange={photoUpload} src={ImagePreviewUrl} />
                </div>
                <div className="col-12 pe-0 mb-3 ps-3">
                  <label className="me-3">Image Alt text / Title</label>
                  <input
                    className="w-100"
                    type="text"
                    value={imagealt}
                    onChange={(e) => {
                      setImageAlt(e.target.value);
                    }}
                  />
                </div>
                <div className="form-field col-12 me-0 form-radio">
                  <label>Status</label>
                  <div className="d-flex">
                    <span className="d-flex">
                      <input
                        type="radio"
                        name="status"
                        className="ms-2"
                        checked={status == "Active" ? true : false}
                        onChange={() => {
                          setStatus("Active");
                        }}
                      />
                      Active
                    </span>
                    <span className="d-flex">
                      <input
                        type="radio"
                        name="status"
                        checked={status == "Inactive" ? true : false}
                        onChange={() => {
                          setStatus("Inactive");
                        }}
                      />
                      Inactive
                    </span>
                  </div>
                </div>
                <div className="col-12 border-top text-end pt-3">
                  <button
                    type="button"
                    className="default-btn"
                    onClick={onSaveImageGalleryData}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
