import React, { useState } from "react";
import "./ChangePassword.css";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Notiflix from "notiflix";
import PostApiCall from "../../PostApi";

export default function ChangePassword() {
  const [newpassword, setNewPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");

  function onSavePassword() {
    if (newpassword != "" && confirmpassword != "") {
      if (newpassword === confirmpassword) {
        Notiflix.Loading.dots();

        PostApiCall.postRequest(
          {
            username: localStorage.getItem("Email"),
            newpassword: newpassword,
          },
          "ChangePassword"
        ).then((results1) =>
          results1.json().then((obj1) => {
            if (results1.status == 200 || results1.status == 201) {
              Notiflix.Loading.remove();
              Notiflix.Notify.success("Password Changed Successfully");
            } else {
              Notiflix.Loading.remove();
              Notiflix.Notify.failure("Error Occurred");
            }
          })
        );
      } else {
        Notiflix.Notify.failure("Password didn't match!");
      }
    } else {
      Notiflix.Notify.failure("Please enter password");
    }
  }

  return (
    <>
      <Navbar />
      <div className="container mt-5 pb-5">
        <div className="row pb-5 mb-5">
          <div className="col-12 text-center">
            <div className="main-heading pb-1">
              <h2 className="main-title wow animate__animated animate__fadeInUp">
                Change Password
              </h2>
              <h3 className="main-text wow animate__animated animate__fadeInUp">
                Reset your existing Password
              </h3>
            </div>
          </div>

          <div className="col-12">
            <div className="form-container">
              <form>
                <div className="row w-100">
                  <div className="form-field col-6 me-0">
                    <label>New Password</label>
                    <input
                      type="password"
                      className="w-100"
                      value={newpassword}
                      onChange={(e) => {
                        setNewPassword(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-field col-6 me-0">
                    <label>Confirm Password</label>
                    <input
                      type="text"
                      className="w-100"
                      value={confirmpassword}
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-12 text-end">
                    <button
                      type="button"
                      className="default-btn"
                      onClick={onSavePassword}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
