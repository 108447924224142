import React, { useState } from "react";
import "./VideoGallery.css";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Notiflix from "notiflix";
import PostApiCall from "../../PostApi";

export default function AddVideoGallery() {
  const [id, setId] = useState(null);
  const [title, setTitle] = useState("");
  const [videolink, setVideoLink] = useState("");
  const [status, setStatus] = useState("Active");

  function onSaveVideoGalleryData() {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        id: id,
        title: title,
        videolink: videolink,
        status: status,
        updatedon: new Date(),
        createdon: new Date(),
      },
      "AddVideoGallery"
    ).then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Video Added Successfully.");
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
  }

  return (
    <>
      <Navbar />

      <div className="container mt-5">
        <div className="row">
          <div className="col-12 text-center">
            <div className="main-heading pb-1">
              <h2 className="main-title wow animate__animated animate__fadeInUp">
                Video Gallery
              </h2>
              <h3 className="main-text wow animate__animated animate__fadeInUp">
                Manage YouTube Video links
              </h3>
            </div>
          </div>
          <div className="col-12">
            <div className="form-container">
              <h3 class="main-text fw-bold">Add New Video</h3>
              <form className="w-100">
                <div className="row w-100">
                  <div className="col-12 pe-0">
                    <div className="row">
                      <div className="form-field col-md-6 me-0 mt-4">
                        <label>Title</label>
                        <input
                          type="text"
                          value={title}
                          onChange={(e) => {
                            setTitle(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-field col-md-6 me-0">
                        <label>YouTube Video link</label>
                        <span className="text-danger">*only embed link</span>
                        <input
                          type="text"
                          value={videolink}
                          onChange={(e) => {
                            setVideoLink(e.target.value);
                          }}
                        />
                      </div>

                      <div className="form-field col-12 me-0 form-radio">
                        <label>Status</label>
                        <div className="d-flex">
                          <span className="d-flex">
                            <input
                              type="radio"
                              name="status"
                              className="ms-2"
                              checked={status == "Active" ? true : false}
                              onChange={() => {
                                setStatus("Active");
                              }}
                            />
                            Active
                          </span>
                          <span className="d-flex">
                            <input
                              type="radio"
                              name="status"
                              checked={status == "Inactive" ? true : false}
                              onChange={() => {
                                setStatus("Inactive");
                              }}
                            />
                            Inactive
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 border-top text-end pt-3">
                    <button
                      type="button"
                      className="default-btn"
                      onClick={onSaveVideoGalleryData}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
