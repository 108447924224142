import React, { useEffect } from "react";
// import "./Projects.css";
import { useState } from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Notiflix from "notiflix";
import PostApiCall from "../../PostApi";
import imageCompression from "browser-image-compression";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { json } from "react-router-dom";

const ImgUpload = ({ onChange, src }) => (
	<label
		htmlFor="photo-upload"
		className="custom-file-upload fas"
		style={{ marginBottom: "-5px" }}
	>
		<div className="img-wrap obimages img-upload">
			<img
				htmlFor="photo-upload"
				src={src}
				style={{ width: "100%", height: "100%", borderRadius: "5%" }}
			/>
		</div>
		<input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
	</label>
);

export default function AddProjects() {
	const ImageApiUrl = 'https://cms.nabdelhi.in/nabdapi/' + "AddImage";
	const [ImagePreviewUrl, SetImagePreviewUrl] = useState(
		"https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png"
	);
	const [OriginalImage, SetOriginalImage] = useState([]);
	const [outputFileName, SetOutputFileName] = useState("");
	const [UploadImage, SetUploadImage] = useState(false);
	const [coverimagealt, setCoverImageAlt] = useState("");
	const [status, setStatus] = useState("Active");
	const [bannnerLocation, setBannnerLocation] = useState(null);
	const [pageName, setPageName] = useState(null);
	const [pageUrl, setPageUrl] = useState(null)
	const [pageHeading, setPageHeading] = useState(null)
	const [pageTitle, setPageTitle] = useState(null)
	const [bannerId, setBannerId] = useState(null)
	const [imageId, setImageId] = useState(null)
	const [bannerImage, setBannerImage] = useState(null);
	const [bannerHeight, setBannerHeight] = useState(null);
	const [bannerWidth, setBannerWidth] = useState(null);
	const [bannerData, setBannerData] = useState([]);
	const [altText, setAltText] = useState(null);

	useEffect(() => {
		const bannerDetails = localStorage.getItem("BannerDetails");
		const bannerInfo = JSON.parse(bannerDetails);

		if (bannerDetails !== null) {
			const imageInfo = JSON.parse(bannerInfo.desktop)
			console.log(imageInfo[0])
			setImageId(imageInfo[0]?.FLD_Image_Id)
			setPageUrl(bannerInfo?.slug)
			setBannerId(bannerInfo?.id)
			SetImagePreviewUrl(imageInfo[0]?.
				FLD_Banner_URL)
			setBannerImage(imageInfo[0]?.FLD_Banner_URL)
			setAltText(imageInfo[0]?.FLD_Alt)
		}
	}, [])

	const photoUpload = async (e) => {
		// console.log(e.target.files)
		var randomnumber = Math.floor(100000 + Math.random() * 900000);
		// var imagetype = outputFileName.split(".");
		// var imagename = coverimagealt + "." + imagetype[1];
		e.preventDefault();
		const imageFile = e.target.files[0];
		if (!imageFile) return;
		SetImagePreviewUrl(URL?.createObjectURL(imageFile));
		SetOriginalImage(imageFile);
		SetOutputFileName(imageFile.name);
		var imagename = imageFile.name.split(".")[0];
		var imageExt = imageFile.type.split("/")[1];
		// var imageExt = imageFile.name.split(".")[1];
		setCoverImageAlt(imagename[0]);
		SetUploadImage(true);
		await onUploadImage(imagename + "." + imageExt, randomnumber, imageFile);

	};

	function onUploadImage(imageFileName, randomnumber, imageFile) {
		// Create an Image object to get the dimensions
		const img = new Image();

		// Create a FileReader to read the image file
		const reader = new FileReader();

		// When the image is loaded, get its dimensions
		reader.onload = function (e) {
			// Set the image source to the result of the file reader
			img.onload = function () {
				const imageWidth = img.width;
				const imageHeight = img.height;
				setBannerHeight(imageHeight)
				setBannerWidth(imageWidth)

				// Now, proceed with your image upload after getting the dimensions
				const form = new FormData();
				form.append("file", imageFile);
				form.append("foldername", "Activities");
				form.append("filename", randomnumber + "-" + imageFileName);
				let response1 = fetch(ImageApiUrl, {
					method: "POST",
					body: form,
				}).then((response) => {
					if (response.status == 200 || response.status == 201) {
						Notiflix.Loading.remove();
						setBannerImage("https://cms.nabdelhi.in/images/Activities/" + randomnumber + "-" + imageFileName)


						Notiflix.Notify.success("Banner Image Added Successfully.");
					} else {
						Notiflix.Loading.remove();
						Notiflix.Notify.failure("Image Uploading Failed");
					}
				});
			};

			// Set the image source to the file URL
			img.src = e.target.result;
		};

		// Read the image file as a DataURL
		reader.readAsDataURL(imageFile);
	}

	function onSubmit() {
		if (bannerImage === null) {
			Notiflix.Notify.failure("Please upload banner image");
			return;
		}
		if (pageUrl === null) {
			Notiflix.Notify.failure("Please enter banner url");
			return;
		}
		if (pageHeading === null) {
			Notiflix.Notify.failure("Please enter banner heading");
			return;
		}
		if (pageTitle === null) {
			Notiflix.Notify.failure("Please enter banner title");
			return;
		}
		if (altText === null) {
			Notiflix.Notify.failure("Please enter banner alt text");
			return;
		}

		onSaveProjectsData()

	}


	function onSaveProjectsData() {


		PostApiCall.postRequest(
			{
				bannerid: bannerId,
				bannerlocation: "Main",
				bannerstatus: status,
				pagename: "Home",
				bannerurl: bannerImage,
				title: pageTitle,
				slug: pageUrl,
				images: [{
					imageid: imageId,
					bannerid: bannerId,
					status: "Active",
					url: bannerImage,
					width: bannerWidth,
					height: bannerHeight,
					bannerfor: "Desktop",
					alt: altText,
				}],
				heading: pageHeading
			},
			"AddBanner"
		).then((resultcategory) =>
			resultcategory.json().then((obj) => {
				if (resultcategory.status == 200 || resultcategory.status == 201) {
					Notiflix.Notify.success("Banner Updated");
					window.location.href = '/homebanner'
					Notiflix.Loading.remove();
				} else {
					Notiflix.Loading.remove();
					Notiflix.Notify.failure("Banner Upload Failed")
				}
			})
		);
	}

	return (
		<>
			<Navbar />

			<div className="container mt-5">
				<div className="row">
					<div className="col-12 text-center">
						<div className="main-heading pb-1">
							<h2 className="main-title wow animate__animated animate__fadeInUp">
								Manage Banner
							</h2>
							<h3 className="main-text wow animate__animated animate__fadeInUp">
								Add and Manage Banner
							</h3>
						</div>
					</div>

					<div className="col-12 px-0">
						<div className="form-container">
							<h3 class="main-text fw-bold">Add New Banner</h3>
							<form className="w-100">
								<div className="row w-100">
									<div className="col-md-2">
										<div className="row">
											<div className="form-field col-12 me-0">
												<p>Upload Cover Image (size:1520 * 730 px)</p>
												<ImgUpload
													onChange={photoUpload}
													src={ImagePreviewUrl}
												/>
												<p className="">
													Image Format: <br /> .jpg, .jpeg, .png, .webp
												</p>
											</div>
										</div>
									</div>
									<div className="col-md-10 pe-0">
										<div className="row gx-0 pe-0">
											<div className="d-flex justify-content-between">
												<div className="form-field col-5 pe-0 me-0">
													<label>Page Name</label>
													<select
														class="w-100"
														onChange={(e) => {
															setPageName(e.target.value);
														}}
													>
														<option value="Select Document Category">
															Select Page Name
														</option>
														<option value="Ongoing Projects">
															Home
														</option>
													</select>
												</div>
												<div className="form-field col-md-6 me-0 ms-3">
													<label>Location</label>
													<select
														class="w-100"
														onChange={(e) => {
															setBannnerLocation(e.target.value);
														}}
													>
														<option value="Select Document Category">
															Select Location
														</option>
														<option value="Ongoing Projects">
															Main
														</option>
													</select>
												</div>
											</div>
											<div className="form-field col-12 pe-0 me-0">
												<label>URL (URL of the page to which banner should navigate when it is clicked.)*</label>
												<input
													type="text"
													value={pageUrl}
													onChange={(e) => {
														setPageUrl(e.target.value);
													}}
												/>
											</div>
											<div className="form-field col-12 pe-0 me-0">
												<label>Heading *</label>
												<input
													type="text"
													value={pageHeading}
													onChange={(e) => {
														setPageHeading(e.target.value);
													}}
												/>
											</div>
											<div className="form-field col-12 pe-0 me-0">
												<label>Title *</label>
												<input
													type="text"
													value={pageTitle}
													onChange={(e) => {
														setPageTitle(e.target.value);
													}}
												/>
											</div>

										</div>
									</div>
									<div className="col-12 pe-0">
										<label className="me-3">Cover Image Alt text / Title</label>
										<input
											className="w-100"
											type="text"
											value={altText}
											onChange={(e) => {
												setAltText(e.target.value);
											}}
										/>
									</div>
									<div className="form-field col-12 me-0 form-radio">
										<label>Status</label>
										<div className="d-flex">
											<span className="d-flex">
												<input
													type="radio"
													name="status"
													className="ms-2"
													checked={status == "Active" ? true : false}
													onChange={() => {
														setStatus("Active");
													}}
												/>
												Active
											</span>
											<span className="d-flex">
												<input
													type="radio"
													name="status"
													checked={status == "Inactive" ? true : false}
													onChange={() => {
														setStatus("Inactive");
													}}
												/>
												Inactive
											</span>
										</div>
									</div>
									<div className="col-12 border-top text-end pt-3">
										<button
											type="button"
											className="default-btn"
											onClick={onSubmit}
										>
											Save
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}
