import createDOMPurify from "dompurify";
import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import GetApiCall from "../../GETAPI";
import PostApiCall from "../../PostApi";
import "./Academy.css";
const DOMPurify = createDOMPurify(window);

export default function AcademyList({
  academyData,
  editAcademyData,
  getACademyDetails,
}) {
  function handleDelete(id) {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete the academy?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            Notiflix.Loading.dots();
            PostApiCall.postRequest({ id }, "DeleteAcademy").then((results) =>
              results.json().then((obj) => {
                Notiflix.Loading.remove();
                if (results.status === 200 || results.status === 201) {
                  Notiflix.Notify.success("Academy successfully deleted.");
                  getACademyDetails();
                } else {
                  Notiflix.Notify.failure(
                    "Something went wrong, try again later."
                  );
                }
              })
            );
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-12">
          <h3 className="main-text fw-bold">Academy Page</h3>
          <div className="row service-section form-container mt-4 pt-0 bg-white">
            {academyData.length == 0 ? (
              <div className="no-data-found w-100 text-center">
                <h4>No data</h4>
              </div>
            ) : (
              academyData.map((data, i) => (
                <div className="col-md-6 col-lg-4" key={data.fld_id}>
                  <div className="service-card">
                    <div className="s-card-img">
                      <img
                        alt="coverimage"
                        src={data.fld_coverimage}
                        className="img-fluid"
                      />
                      <div className="s-content">
                        <h4
                          className="cursor-pointer academy-title-box"
                          onClick={() => editAcademyData(data)}
                        >
                          {data.fld_title}
                        </h4>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              data.fld_shortdescription
                            ),
                          }}
                        />
                        <div className="col-12 border-top pt-3 text-end d-flex">
                          <div className="form-field form-radio d-flex">
                            <span className={data.fld_status}>
                              {data.fld_status}
                            </span>
                          </div>
                          <div className="ms-auto">
                            <button
                              type="button"
                              className="default-btn ms-auto"
                              onClick={() => editAcademyData(data)}
                            >
                              Edit
                            </button>
                            <button
                              type="button"
                              className="default-btn"
                              onClick={() => handleDelete(data.fld_id)}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
