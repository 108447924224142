import React, { useState } from "react";
import "../LoginPage/LoginPage.css";
import LoginNavbar from "../../components/Navbar/LoginNavbar";
import PostApiCall from "../../PostApi";
import Notiflix from "notiflix";
import moment from "moment";

function LoginPage() {
  const [email, SetEmail] = useState("");
  const [password, SetPasword] = useState("");
  const [emailregex, SetEmailRegex] = useState(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  const [rememberMe, SetRememberMe] = useState(null);

  function onLoginSubmit() {
    if (email != "") {
      if (emailregex.test(email)) {
        if (password != "") {
          Notiflix.Loading.dots("");
          PostApiCall.postRequest(
            {
              email: email,
              password: password,
              action: "Login",
              actiondate: moment().format("lll"),
            },
            "AuthenticateUser"
          ).then((results) =>
            results.json().then((obj) => {
              if (results.status == 200 || results.status == 201) {
                localStorage.setItem(
                  "lastlogin",
                  JSON.stringify(moment().format("lll"))
                );
                localStorage.setItem("access", JSON.stringify(obj.token));
                localStorage.setItem("LoginDetail", JSON.stringify(obj.data));
                localStorage.setItem("ClientDetails", JSON.stringify(obj.data));
                localStorage.setItem("RememberMe", rememberMe);
                localStorage.setItem("Email", email);
                Notiflix.Loading.remove();
                window.location.href = "/dashboard";
              } else {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure(obj.data);
              }
            })
          );
        } else {
          Notiflix.Notify.failure("Please enter password.");
        }
      } else {
        Notiflix.Notify.failure("Please enter valid email address.");
      }
    } else {
      Notiflix.Notify.failure("Please enter email address.");
    }
  }
  return (
    <>
      <section className="Main-slider position-relative">
        <LoginNavbar />
        <div className="container">
          <div className="row justify-content-lg-end">
            <div className="col-lg-7 text-start">
              <div className="home-banner">
                <p>We are Making a Difference !</p>
                <h2>National Association for the Blind</h2>
                <p>Admin Panel</p>
                <div className="news-input d-flex flex-column px-0">
                  <form autocomplete="off">
                    <div className="row mb-3">
                      <label className="col-2">Username</label>
                      <input
                        type="text"
                        className=" col-4 ms-auto"
                        autocomplete="false"
                        name="hidden"
                        value={email}
                        onChange={(e) => {
                          SetEmail(e.target.value);
                        }}
                      />
                    </div>
                    <div className="row mb-3">
                      <label className="col-2">Password</label>
                      <input
                        type="password"
                        className=" col-4 ms-auto"
                        autocomplete="false"
                        name="hidden"
                        value={[password]}
                        onChange={(e) => {
                          SetPasword(e.target.value);
                        }}
                      />
                    </div>
                  </form>
                  <button
                    href=""
                    alt="Login to admin panel"
                    className="default-btn mt-2 d-inline-block ms-auto"
                    onClick={onLoginSubmit}
                  >
                    Login to the Panel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default LoginPage;
