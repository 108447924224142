import React from "react";
import "./Stories.css";
import Notiflix from "notiflix";
import PostApiCall from "../../PostApi";
import { useState } from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import imageCompression from "browser-image-compression";

const ImgUpload = ({ onChange, src }) => (
  <label
    htmlFor="photo-upload"
    className="custom-file-upload fas"
    style={{ marginBottom: "-5px" }}
  >
    <div className="img-wrap obimages img-upload">
      <img
        htmlFor="photo-upload"
        src={src}
        style={{ width: "100%", height: "100%", borderRadius: "5%" }}
      />
    </div>
    <input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
  </label>
);
var randomnumber = Math.floor(100000 + Math.random() * 900000);
export default function AddStory() {
  const ImageApiUrl = "https://cms.nabdelhi.in/nabdapi/UploadGalleryImage";
  const [ImagePreviewUrl, SetImagePreviewUrl] = useState(
    "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png"
  );
  const [OriginalImage, SetOriginalImage] = useState([]);
  const [outputFileName, SetOutputFileName] = useState("");
  const [UploadImage, SetUploadImage] = useState(false);
  const [randomnum, SetRandomNumber] = useState(randomnumber);
  const [storyid, SetStoryId] = useState(null);
  const [storyname, SetStoryName] = useState("");
  const [storydesignation, SetStoryDesignation] = useState("");
  const [companyname, SetCompanyName] = useState("");
  const [storydescription, SetStoryDescription] = useState("");
  const [imagealt, SetImageAlt] = useState("");
  const [status, SetStatus] = useState("Active");

  const photoUpload = (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    SetImagePreviewUrl(URL.createObjectURL(imageFile));
    SetOriginalImage(imageFile);
    SetOutputFileName(imageFile.name);
    var imagename = imageFile.name.split(".");
    SetImageAlt(imagename[0]);
    SetUploadImage(true);
  };

  function onUploadImage(imageFileName, randomnumber) {
    var options = {
      maxSizeMB: 0.05,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    imageCompression(OriginalImage, options).then(async (compressedFile) => {
      const form = new FormData();
      form.append("file", compressedFile);
      form.append("foldername", "Activities");
      form.append("filename", randomnumber + "-" + imageFileName);
      let response = await fetch(ImageApiUrl, {
        method: "POST",
        body: form,
      }).then((response) => {
        if (response.status == 200 || response.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Story Added Successfully.");
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Image Uploading Failed");
        }
      });
    });
  }

  function onSaveStoryData() {
    Notiflix.Loading.dots();
    var imagetype = outputFileName.split(".");
    var imagename = imagealt + "." + imagetype[1];
    PostApiCall.postRequest(
      {
        storyid: storyid,
        storyname: storyname,
        storydesignation: storydesignation,
        companyname: companyname,
        storydescription: storydescription,

        storyimage:
          "https://cms.nabdelhi.in/images/Activities/" +
          randomnum +
          "-" +
          imagename,
        imagealt: imagealt,
        status: status,
      },
      "AddStory"
    ).then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          onUploadImage(imagename, randomnumber);
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
  }
  return (
    <>
      <Navbar />

      <div className="container mt-5">
        <div className="row">
          <div className="col-12 px-0">
            <div className="form-container">
              <h3 class="main-text fw-bold">Add New Story</h3>
              <form className="w-100">
                <div className="row w-100">
                  <div className="col-md-2">
                    <div className="row">
                      <div className="form-field col-12 me-0">
                        <span>Upload Image</span>
                        <ImgUpload
                          onChange={photoUpload}
                          src={ImagePreviewUrl}
                        />
                        <p className="mt-3">
                          Image Size: <br /> Width: 480px <br /> Height: 500px
                        </p>
                        <p className="">
                          Image Format: <br /> .jpg, .jpeg, .png, .webp
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="row">
                      <div className="form-field col-md-4 me-0">
                        <label>Name</label>
                        <input
                          type="text"
                          value={storyname}
                          onChange={(e) => {
                            SetStoryName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-field col-md-4 me-0">
                        <label>Designation</label>
                        <input
                          type="text"
                          value={storydesignation}
                          onChange={(e) => {
                            SetStoryDesignation(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-field col-md-4 me-0">
                        <label>Company Name</label>
                        <input
                          type="text"
                          value={companyname}
                          onChange={(e) => {
                            SetCompanyName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-field col-12 me-0">
                        <label>Description (max. 300 characters)</label>
                        <textarea
                          cols={1}
                          rows={5}
                          value={storydescription}
                          onChange={(e) => {
                            SetStoryDescription(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-12 mb-3">
                        <label className="me-3">Image Alt text / Title</label>
                        <input
                          className="w-100"
                          type="text"
                          value={imagealt}
                          onChange={(e) => {
                            SetImageAlt(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-field col-12 me-0 form-radio">
                    <label>Status</label>
                    <div className="d-flex">
                      <span className="d-flex">
                        <input
                          type="radio"
                          name="status"
                          className="ms-2"
                          checked={status == "Active" ? true : false}
                          onChange={() => {
                            SetStatus("Active");
                          }}
                        />
                        Active
                      </span>
                      <span className="d-flex">
                        <input
                          type="radio"
                          name="status"
                          checked={status == "Inactive" ? true : false}
                          onChange={() => {
                            SetStatus("Inactive");
                          }}
                        />
                        Inactive
                      </span>
                    </div>
                  </div>
                  <div className="col-12 border-top text-end pt-3">
                    <button
                      type="button"
                      className="default-btn"
                      onClick={onSaveStoryData}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
