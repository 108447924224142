import React, { useState, useEffect } from "react";
import "./VideoGallery.css";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import GetApiCall from "../../GETAPI";
import PostApiCall from "../../PostApi";
import Notiflix from "notiflix";
import Modal from "react-bootstrap/Modal";
import { confirmAlert } from "react-confirm-alert";

export default function VideoGallery() {
  let navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [allvideodata, setAllVideoData] = useState([]);
  const [id, setId] = useState(null);
  const [title, setTitle] = useState("");
  const [videolink, setVideoLink] = useState("");
  const [status, setStatus] = useState("Active");

  useEffect(() => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetVideoGalleryData").then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          setAllVideoData(obj.data);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("No Records Exits");
        }
      })
    );
  }, []);

  function onUpdateVideoGalleryData() {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        id: id,
        title: title,
        videolink: videolink,
        status: status,
        updatedon: new Date(),
        createdon: new Date(),
      },
      "AddVideoGallery"
    ).then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          GetApiCall.getRequest("GetVideoGalleryData").then((resultdes) =>
            resultdes.json().then((obj) => {
              if (obj.data.length != 0) {
                setAllVideoData(obj.data);
                setModalShow(false);
                setTitle("");
                setVideoLink("");
                setStatus("Active");
                setId(null);
                Notiflix.Loading.remove();
                Notiflix.Notify.success("Video Added Successfully.");
              } else {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure("No Records Exits");
              }
            })
          );
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
  }
  function onCloseVideoGalleryModal() {
    setModalShow(false);
    setTitle("");
    setVideoLink("");
    setStatus("Active");
    setId(null);
  }

  function onAddVideo() {
    navigate("/addvideogallry");
  }
  return (
    <>
      <Navbar />

      <div className="container mt-5">
        <div className="row">
          <div className="col-12 text-center">
            <div className="main-heading pb-1">
              <h2 className="main-title wow animate__animated animate__fadeInUp">
                Video Gallery
              </h2>
              <h3 className="main-text wow animate__animated animate__fadeInUp">
                Manage YouTube Video links
              </h3>
            </div>
          </div>
          <div className="col-12 mt-5">
            <div className="form-container">
              <div className="mb-3 d-flex justify-content-between border-bottom pb-3">
                <h3 class="main-text fw-bold">Video List</h3>
                <button
                  type="button"
                  className="default-btn"
                  onClick={() => {
                    onAddVideo();
                  }}
                >
                  Add New Video
                </button>
              </div>
              <form className="w-100">
                <div className="row w-100">
                  {allvideodata.map((videodata, i) => {
                    return (
                      <div className="col-md-3 px-3">
                        <div className="video-card">
                          <h5 className="form-field">{videodata.fld_title}</h5>
                          <a href={videodata.fld_video_link} target="_blank">
                            Watch Video
                          </a>
                          <div className="col-12 border-top pt-3 mt-3 text-end d-flex">
                            <div className="form-field form-radio d-flex">
                              <span className={videodata.fld_status}>
                                {videodata.fld_status}
                              </span>
                            </div>
                            <div className="ms-auto">
                              <button
                                type="button"
                                className="default-btn ms-auto video-btn"
                                onClick={() => {
                                  setModalShow(true);
                                  setTitle(videodata.fld_title);
                                  setVideoLink(videodata.fld_video_link);
                                  setStatus(videodata.fld_status);
                                  setId(videodata.fld_id);
                                }}
                              >
                                Edit
                              </button>
                              <button
                                type="button"
                                className="default-btn video-btn"
                                onClick={() => {
                                  confirmAlert({
                                    title: "Confirm to Delete",
                                    message:
                                      "Are you sure you want to delete the Video?",
                                    buttons: [
                                      {
                                        label: "Yes",
                                        onClick: () => {
                                          PostApiCall.postRequest(
                                            {
                                              id: videodata.fld_id,
                                            },
                                            "DeleteVideo"
                                          ).then((results) =>
                                            results.json().then((obj) => {
                                              if (
                                                results.status == 200 ||
                                                results.status == 201
                                              ) {
                                                Notiflix.Loading.remove();
                                                Notiflix.Notify.success(
                                                  "Video successfully deleted."
                                                );
                                                window.location.reload();
                                              } else {
                                                Notiflix.Loading.remove();
                                                Notiflix.Notify.failure(
                                                  "Something went wrong, try again later."
                                                );
                                              }
                                            })
                                          );
                                        },
                                      },
                                      {
                                        label: "No",
                                      },
                                    ],
                                  });
                                }}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-90w"
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12">
            <div className="form-container">
              <h3 class="main-text fw-bold">Add New Video</h3>
              <form className="w-100">
                <div className="row w-100">
                  <div className="col-12 pe-0">
                    <div className="row">
                      <div className="form-field col-md-6 me-0">
                        <label>Title</label>
                        <input
                          type="text"
                          value={title}
                          onChange={(e) => {
                            setTitle(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-field col-md-6 me-0">
                        <label>YouTube Video link</label>
                        <input
                          type="text"
                          value={videolink}
                          onChange={(e) => {
                            setVideoLink(e.target.value);
                          }}
                        />
                      </div>

                      <div className="form-field col-12 me-0 form-radio">
                        <label>Status</label>
                        <div className="d-flex">
                          <span className="d-flex">
                            <input
                              type="radio"
                              name="status"
                              className="ms-2"
                              checked={status == "Active" ? true : false}
                              onChange={() => {
                                setStatus("Active");
                              }}
                            />
                            Active
                          </span>
                          <span className="d-flex">
                            <input
                              type="radio"
                              name="status"
                              checked={status == "Inactive" ? true : false}
                              onChange={() => {
                                setStatus("Inactive");
                              }}
                            />
                            Inactive
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 border-top text-end pt-3">
                    <button
                      type="button"
                      className="default-btn"
                      onClick={onCloseVideoGalleryModal}
                    >
                      Close
                    </button>

                    <button
                      type="button"
                      className="default-btn"
                      onClick={onUpdateVideoGalleryData}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Footer />
    </>
  );
}
