import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Notiflix from "notiflix";
import React, { useEffect, useState, useRef, useContext } from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import PostApiCall from "../../PostApi";
import GetApiCall from "../../GETAPI";
import "./Academy.css";
import AcademyList from "./AcademyList";
import AddAcademyInner from "./AcademyInnerList";
import imageCompression from "browser-image-compression";
import { store } from "../../components/Context/store";

const CoverImgUpload = ({ onChange, src }) => (
  <label
    htmlFor="cover-photo-upload"
    className="custom-file-upload fas"
    style={{ marginBottom: "-5px" }}
  >
    <div className="img-wrap obimages img-upload">
      <img
        src={src}
        style={{ width: "100%", height: "100%", borderRadius: "5%" }}
      />
    </div>
    <input
      accept="image/*"
      id="cover-photo-upload"
      type="file"
      onChange={onChange}
    />
  </label>
);

export default function AddAcademy() {
  const [id, setId] = useState(null);
  const [coverimagePreviewUrl, SetCoverImagePreviewUrl] = useState(
    "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png"
  );
  const [title, setTitle] = useState("");
  const [shortdescription, setShortDescription] = useState("");
  const [longdescription, setLongDescription] = useState("");
  const [coverimagealt, setCoverImageAlt] = useState("");
  const [status, setStatus] = useState("Active");
  const [academyValueExit, setAcademyValueExit] = useState("");
  const [OriginalImage, SetOriginalImage] = useState([]);
  const [outputFileName, SetOutputFileName] = useState("");
  const [imagealt, SetImageAlt] = useState("");
  const randomnum = 1548;
  const ImageApiUrl = "https://cms.nabdelhi.in/nabdapi/UploadGalleryImage";
  const { setAcademyMasterId, academyMasterId } = useContext(store);

  const formRef = useRef(null);

  useEffect(() => {
    getACademyDetails();
  }, []);

  const getACademyDetails = () => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetAcademyData").then((resultdes) =>
      resultdes.json().then((obj) => {
        Notiflix.Loading.remove();
        if (obj.data.length !== 0) {
          setAcademyValueExit(obj.data);
          setAcademyMasterId(obj.data[0].fld_id);
        } else {
          setAcademyValueExit([]);
          Notiflix.Loading.remove();
        }
      })
    );
  };

  // Callback functions to edit child academy data
  const handleEditAcademyData = (acedamyData) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setId(acedamyData.fld_id);
    setTitle(acedamyData.fld_title);
    setShortDescription(acedamyData.fld_shortdescription);
    setLongDescription(acedamyData.fld_longdescription);
    SetCoverImagePreviewUrl(acedamyData.fld_coverimage);
    setCoverImageAlt(acedamyData.fld_coverimagealt);
    setStatus(acedamyData.fld_status);
  };

  // image FTP on server
  function onUploadImage(imageFileName, randomnumber) {
    var options = {
      maxSizeMB: 0.05,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    imageCompression(OriginalImage, options).then(async (compressedFile) => {
      const form = new FormData();
      form.append("file", compressedFile);
      form.append("foldername", "Academy");
      form.append("filename", randomnumber + "-" + imageFileName);
      let response = await fetch(ImageApiUrl, {
        method: "POST",
        body: form,
      }).then((response) => {
        if (response.status == 200 || response.status == 201) {
          Notiflix.Loading.remove();
          // Notiflix.Notify.success("Academy Added Successfully.");
        } else {
          Notiflix.Loading.remove();
          // Notiflix.Notify.failure("Image Uploading Failed");
        }
      });
    });
  }
  const handleImageChange = (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    SetCoverImagePreviewUrl(URL.createObjectURL(imageFile));
    SetOriginalImage(imageFile);
    SetOutputFileName(imageFile.name);
    var imagename = imageFile.name.split(".");
    SetImageAlt(imagename[0]);
  };
  function onSaveAcademyData() {
    var imagetype = outputFileName.split(".");
    var imagename = imagealt + "." + imagetype[1];
    const trimmedImageName = imagename.trim().split(/\s+/).join("-");
    if (title != "") {
      if (shortdescription != "") {
        if (longdescription != "") {
          if (coverimagealt != "") {
            Notiflix.Loading.dots();
            let apiPayload = {};
            if (OriginalImage.length != 0) {
              try {
                onUploadImage(trimmedImageName, randomnum);
                apiPayload = {
                  id: id,
                  title: title,
                  shortdescription: shortdescription,
                  longdescription: longdescription,
                  coverimage:
                    "https://cms.nabdelhi.in/images/Academy/" +
                    randomnum +
                    "-" +
                    trimmedImageName,
                  coverimagealt: coverimagealt,
                  status: status,
                };
              } catch (error) {
                Notiflix.Notify.failure("Image upload failed");
                console.log(error);
              }
            } else {
              apiPayload = {
                id: id,
                title: title,
                shortdescription: shortdescription,
                longdescription: longdescription,
                coverimage: coverimagePreviewUrl,
                coverimagealt: coverimagealt,
                status: status,
              };
            }
            PostApiCall.postRequest(apiPayload, "AddAcademy").then((results1) =>
              results1.json().then((obj1) => {
                Notiflix.Loading.remove();
                if (results1.status === 200 || results1.status === 201) {
                  Notiflix.Loading.remove();
                  Notiflix.Notify.success(
                    id
                      ? "Academy updated successfully."
                      : "Academy added successfully."
                  );
                  resetForm();
                  getACademyDetails();
                } else {
                  Notiflix.Notify.failure("Error Occurred");
                }
              })
            );
          } else {
            Notiflix.Notify.failure("Please enter alt text");
          }
        } else {
          Notiflix.Notify.failure("Please enter long description");
        }
      } else {
        Notiflix.Notify.failure("Please enter short description");
      }
    } else {
      Notiflix.Notify.failure("Please enter title");
    }
  }

  const resetForm = () => {
    setId(null);
    setTitle("");
    setShortDescription("");
    setLongDescription("");
    setCoverImageAlt("");
    setStatus("Active");
    SetCoverImagePreviewUrl(
      "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png"
    );
  };

  return (
    <>
      {/* {console.log(academyMasterId)} */}
      <Navbar />
      <div className="container mt-5">
        <div className="row">
          <div className="col-12 text-center">
            <h2 className="main-title">Manage Academy</h2>
            <h3 className="main-text">
              You can manage the content for the Academy Offered
            </h3>
          </div>

          <div className="col-12 px-0">
            <div className="form-container" ref={formRef}>
              <h3 className="main-text fw-bold">Add New Academy</h3>
              <form className="w-100 mt-4">
                <div className="row w-100">
                  <div className="col-md-2">
                    {/* cover image */}
                    <div className="form-field col-12 me-0">
                      <label>Upload Cover Image</label>
                      <CoverImgUpload
                        src={coverimagePreviewUrl}
                        onChange={handleImageChange}
                      />
                      <p className="mt-4">
                        Image Size: Width: 400px, Height: 250px
                      </p>
                      <p>Image Format: .jpg, .jpeg, .png, .webp</p>
                    </div>
                  </div>

                  {/* Main form content */}
                  <div className="col-md-10 pe-0">
                    <div className="form-field col-12 me-0">
                      <label>
                        Title<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                    <div className="form-field col-12 me-0">
                      <label>
                        Short Description<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        value={shortdescription}
                        onChange={(e) => setShortDescription(e.target.value)}
                      />
                    </div>
                    <div className="form-field col-12 me-0">
                      <label>
                        Long Description<span className="text-danger">*</span>
                      </label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={longdescription}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setLongDescription(data);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12 mb-3 pe-0">
                    <label className="me-3">
                      Cover Image Alt text / Title
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="w-100"
                      type="text"
                      value={coverimagealt}
                      onChange={(e) => setCoverImageAlt(e.target.value)}
                    />
                  </div>

                  <div className="form-field col-12 me-0 form-radio">
                    <label>Status</label>
                    <div className="d-flex">
                      <span className="d-flex">
                        <input
                          type="radio"
                          name="status"
                          className="ms-2"
                          checked={status === "Active"}
                          onChange={() => setStatus("Active")}
                        />
                        Active
                      </span>
                      <span className="d-flex">
                        <input
                          type="radio"
                          name="status"
                          checked={status === "Inactive"}
                          onChange={() => setStatus("Inactive")}
                        />
                        Inactive
                      </span>
                    </div>
                  </div>
                  <div className="col-12 border-top mx-auto text-end pt-3">
                    {academyValueExit.length >= 1 && !id ? (
                      <></>
                    ) : (
                      <button
                        type="button"
                        className="default-btn"
                        onClick={onSaveAcademyData}
                      >
                        {id ? "Update" : "Save"}
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
            <AcademyList
              academyData={academyValueExit}
              editAcademyData={handleEditAcademyData}
              getACademyDetails={getACademyDetails}
            />

            {/* ***** Inner Academy List ***** */}
            {academyValueExit.length > 0 && <AddAcademyInner />}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
