import React, { useState, useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import "./NewsBlogs.css";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import Notiflix from "notiflix";
import GetApiCall from "../../GETAPI";
import PostApiCall from "../../PostApi";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import imageCompression from "browser-image-compression";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import createDOMPurify from "dompurify";
const DOMPurify = createDOMPurify(window);

const ImgUpload = ({ onChange, src }) => (
  <label
    htmlFor="photo-upload"
    className="custom-file-upload fas"
    style={{ marginBottom: "-5px" }}
  >
    <div className="img-wrap obimages img-upload">
      <img
        htmlFor="photo-upload"
        src={src}
        style={{ width: "100%", height: "100%", borderRadius: "5%" }}
      />
    </div>
    <input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
  </label>
);

export default function NewsBlogs() {
  let navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [ImagePreviewUrl, SetImagePreviewUrl] = useState(
    "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png"
  );
  const ImageApiUrl = "https://cms.nabdelhi.in/nabdapi/UploadGalleryImage";
  const [OriginalImage, SetOriginalImage] = useState([]);
  const [outputFileName, SetOutputFileName] = useState("");
  const [UploadImage, SetUploadImage] = useState(false);
  const [allNewsBlogData, setAllNewsBlogData] = useState([]);
  const [id, setId] = useState(null);
  const [imagealt, setImageAlt] = useState("");
  const [title, setTitle] = useState("");
  const [writtenby, setWrittenBy] = useState("");
  const [shortdescription, setShortDescription] = useState("");
  const [content, setContent] = useState("");
  const [status, setStatus] = useState("Active");

  const photoUpload = (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    SetImagePreviewUrl(URL.createObjectURL(imageFile));
    SetOriginalImage(imageFile);
    SetOutputFileName(imageFile.name);
    var imagename = imageFile.name.split(".");
    setImageAlt(imagename[0]);
    SetUploadImage(true);
  };

  useEffect(() => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetNewsBlogData").then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          setAllNewsBlogData(obj.data);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("No Records Exits");
        }
      })
    );
  }, []);

  async function onUploadImage(ImageFileName, randomnumber) {
    var options = {
      maxSizeMB: 0.05,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    imageCompression(OriginalImage, options).then(async (compressedFile) => {
      const form = new FormData();
      form.append("file", compressedFile);
      form.append("foldername", "Activities");
      form.append("filename", randomnumber + "-" + ImageFileName);
      let response = await fetch(ImageApiUrl, {
        method: "POST",
        body: form,
      }).then((response) => {
        if (response.status == 200 || response.status == 201) {
          GetApiCall.getRequest("GetNewsBlogData").then((resultdes) =>
            resultdes.json().then((obj) => {
              if (obj.data.length != 0) {
                setAllNewsBlogData(obj.data);
                Notiflix.Loading.remove();
              } else {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure("No Records Exits");
              }
            })
          );
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Image Uploading Failed");
        }
      });
    });
    SetOriginalImage([]);
  }

  function onUpdateNewsBlogsData() {
    Notiflix.Loading.dots();
    var randomnumber = Math.floor(100000 + Math.random() * 900000);
    var imagetype = outputFileName.split(".");
    var imagename = imagealt + "." + imagetype[1];
    var newsblogsobj = {};
    if (OriginalImage.length != []) {
      newsblogsobj = {
        id: id,
        image:
          "https://cms.nabdelhi.in/images/Activities/" +
          randomnumber +
          "-" +
          imagename,
        imagealt: imagealt,
        title: title,
        writtenby: writtenby,
        shortdescription: shortdescription,
        content: content,
        status: status,
        updatedon: new Date(),
        createdon: new Date(),
      };
    } else {
      newsblogsobj = {
        id: id,
        image: ImagePreviewUrl,
        imagealt: imagealt,
        title: title,
        writtenby: writtenby,
        shortdescription: shortdescription,
        content: content,
        status: status,
        updatedon: new Date(),
        createdon: new Date(),
      };
    }
    PostApiCall.postRequest(newsblogsobj, "AddNewsBlogs").then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          if (OriginalImage.length != 0) {
            onUploadImage(imagename, randomnumber);
          } else {
            GetApiCall.getRequest("GetNewsBlogData").then((resultdes) =>
              resultdes.json().then((obj) => {
                if (obj.data.length != 0) {
                  setAllNewsBlogData(obj.data);
                  setModalShow(false);
                  setId(null);
                  setImageAlt("");
                  setTitle("");
                  setWrittenBy("");
                  setShortDescription("");
                  setContent("");
                  setStatus("");
                  SetImagePreviewUrl(
                    "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png"
                  );
                  Notiflix.Loading.remove();
                  Notiflix.Notify.success("News&Blogs Updated Successfully.");
                } else {
                  Notiflix.Loading.remove();
                  Notiflix.Notify.failure("No Records Exits");
                }
              })
            );
          }
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
  }

  function onCloseEditNewsBlogsModal() {
    setModalShow(false);
    setId(null);
    setImageAlt("");
    setTitle("");
    setWrittenBy("");
    setShortDescription("");
    setContent("");
    setStatus("");
    SetImagePreviewUrl(
      "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png"
    );
  }

  function onAddNewsBlogs() {
    navigate("/addnewsblogs");
  }

  return (
    <>
      <Navbar />
      <div className="container mt-5">
        <div className="row">
          <div className="col-12 text-center">
            <div className="main-heading pb-1">
              <h2 className="main-title wow animate__animated animate__fadeInUp">
                News & Blogs
              </h2>
              <h3 className="main-text wow animate__animated animate__fadeInUp">
                Use this module to Manage Content of News & Blogs
              </h3>
            </div>
          </div>

          <div className="col-12 mt-5">
            <div className="form-container">
              <div className="mb-3 d-flex justify-content-between border-bottom pb-3">
                <h3 class="main-text fw-bold">News & Blogs List</h3>
                <button
                  type="button"
                  className="default-btn"
                  onClick={() => {
                    onAddNewsBlogs();
                  }}
                >
                  Add New New & Blog
                </button>
              </div>

              {allNewsBlogData.map((newsblogsdata, i) => {
                return (
                  <form className="mt-3 flex-column">
                    <div className="col-12">
                      <div className="service-card service-card-inner">
                        <div className="s-card-img">
                          <img
                            src={newsblogsdata.fld_image}
                            alt={newsblogsdata.fld_image_alt}
                            className="img-fluid"
                          />

                          <div className="s-content mt-3">
                            <h4>{newsblogsdata.fld_title}</h4>
                            <h5 mt-2>
                              Updated on -{" "}
                              {newsblogsdata.fld_updated_on == null
                                ? moment(newsblogsdata.fld_created_on).format(
                                    "DD MM YYYY"
                                  )
                                : moment(newsblogsdata.fld_updated_on).format(
                                    "DD MM YYYY"
                                  )}
                            </h5>
                            <h5 mt-2>
                              Posted by - {newsblogsdata.fld_written_by}
                            </h5>
                            <h3 mt-2>Short Description</h3>
                            <p>{newsblogsdata.fld_short_description}</p>
                            <h3 mt-2>Content</h3>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  newsblogsdata.fld_content
                                ),
                              }}
                            />
                          </div>

                          <div className="col-12 border-top pt-3 mt-3 text-end d-flex">
                            <div className="form-field form-radio d-flex">
                              <span className={newsblogsdata.fld_status}>
                                {newsblogsdata.fld_status}
                              </span>
                            </div>
                            <div className="ms-auto">
                              <button
                                type="button"
                                className="default-btn ms-auto"
                                onClick={() => {
                                  setModalShow(true);
                                  setId(newsblogsdata.fld_id);
                                  setImageAlt(newsblogsdata.fld_image_alt);
                                  setTitle(newsblogsdata.fld_title);
                                  setWrittenBy(newsblogsdata.fld_written_by);
                                  setShortDescription(
                                    newsblogsdata.fld_short_description
                                  );
                                  setContent(newsblogsdata.fld_content);
                                  setStatus(newsblogsdata.fld_status);
                                  SetImagePreviewUrl(newsblogsdata.fld_image);
                                }}
                              >
                                Edit
                              </button>
                              <button
                                type="button"
                                className="default-btn"
                                onClick={() => {
                                  confirmAlert({
                                    title: "Confirm to Delete",
                                    message:
                                      "Are you sure you want to delete the News&Blogs?",
                                    buttons: [
                                      {
                                        label: "Yes",
                                        onClick: () => {
                                          PostApiCall.postRequest(
                                            {
                                              id: newsblogsdata.fld_id,
                                            },
                                            "DeleteNewsBlogs"
                                          ).then((results) =>
                                            results.json().then((obj) => {
                                              if (
                                                results.status == 200 ||
                                                results.status == 201
                                              ) {
                                                Notiflix.Loading.remove();
                                                Notiflix.Notify.success(
                                                  "News&Blogs successfully deleted."
                                                );
                                                window.location.reload();
                                              } else {
                                                Notiflix.Loading.remove();
                                                Notiflix.Notify.failure(
                                                  "Something went wrong, try again later."
                                                );
                                              }
                                            })
                                          );
                                        },
                                      },
                                      {
                                        label: "No",
                                      },
                                    ],
                                  });
                                }}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-90w"
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12">
            <div className="form-container">
              <h3 class="main-text fw-bold">Add News & Blogs</h3>
              <form className="">
                <div className="row">
                  <div className="col-12 inner-image mb-3">
                    <label>Upload Image</label>
                    <span className="ms-5">
                      Image Size: Width: 1920px Height: 1440px
                    </span>
                    <span className="ms-5">
                      Image Format: .jpg, .jpeg, .png, .webp
                    </span>
                    <ImgUpload onChange={photoUpload} src={ImagePreviewUrl} />
                  </div>
                  <div className="col-12 pe-0 mb-3 ps-3">
                    <label className="me-3">Image Alt text / Title</label>
                    <input
                      className="w-100"
                      type="text"
                      value={imagealt}
                      onChange={(e) => {
                        setImageAlt(e.target.value);
                      }}
                    />
                  </div>

                  <div className="form-field col-12 me-0">
                    <label>Title</label>
                    <input
                      type="text"
                      value={title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-field col-12 me-0">
                    <label>Written By</label>
                    <input
                      type="text"
                      value={writtenby}
                      onChange={(e) => {
                        setWrittenBy(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-field col-12 me-0">
                    <label>Short Description</label>
                    <input
                      type="text"
                      value={shortdescription}
                      onChange={(e) => {
                        setShortDescription(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-field col-12 me-0">
                    <label>Content</label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={content}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setContent(data);
                      }}
                    />
                  </div>

                  <div className="form-field col-12 me-0 form-radio">
                    <label>Status</label>
                    <div className="d-flex">
                      <span className="d-flex">
                        <input
                          type="radio"
                          name="status"
                          className="ms-2"
                          checked={status == "Active" ? true : false}
                          onChange={() => {
                            setStatus("Active");
                          }}
                        />
                        Active
                      </span>
                      <span className="d-flex">
                        <input
                          type="radio"
                          name="status"
                          checked={status == "Inactive" ? true : false}
                          onChange={() => {
                            setStatus("Inactive");
                          }}
                        />
                        Inactive
                      </span>
                    </div>
                  </div>
                  <div className="col-12 border-top text-end pt-3">
                    <button
                      type="button"
                      className="default-btn d-inline-block mt-3 ms-auto"
                      onClick={onCloseEditNewsBlogsModal}
                    >
                      Close
                    </button>

                    <button
                      type="button"
                      className="default-btn"
                      onClick={onUpdateNewsBlogsData}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
}
