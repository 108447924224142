import React from "react";
import "./Academy.css";
import { useState, useContext } from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Notiflix from "notiflix";
import PostApiCall from "../../PostApi";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import imageCompression from "browser-image-compression";
import { useNavigate } from "react-router-dom";
import { store } from "../../components/Context/store";

const CoverImgUpload = ({ onChange, src }) => (
  <label
    htmlFor="inner-academy-image"
    className="custom-file-upload fas"
    style={{ marginBottom: "-5px" }}
  >
    <div className="img-wrap obimages img-upload">
      <img
        htmlFor="inner-academy-image"
        src={src}
        style={{ width: "100%", height: "100%", borderRadius: "5%" }}
      />
    </div>
    <input
      accept="image/*"
      id="inner-academy-image"
      type="file"
      onChange={onChange}
    />
  </label>
);

export default function AddInnerAcademy() {
  const [coverimagePreviewUrl, SetCoverImagePreviewUrl] = useState(
    "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png"
  );
  const [id, setId] = useState(null);
  const [title, setTitle] = useState("");
  const [shortdescription, setShortDescription] = useState("");
  const [longdescription, setLongDescription] = useState("");
  const [coverimagealt, setCoverImageAlt] = useState("");
  const [status, setStatus] = useState("Active");
  const [OriginalImage, SetOriginalImage] = useState([]);
  const [outputFileName, SetOutputFileName] = useState("");
  const [imagealt, SetImageAlt] = useState("");
  const randomnum = 8578;
  const ImageApiUrl = "https://cms.nabdelhi.in/nabdapi/UploadGalleryImage";
  const navigate = useNavigate();
  const { academyMasterId } = useContext(store);

  function onSaveInnerAcademyData() {
    var imagetype = outputFileName.split(".");
    var imagename = imagealt + "." + imagetype[1];
    const trimmedImageName = imagename.trim().split(/\s+/).join("-");

    if (title != "") {
      if (shortdescription != "") {
        if (longdescription != "") {
          if (coverimagealt != "") {
            Notiflix.Loading.dots();
            let apiPayload = {};
            if (OriginalImage.length != 0) {
              try {
                onUploadImage(trimmedImageName, randomnum);
                apiPayload = {
                  academymasterid: academyMasterId,
                  id: id,
                  title: title,
                  shortdescription: shortdescription,
                  longdescription: longdescription,
                  coverimage:
                    "https://cms.nabdelhi.in/images/Academy/" +
                    randomnum +
                    "-" +
                    trimmedImageName,
                  coverimagealt: coverimagealt,
                  status: status,
                };
              } catch (error) {
                Notiflix.Notify.failure("Image upload failed");
                console.log(error);
              }
            } else {
              apiPayload = {
                academymasterid: academyMasterId,
                id: id,
                title: title,
                shortdescription: shortdescription,
                longdescription: longdescription,
                coverimage: coverimagePreviewUrl,
                coverimagealt: coverimagealt,
                status: status,
              };
            }
            PostApiCall.postRequest(apiPayload, "AddInnerAcademy").then(
              (results1) =>
                results1.json().then((obj1) => {
                  Notiflix.Loading.remove();
                  if (results1.status === 200 || results1.status === 201) {
                    Notiflix.Notify.success("Academy added successfully.");
                    resetForm();
                    navigate("/addacademy");
                  } else {
                    Notiflix.Notify.failure("Error Occurred");
                  }
                })
            );
          } else {
            Notiflix.Notify.failure("Please enter alt text");
          }
        } else {
          Notiflix.Notify.failure("Please enter long description");
        }
      } else {
        Notiflix.Notify.failure("Please enter short description");
      }
    } else {
      Notiflix.Notify.failure("Please enter title");
    }
  }

  // image FTP on server
  function onUploadImage(imageFileName, randomnumber) {
    var options = {
      maxSizeMB: 0.05,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    imageCompression(OriginalImage, options).then(async (compressedFile) => {
      const form = new FormData();
      form.append("file", compressedFile);
      form.append("foldername", "Academy");
      form.append("filename", randomnumber + "-" + imageFileName);
      let response = await fetch(ImageApiUrl, {
        method: "POST",
        body: form,
      }).then((response) => {
        if (response.status == 200 || response.status == 201) {
          Notiflix.Loading.remove();
          // Notiflix.Notify.success("Academy Added Successfully.");
        } else {
          Notiflix.Loading.remove();
          // Notiflix.Notify.failure("Image Uploading Failed");
        }
      });
    });
  }
  const handleImageChange = (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    console.log(imageFile);
    SetCoverImagePreviewUrl(URL.createObjectURL(imageFile));
    SetOriginalImage(imageFile);
    SetOutputFileName(imageFile.name);
    var imagename = imageFile.name.split(".");
    SetImageAlt(imagename[0]);
  };

  const resetForm = () => {
    setId(null);
    setTitle("");
    setShortDescription("");
    setLongDescription("");
    setCoverImageAlt("");
    setStatus("Active");
    SetCoverImagePreviewUrl(
      "https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png"
    );
  };

  return (
    <>
      <Navbar />

      <div className="container mt-5">
        <div className="row">
          <div className="col-12 text-center">
            <div className="main-heading pb-1">
              <h2 className="main-title wow animate__animated animate__fadeInUp">
                Manage Inner Academy
              </h2>
              <h3 className="main-text wow animate__animated animate__fadeInUp">
                You can manage the content for the Inner Academy Offered
              </h3>
            </div>
          </div>

          <div className="col-12 px-0">
            <div className="form-container">
              <h3 class="main-text fw-bold">Add New Inner Academy</h3>
              <form className="w-100 mt-4">
                <div className="row w-100">
                  <div className="col-md-2">
                    <div className="row">
                      <div className="form-field col-12 me-0">
                        <label>Upload Cover Image</label>
                        <CoverImgUpload
                          src={coverimagePreviewUrl}
                          onChange={handleImageChange}
                        />
                        <p className="mt-4">
                          Image Size: <br /> Width: 400px <br /> Height: 250px
                        </p>
                        <p>
                          Image Format: <br /> .jpg, .jpeg, .png, .webp
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-10 pe-0">
                    <div className="row w-100 me-0 pe-0 gx-0">
                      <div className="form-field col-12 me-0">
                        <label>
                          Title<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          value={title}
                          onChange={(e) => {
                            setTitle(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-field col-12 me-0">
                        <label>
                          Short Description
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          value={shortdescription}
                          onChange={(e) => {
                            setShortDescription(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-field col-12 me-0">
                        <label>
                          Long Description<span className="text-danger">*</span>
                        </label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={longdescription}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setLongDescription(data);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mb-3 pe-0">
                    <label className="me-3">Cover Image Alt text / Title</label>
                    <input
                      className="w-100"
                      type="text"
                      value={coverimagealt}
                      onChange={(e) => {
                        setCoverImageAlt(e.target.value);
                      }}
                    />
                  </div>

                  <div className="form-field col-12 me-0 form-radio">
                    <label>Status</label>
                    <div className="d-flex">
                      <span className="d-flex">
                        <input
                          type="radio"
                          name="status"
                          className="ms-2"
                          checked={status == "Active" ? true : false}
                          onChange={() => {
                            setStatus("Active");
                          }}
                        />
                        Active
                      </span>
                      <span className="d-flex">
                        <input
                          type="radio"
                          name="status"
                          checked={status == "Inactive" ? true : false}
                          onChange={() => {
                            setStatus("Inactive");
                          }}
                        />
                        Inactive
                      </span>
                    </div>
                  </div>
                  <div className="col-12 border-top mx-auto text-end pt-3">
                    <button
                      type="button"
                      className="default-btn"
                      onClick={onSaveInnerAcademyData}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
