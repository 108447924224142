import React, { useEffect } from "react";
import "./Downloads.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Notiflix from "notiflix";
import GetApiCall from "../../GETAPI";
import PostApiCall from "../../PostApi";
import { confirmAlert } from "react-confirm-alert";
import { BsFillFilePdfFill } from "react-icons/bs";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Modal from "react-bootstrap/Modal";
import Sequentialize from "../../assets/images/Sequentialize.png";
import { Offcanvas } from "react-bootstrap";
import DragDropDownload from "./DragDorpDownload";
export default function Downloads() {
  let navigate = useNavigate();
  const ImageApiUrl = "https://cms.nabdelhi.in/nabdapi/UploadGalleryImage";
  const [fillActive, setFillActive] = useState("tab1");
  const [alldownloadsdata, SetAllDownloadsData] = useState([]);
  const [choosedocumentcategory, SetChooseDocumentCategory] = useState("");
  const [importantpublication, SetImportantPublication] = useState([]);
  const [rechlistcedu, SetRechListcEdu] = useState([]);
  const [annualreports, SetAnnualReports] = useState([]);
  const [auditedaccounts, SetAuditedAccounts] = useState([]);
  const [legaldocuments, SetLegalDocuments] = useState([]);
  const [beneficiarydata, SetBeneficiaryData] = useState([]);
  const [alldownloads, SetAllDownloads] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [showdocumentcategory, SetShowDocumentCategory] = useState(true);
  const [downloadid, SetDocumentId] = useState(null);
  const [document, SetDocument] = useState("");
  const [documentfile, SetDocumentFile] = useState([]);
  const [documentFileUrl, setDocumentFileUrl] = useState("");
  const [documentname, SetDocumentName] = useState("");
  const [documentcategory, SetDocumentCategory] = useState("");
  const [canvasData, setCanvasData] = useState(null);
  const [documenttype, SetDocumentType] = useState("Statutory Documents");
  const [status, SetStatus] = useState("Active");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleFillClick = (value) => {
    if (value === fillActive) {
      return;
    }

    setFillActive(value);
  };

  useEffect(() => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetDownloadsData").then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          var allannualreports = [];
          var allimportantpublications = [];
          var allrecholisticeducation = [];
          var allauditedaccounts = [];
          var alllegaldocuments = [];
          var allbeneficiarydata = [];
          SetAllDownloadsData(obj.data);
          SetAllDownloads(obj.data);
          obj.data.map((data, i) => {
            if (data.fld_category == "ANNUAL REPORTS") {
              allannualreports.push(data);
            } else if (data.fld_category == "IMPORTANT PUBLICATIONS") {
              allimportantpublications.push(data);
            } else if (
              data.fld_category ==
              "REC HOLISTIC EDUCATION & REHABILITATION FOR CHILDREN WITH BLINDNESS"
            ) {
              allrecholisticeducation.push(data);
            } else if (data.fld_category == "AUDITED ACCOUNTS") {
              allauditedaccounts.push(data);
            } else if (data.fld_category == "LEGAL DOCUMENTS") {
              alllegaldocuments.push(data);
            } else if (data.fld_category == "BENEFICIARY DATA") {
              allbeneficiarydata.push(data);
            }
          });
          SetImportantPublication(allimportantpublications);
          SetRechListcEdu(allrecholisticeducation);
          SetAnnualReports(allannualreports);
          SetAuditedAccounts(allauditedaccounts);
          SetLegalDocuments(alllegaldocuments);
          SetBeneficiaryData(allbeneficiarydata);
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("No Records Exits");
        }
      })
    );
  }, []);

  function onSelectDocumentCategory(value) {
    if (value == "ANNUAL REPORTS") {
      SetAllDownloadsData(annualreports);
      setCanvasData(annualreports);
    } else if (value == "IMPORTANT PUBLICATIONS") {
      SetAllDownloadsData(importantpublication);
      setCanvasData(importantpublication);
    } else if (
      value ==
      "REC HOLISTIC EDUCATION & REHABILITATION FOR CHILDREN WITH BLINDNESS"
    ) {
      SetAllDownloadsData(rechlistcedu);
      setCanvasData(rechlistcedu);
    } else if (value == "AUDITED ACCOUNTS") {
      SetAllDownloadsData(auditedaccounts);
      setCanvasData(auditedaccounts);
    } else if (value == "LEGAL DOCUMENTS") {
      SetAllDownloadsData(legaldocuments);
      setCanvasData(legaldocuments);
    } else if (value == "BENEFICIARY DATA") {
      SetAllDownloadsData(beneficiarydata);
      setCanvasData(beneficiarydata);
    } else {
      SetAllDownloadsData(alldownloads);
      setCanvasData(alldownloads);
    }
  }

  async function onUploadDocument(documentFileName, randomnumber) {
    var options = {
      maxSizeMB: 0.05,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const form = new FormData();
    form.append("file", documentfile);
    form.append("foldername", "Activities");
    form.append("filename", randomnumber + "-" + documentFileName);
    let response = await fetch(ImageApiUrl, {
      method: "POST",
      body: form,
    }).then((response) => {
      if (response.status == 200 || response.status == 201) {
        GetApiCall.getRequest("GetDownloadsData").then((resultdes) =>
          resultdes.json().then((obj) => {
            if (obj.data.length != 0) {
              var allannualreports = [];
              var allimportantpublications = [];
              var allrecholisticeducation = [];
              var allauditedaccounts = [];
              var alllegaldocuments = [];
              var allbeneficiarydata = [];
              SetAllDownloadsData(obj.data);
              SetAllDownloads(obj.data);
              obj.data.map((data, i) => {
                if (data.fld_category == "ANNUAL REPORTS") {
                  allannualreports.push(data);
                } else if (data.fld_category == "IMPORTANT PUBLICATIONS") {
                  allimportantpublications.push(data);
                } else if (
                  data.fld_category ==
                  "REC HOLISTIC EDUCATION & REHABILITATION FOR CHILDREN WITH BLINDNESS"
                ) {
                  allrecholisticeducation.push(data);
                } else if (data.fld_category == "AUDITED ACCOUNTS") {
                  allauditedaccounts.push(data);
                } else if (data.fld_category == "LEGAL DOCUMENTS") {
                  alllegaldocuments.push(data);
                } else if (data.fld_category == "BENEFICIARY DATA") {
                  allbeneficiarydata.push(data);
                }
              });
              SetImportantPublication(allimportantpublications);
              SetRechListcEdu(allrecholisticeducation);
              SetAnnualReports(allannualreports);
              SetAuditedAccounts(allauditedaccounts);
              SetLegalDocuments(alllegaldocuments);
              SetBeneficiaryData(allbeneficiarydata);

              setModalShow(false);
              SetShowDocumentCategory(true);
              SetDocumentId(null);
              SetDocument("");
              SetDocumentName("");
              SetDocumentCategory("");
              SetDocumentType("Statutory Documents");
              SetStatus("Active");

              Notiflix.Loading.remove();
              Notiflix.Notify.success("Downloads Updated Successfully.");
            } else {
              Notiflix.Loading.remove();
              Notiflix.Notify.failure("No Records Exits");
            }
          })
        );
      } else {
        Notiflix.Loading.remove();
        Notiflix.Notify.failure("Document Uploading Failed");
      }
    });

    SetDocumentFile([]);
  }

  function onSaveDownloadData() {
    var randomnumber = Math.floor(100000 + Math.random() * 900000);
    Notiflix.Loading.dots();
    var documentobj = {};
    if (documentfile.length != 0) {
      documentobj = {
        downloadid: downloadid,
        document:
          "https://cms.nabdelhi.in/images/Activities/" +
          randomnumber +
          "-" +
          document,
        documentname: documentname,
        documentcategory: documentcategory,
        documenttype: documenttype,
        status: status,
      };
    } else {
      documentobj = {
        downloadid: downloadid,
        document: documentFileUrl,
        documentname: documentname,
        documentcategory: documentcategory,
        documenttype: documenttype,
        status: status,
      };
    }
    PostApiCall.postRequest(documentobj, "AddDownload").then((results1) =>
      results1.json().then((obj1) => {
        if (results1.status == 200 || results1.status == 201) {
          if (documentfile.length != 0) {
            onUploadDocument(document, randomnumber);
          } else {
            GetApiCall.getRequest("GetDownloadsData").then((resultdes) =>
              resultdes.json().then((obj) => {
                if (obj.data.length != 0) {
                  var allannualreports = [];
                  var allimportantpublications = [];
                  var allrecholisticeducation = [];
                  var allauditedaccounts = [];
                  var alllegaldocuments = [];
                  var allbeneficiarydata = [];
                  SetAllDownloadsData(obj.data);
                  SetAllDownloads(obj.data);
                  obj.data.map((data, i) => {
                    if (data.fld_category == "ANNUAL REPORTS") {
                      allannualreports.push(data);
                    } else if (data.fld_category == "IMPORTANT PUBLICATIONS") {
                      allimportantpublications.push(data);
                    } else if (
                      data.fld_category ==
                      "REC HOLISTIC EDUCATION & REHABILITATION FOR CHILDREN WITH BLINDNESS"
                    ) {
                      allrecholisticeducation.push(data);
                    } else if (data.fld_category == "AUDITED ACCOUNTS") {
                      allauditedaccounts.push(data);
                    } else if (data.fld_category == "LEGAL DOCUMENTS") {
                      alllegaldocuments.push(data);
                    } else if (data.fld_category == "BENEFICIARY DATA") {
                      allbeneficiarydata.push(data);
                    }
                  });
                  SetImportantPublication(allimportantpublications);
                  SetRechListcEdu(allrecholisticeducation);
                  SetAnnualReports(allannualreports);
                  SetAuditedAccounts(allauditedaccounts);
                  SetLegalDocuments(alllegaldocuments);
                  SetBeneficiaryData(allbeneficiarydata);

                  setModalShow(false);
                  SetShowDocumentCategory(true);
                  SetDocumentId(null);
                  SetDocument("");
                  SetDocumentName("");
                  SetDocumentCategory("");
                  SetDocumentType("Statutory Documents");
                  SetStatus("Active");

                  Notiflix.Loading.remove();
                  Notiflix.Notify.success("Downloads Updated Successfully.");
                } else {
                  Notiflix.Loading.remove();
                  Notiflix.Notify.failure("No Records Exits");
                }
              })
            );
          }
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Error Occurred");
        }
      })
    );
  }

  function onCloseDownloadModal() {
    setModalShow(false);
    SetShowDocumentCategory(true);
    SetDocumentId(null);
    SetDocument("");
    SetDocumentName("");
    SetDocumentCategory("");
    SetDocumentType("Statutory Documents");
    SetStatus("Active");
  }

  function onAddNewDownload() {
    navigate("/addnewdownload");
  }
  return (
    <>
      <Navbar />

      <div className="container mt-5">
        <div className="row">
          <div className="col-12 text-center">
            <div className="main-heading pb-1">
              <h2 className="main-title wow animate_animated animate_fadeInUp">
                Manage Downloads
              </h2>
              <h3 className="main-text wow animate_animated animate_fadeInUp">
                Use this module to add Documents that can be downloadable from
                the website
              </h3>
            </div>
          </div>
          <div className="col-12 mt-5">
            <div className="form-container download-main">
              <div className="mb-3 d-flex justify-content-between border-bottom pb-3">
                <h3 class="main-text fw-bold">All Documents List</h3>
                <button
                  type="button"
                  className="default-btn ms-auto"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                  onClick={() => {
                    handleShow();
                  }}
                >
                  <img
                    alt="sequential-img"
                    src={Sequentialize}
                    className="sequentialize-btn"
                  />
                </button>
                <button
                  type="button"
                  className="default-btn"
                  onClick={() => {
                    onAddNewDownload();
                  }}
                >
                  Add New Document
                </button>
              </div>
              <form className="mt-3 flex-column">
                <select
                  class="w-100"
                  onChange={(e) => {
                    SetChooseDocumentCategory(e.target.value);
                    onSelectDocumentCategory(e.target.value);
                  }}
                >
                  <option value="Select Document Category">
                    Select Document Category
                  </option>
                  <option value="IMPORTANT PUBLICATIONS">
                    IMPORTANT PUBLICATIONS
                  </option>
                  <option value="REC HOLISTIC EDUCATION & REHABILITATION FOR CHILDREN WITH BLINDNESS">
                    REC HOLISTIC EDUCATION & REHABILITATION FOR CHILDREN WITH
                    BLINDNESS
                  </option>
                  <option value="ANNUAL REPORTS">ANNUAL REPORTS</option>
                  <option value="AUDITED ACCOUNTS">AUDITED ACCOUNTS</option>
                  <option value="LEGAL DOCUMENTS">LEGAL DOCUMENTS</option>
                  <option value="BENEFICIARY DATA">BENEFICIARY DATA</option>
                </select>
                <div className="row mt-4">
                  {alldownloadsdata
                    ?.sort((a, b) => a.fld_sequence - b.fld_sequence)
                    .map((downloaddata, i) => {
                      return (
                        <div className="col-md-4 px-3 mb-3">
                          <div className="members-card">
                            <div className="form-field download-section col-12 me-0">
                              <a
                                href={downloaddata.fld_document}
                                target="_blank"
                              >
                                <BsFillFilePdfFill />
                              </a>
                            </div>
                            <div className="col-md-10">
                              <div className="row">
                                <div className="form-field col-12 flex-row me-0">
                                  <span>{downloaddata.fld_name}</span>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 border-top pt-3 text-end d-flex">
                              <div className="form-field form-radio d-flex">
                                <span className={downloaddata.fld_status}>
                                  {downloaddata.fld_status}
                                </span>
                              </div>
                              <div className="ms-auto">
                                <button
                                  type="button"
                                  className="default-btn ms-auto"
                                  onClick={() => {
                                    setModalShow(true);
                                    SetShowDocumentCategory(true);
                                    SetDocumentId(downloaddata.fld_id);
                                    setDocumentFileUrl(
                                      downloaddata.fld_document
                                    );
                                    SetDocumentName(downloaddata.fld_name);
                                    SetDocumentCategory(
                                      downloaddata.fld_category
                                    );
                                    SetDocumentType(
                                      downloaddata.fld_document_type
                                    );
                                    SetStatus(downloaddata.fld_status);
                                  }}
                                >
                                  Edit
                                </button>
                                <button
                                  type="button"
                                  className="default-btn"
                                  onClick={() => {
                                    confirmAlert({
                                      title: "Confirm to Delete",
                                      message:
                                        "Are you sure you want to delete the Download?",
                                      buttons: [
                                        {
                                          label: "Yes",
                                          onClick: () => {
                                            PostApiCall.postRequest(
                                              {
                                                id: downloaddata.fld_id,
                                              },
                                              "DeleteDownload"
                                            ).then((results) =>
                                              results.json().then((obj) => {
                                                if (
                                                  results.status == 200 ||
                                                  results.status == 201
                                                ) {
                                                  Notiflix.Loading.remove();
                                                  Notiflix.Notify.success(
                                                    "Download successfully deleted."
                                                  );
                                                  window.location.reload();
                                                } else {
                                                  Notiflix.Loading.remove();
                                                  Notiflix.Notify.failure(
                                                    "Something went wrong, try again later."
                                                  );
                                                }
                                              })
                                            );
                                          },
                                        },
                                        {
                                          label: "No",
                                        },
                                      ],
                                    });
                                  }}
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                {/* </MDBTabsPane>
                </MDBTabsContent> */}
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-90w"
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12">
            <div className="form-container">
              <h3 class="main-text fw-bold">Add New Document</h3>
              <form className="">
                <div className="row">
                  <div className="form-field col-md-3 me-0 form-file">
                    <p>Upload PDF</p>
                    <input
                      type="file"
                      onChange={(e) => {
                        e.preventDefault();
                        const documentFile = e.target.files[0];
                        SetDocumentFile(documentFile);
                        SetDocument(documentFile.name);
                      }}
                    />
                  </div>
                  <div className="col-md-9">
                    <div className="row">
                      <div className="form-field col-md-6 me-0 form-radio">
                        <label>Choose Document Type</label>
                        <div className="d-flex">
                          <span className="d-flex">
                            <input
                              type="radio"
                              name="member"
                              className="ms-2"
                              checked={
                                documenttype == "Statutory Documents"
                                  ? true
                                  : false
                              }
                              onChange={() => {
                                SetShowDocumentCategory(true);
                                SetDocumentType("Statutory Documents");
                              }}
                            />
                            Statutory Documents
                          </span>
                          <span className="d-flex">
                            <input
                              type="radio"
                              name="member"
                              checked={
                                documenttype == "Annual Reports" ? true : false
                              }
                              onChange={() => {
                                SetShowDocumentCategory(false);
                                SetDocumentType("Annual Reports");
                                SetDocumentCategory("Annual Reports");
                              }}
                            />
                            Annual Reports
                          </span>
                        </div>
                      </div>
                      <div
                        className="form-field col-md-6 me-0"
                        style={{
                          display:
                            documenttype == "Statutory Documents"
                              ? "block"
                              : "none",
                        }}
                      >
                        <label>Document Category</label>
                        <select
                          class="w-100"
                          value={documentcategory}
                          onChange={(e) => {
                            SetDocumentCategory(e.target.value);
                          }}
                        >
                          <option value=" IMPORTANT PUBLICATIONS">
                            IMPORTANT PUBLICATIONS
                          </option>
                          <option
                            value="REC HOLISTIC EDUCATION &
                            REHABILITATION FOR CHILDREN WITH BLINDNESS"
                          >
                            REC HOLISTIC EDUCATION & REHABILITATION FOR CHILDREN
                            WITH BLINDNESS
                          </option>
                          <option value="ANNUAL REPORTS">ANNUAL REPORTS</option>
                          <option value="AUDITED ACCOUNTS">
                            AUDITED ACCOUNTS
                          </option>
                          <option value="LEGAL DOCUMENTS">
                            LEGAL DOCUMENTS
                          </option>
                          <option value="BENEFICIARY DATA">
                            BENEFICIARY DATA
                          </option>
                        </select>
                      </div>
                      <div className="form-field col-md-6 me-0">
                        <label>Name</label>
                        <input
                          type="text"
                          value={documentname}
                          onChange={(e) => {
                            SetDocumentName(e.target.value);
                          }}
                        />
                      </div>

                      <div className="form-field col-md-6 me-0 form-radio">
                        <label>Status</label>
                        <div className="d-flex">
                          <span className="d-flex">
                            <input
                              type="radio"
                              name="status"
                              className="ms-2"
                              checked={status == "Active" ? true : false}
                              onChange={() => {
                                SetStatus("Active");
                              }}
                            />
                            Active
                          </span>
                          <span className="d-flex">
                            <input
                              type="radio"
                              name="status"
                              checked={status == "Inactive" ? true : false}
                              onChange={() => {
                                SetStatus("Inactive");
                              }}
                            />
                            Inactive
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 border-top text-end pt-3">
                    <button
                      type="button"
                      className="default-btn d-inline-block mt-3 ms-auto"
                      onClick={onCloseDownloadModal}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="default-btn"
                      onClick={onSaveDownloadData}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header className="border-bottom" closeButton>
          <Offcanvas.Title>Sequentialize</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <DragDropDownload
            verticalData={canvasData === null ? alldownloadsdata : canvasData}
            // verticalData={
            //   fillActive == "tab1"
            //     ? allActiveOfficeBearers
            //     : allActiveGoverningCouncil
            // }
            for="Vertical"
            htmlFor="verticle"
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
