import { createContext, useState } from "react";

export const store = createContext();

const Provider = ({ children }) => {
  const [activeLink, setactiveLink] = useState("Home");
  const [academyMasterId, setAcademyMasterId] = useState(null);

  return (
    <store.Provider
      value={{
        activeLink,
        setactiveLink,
        academyMasterId,
        setAcademyMasterId,
      }}
    >
      {children}
    </store.Provider>
  );
};
export default Provider;
